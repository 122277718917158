import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    custom_id : {
        show: true,
        title: 'ID',
        sortable: true,
        column: 'custom_id'
    },
    name : {
        show: true,
        title: i18n.t('message.full_name'),
        sortable: true,
        column: 'full_name'
    },
    type_id : {
        show: true,
        title: i18n.t('message.type_id'),
        sortable: true,
        column: 'type_id'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    location : {
        show: true,
        title: i18n.t('message.location'),
        sortable: true,
        column: 'location'
    },
    inn : {
        show: false,
        title: i18n.t('message.inn'),
        sortable: true,
        column: 'inn'
    },
    country_id : {
        show: true,
        title: i18n.t('message.country_id'),
        sortable: true,
        column: 'country_id'
    },
    city_id : {
        show: true,
        title: i18n.t('message.city_id'),
        sortable: true,
        column: 'city_id'
    },
    gender_id : {
        show: false,
        title: i18n.t('message.gender_id'),
        sortable: true,
        column: 'gender_id'
    },

    passport_iin : {
        show: false,
        title: i18n.t('message.passport_iin'),
        sortable: true,
        column: 'passport_iin'
    },

    passport : {
        show: false,
        title: i18n.t('message.passport'),
        sortable: true,
        column: 'passport'
    },

    clientTypes : {
        show: true,
        title: i18n.t('message.clientTypes'),
        sortable: true,
        column: 'clientTypes'
    },

    mfo: {
        show: false,
        title: i18n.t("message.mfo"),
        sortable: true,
        column: "mfo"
    },
    okonx: {
        show: false,
        title: i18n.t("message.okonx"),
        sortable: true,
        column: "okonx"
    },

    oked: {
        show: false,
        title: i18n.t("message.oked"),
        sortable: true,
        column: "oked"
    },

    rkp_nds: {
        show: false,
        title: i18n.t("message.rkp_nds"),
        sortable: true,
        column: "rkp_nds"
    },

    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
