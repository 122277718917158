import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/dealOffers',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/dealOffers',
        method: 'post',
        data
    })
}
export function show(id) {
    return request({
        url: `/dealOffers/${id}`,
        method: 'get'
    })
}

export function getLatestFiles(params) {
    return request({
        url: '/dealOffers/getLatestFiles',
        method: 'get',
        params
    })
}

export function downloadDealOffer(offer_id) {
    return request({
        url: `/dealOffers/downloadDealOffer/${offer_id}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function downloadLatestFile(params) {
    return request({
        url: `/dealOffers/downloadLatestFile/2`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}


export function getDealOffers(deal_id) {
    return request({
        url: `/dealOffers/getDealOffers/${deal_id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/dealOffers/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/dealOffers/${id}`,
        method: 'delete'
    })
}