export const filter = {
  id : null,  
  client_id: null, 
  filial_id: -1,
  date: '',
  date_interval: '',
  payment_type_id: null, 
  money_amount: 0, 
  remainder: 0, 
  currency_rate: 0, 
  bank_account_id: null, 
  comment: '', 
  created_at: '',
  updated_at: ''  
};
  