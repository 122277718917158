import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/clients',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/clients',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/clients/inventory",
        method: "get",
        params
    });
}

export function freeClients(params) {
    return request({
        url: "/clients/freeClients",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/clients/${id}`,
        method: 'get',
    })
}

export function clientDeals(id) {
    return request({
        url: `/clients/clientDeals/${id}`,
        method: 'get',
    })
}

export function filterClientsByType(params) {
    return request({
        url: `/clients/filterClientsByType`,
        method: 'get',
        params
    })
}

export function getTypes(params) {
    return request({
        url: '/clients/get/types',
        method: 'get',
        params
    })
}

export function getGenders(params) {
    return request({
        url: '/clients/get/genders',
        method: 'get',
        params
    })
}
export function personClients(params) {
    return request({
        url: "/clients/getPersonClients",
        method: "get",
        params
    });
}
export function update(data) {
    return request({
        url: `/clients/${parseInt(data.get('id'))}`,
        method: 'post',
        data
    })
}

export function sendClientProductsFromFilial(data) {
    return request({
        url: `/clients/sendClientProductsFromFilial/${parseInt(data.client_id)}`,
        method: 'put',
        data
    })
}

export function showClientProductsFromFilial(params) {
    return request({
        url: `/clients/showClientProductsFromFilial`,
        method: 'get',
        params
    })
}

export function destroy(id) {
    return request({
        url: `/clients/${id}`,
        method: 'delete',
    })
}
export function clientDashboard(params) {
    return request({
        url: `/clients/clientDashboard/${params.client_id}`,
        method: 'get',
        params
    })
}

export function getClientAddresses(params) {
    return request({
        url: `/clients/getClientAddresses/${params.id}`,
        method: 'get',
        params
    })
}

export function updateClientAddresses(data) {
    return request({
        url: `/clients/updateClientAddresses/${data.id}`,
        method: 'put',
        data
    })
}

export function recieverDealsList(params) {
    return request({
        url: `/clients/recieverDealsList/${params.id}`,
        method: 'get',
        params
    })
}