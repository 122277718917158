import _ from 'lodash';
export default {
    props: {
        query:{
            default: Object
        }
    },
  data() {
      return {
          drawerCreate: false,
          drawerShow: false,
          drawerUpdate: false,
          loadingData: false,
          reloadList: false,
          selectedItem: {}
      }
  },

  created() {
      this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  mounted() {
      if (_.isFunction(this.controlExcelData)) {
          this.controlExcelData();
      }
  },    
  methods: {
        newInventoryItem(newItem){
            if (_.isFunction(this.inventorySearch)) {
                this.inventorySearch(this.query).then(res => {
                    if(this.inventoryItems && this.inventoryItems.length > 0){
                        let max_id =  Math.max(...this.inventoryItems.map(obj => obj.id));
                        this.dispatch(max_id);
                    }
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }else if (_.isFunction(this.updateInventory)) {
                this.updateInventory(this.query).then(res => {
                    if(this.inventoryItems && this.inventoryItems.length > 0){
                        let max_id =  Math.max(...this.inventoryItems.map(obj => obj.id));
                        this.dispatch(max_id);
                    }
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
            
        },
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        afterFetchData() {
            this.reloadList = false;
        },
        fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort,};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateInventory(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },

  },
}
