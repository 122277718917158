import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: false,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    airway_bill_number: {
        show: true,
        title: i18n.t('message.airway_bill_number'),
        sortable: true,
        column: 'airway_bill_number'
    },

    custom_id: {
        show: true,
        title: i18n.t('message.custom_id'),
        sortable: true,
        column: 'custom_id'
    },

    from_filial : {
        show: true,
        title: i18n.t('message.from_filial'),
        sortable: true,
        column: 'from_filial'
    },
    to_filial : {
        show: true,
        title: i18n.t('message.to_filial'),
        sortable: true,
        column: 'to_filial'
    },

    containersCount : {
        show: true,
        title: i18n.t('message.containersCount'),
        sortable: true,
        column: 'containersCount'
    },

    total_weight : {
        show: true,
        title: i18n.t('message.weight_in_kg'),
        sortable: true,
        column: 'total_weight'
    },

    date : {
        show: false,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
