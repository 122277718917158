import {i18n} from '@/utils/i18n';

export const rules = {
    date: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.date')}), trigger: 'change' },
    ],
    status: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.status')}), trigger: 'change' },
    ],
    courier_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.courier')}), trigger: 'change' },
    ],
};
