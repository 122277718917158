export const filter = {
    id : '',  
    name: '', 
    sip_number: '', 
    server_url: '', 
    port: null, 
    extension_type: '', 
    created_at: '',
    updated_at: ''  
  };
  