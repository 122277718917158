<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.directionTariff')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(item, index) in list" :key="'item-' + index" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      from_filial_id:{
        default: null
      },
      to_filial_id:{
        default: null
      },
      show_all:{
        default: false
      },
    },
    watch:{
      watchFilials:{
        handler: function() {
            if((this.from_filial_id && this.to_filial_id) || this.show_all){
                let query = {}; 
                if(!this.show_all){
                  query = { 
                    from_filial_id: this.from_filial_id, 
                    to_filial_id: this.to_filial_id
                  }
                }
                
               this.updateInventory(query).then( res => {
                    this.list = JSON.parse(JSON.stringify(this.inventoryItems));
                    if(this.firstUpdate){
                      this.selected = this.id;
                      this.firstUpdate = false;
                    }else{
                      this.selected = null;
                      this.$emit('input', null);
                    }
                });
            }else{
                this.list = [];
            }
        },
        deep: true,
        immediate: true
      },
    },
    data() {
      return {
        selected: null,
        firstUpdate: true,
        list: [],
      }
    },
    computed: {
      ...mapGetters({
        inventoryItems: 'directionTariffs/inventory',
      }),
      watchFilials() {
        return this.from_filial_id + this.to_filial_id;
      }
    },
    methods: {
      ...mapActions({
        updateInventory: 'directionTariffs/inventory',
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
