<template>
  <div>
    <div class="app-modal_In">
      <div class="app-modal__header d-flex f-between p-5 m-title-modal">
        <p class="large--title m-0">{{ $t('message.add_flight') }}</p>
        <div>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
    </div>
    <el-row>
        <el-col :span="12">
            <div class="gc-card__body px-3 py-4">
                <div class="app-form__group mb-0">
                    <div
                        class="
                            gc-card
                            rounded-sm
                            mb-4
                            p-relative
                            mr-3
                        "
                    >
                        <div class="px-3 pb-3">
                            <el-form label-position="top" label-width="100px" :model="formLabelAlign">
                                <el-form-item
                                    :label="$t('message.pick_flight')"
                                    prop="board_id"
                                    class="label_mini mb-0"
                                >
                                    <select-board
                                    :size="'medium'"
                                    :placeholder="$t('message.pick_flight')"
                                    >
                                    </select-board>
                                </el-form-item>
                                <el-form-item
                                    :label="$t('message.pick_send')"
                                    prop="board_id"
                                    class="label_mini mb-0"
                                >
                                <el-date-picker
                                        v-model="value1"
                                        class="w100 h32"
                                        type="date">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    :label="$t('message.pick_arrive')"
                                    prop="board_id"
                                    class="label_mini mb-0"
                                >
                                <el-date-picker
                                        v-model="value2"
                                        class="w100 h32"
                                        type="date">
                                    </el-date-picker>
                                </el-form-item>
                            </el-form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
        <el-col :span="12">
            <div class="info__arrived">
                <div
                    class="
                        gc-card
                        rounded-sm
                        mb-4
                        p-relative
                        mr-3
                    "
                >
                    <div class="gc-card__body px-3 py-2">
                        <div class="text-descr">
                            <span
                                class="
                                    text-descr__label
                                    d-block
                                "
                                >{{
                                    $t("message.country")
                                }}</span
                            >
                            <div
                                class="
                                    d-block
                                    align-center
                                    font-medium
                                "
                            >
                            <div class="tracking__box">
                                <div class="tracking__item">
                                    <div class="tracking__date">
                                        <span class="tracking__bold">22:05</span>
                                        <span class="tracking__light">9 дек, пт</span>
                                    </div>
                                    <div class="tracking__location">
                                        <span class="tracking__bold">Ташкент</span>
                                        <span class="tracking__light">Ташкент, TAS</span>
                                    </div>
                                </div>
                                <div class="tracking__item">
                                    <div class="tracking__date">
                                        <span class="tracking__bold">08:35</span>
                                        <span class="tracking__light">10 дек, сб</span>
                                    </div>
                                    <div class="tracking__location">
                                        <span class="tracking__bold">Сеул</span>
                                        <span class="tracking__light">Инчеон, ICN</span>
                                    </div>
                                </div>
                                <div class="tracking__item">
                                    <div class="tracking__date">
                                        <span class="tracking__bold">08:35</span>
                                        <span class="tracking__light">10 дек, сб</span>
                                    </div>
                                    <div class="tracking__location">
                                        <span class="tracking__bold">Дубай</span>
                                        <span class="tracking__light">Дубай, DXB</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
    <div class="
        gc-card
        rounded-sm
        mb-4
        p-relative
        mx-3
    ">
        <div class="rounded-sm">
            <el-table
                class="rounded-sm"
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="date"
                    label="Название"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="Количество"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="Вес">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="Статус">
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="Штрихкод">
                </el-table-column>
            </el-table>
        </div>
    </div>
    <div class="mx-3">
        <select-board
            :size="'medium'"
            :placeholder="$t('message.pick_product')"
            >
        </select-board>
    </div>
  </div>
</template>

<script>
import selectBoard from "@/components/inventory/select-deal-board";
export default {
    data: () => ({
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        },
        value1: '',
        value2: '',
        tableData: [{
            date: 'Монитор Dell',
            name: '2',
            address: '3.8',
            status: 'active',
            code: '67804289323'
          }, {
            date: 'Монитор LG',
            name: '3',
            address: '2.8',
            status: 'active',
            code: '67804289323'
          },]
    }),
    components: {
        selectBoard
    }
}
</script>

<style>

</style>
