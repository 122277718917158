import {i18n} from '@/utils/i18n';

export const rules = {
    title: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    start: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.begin_date')}), trigger: 'change' },
    ],
};
