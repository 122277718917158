import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    sip_number: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.sip_number')}), trigger: 'change' },
    ],
    sip_password: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.sip_password')}), trigger: 'change' },
    ],
    server_url: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.server_url')}), trigger: 'change' },
    ],
    port: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.port')}), trigger: 'change' },
    ],
    extension_type: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.extension_type')}), trigger: 'change' },
    ],
};
