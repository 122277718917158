import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/sipAccounts',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/sipAccounts',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/sipAccounts/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/sipAccounts/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/sipAccounts/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/sipAccounts/${id}`,
        method: 'delete'
    })
}

export function getSipAccountsFromServer() {
    return request({
        url: '/sipAccounts/getSipAccountsFromServer',
        method: 'get'
    })
}