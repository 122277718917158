<template>
  <div>
	<div class="main-services">
		<div>
			<i class="el-icon-s-operation"></i>
			<span>{{$t('message.services')}}</span>
		</div>
		<div class="select-services" v-if="permissions.some(per => per.slug == 'services.show')">
			<el-select 
				v-model="service_id" 
				clearable
				:placeholder="$t('message.services')" 
				class="w-100" 
				size="medium"
				filterable
				:filter-method="queryFilter"
				@change="selectedServiceItem"
				>
				<el-option 
					v-for="(item, index) in s_items" :key="index" 
					:label="item.name" 
					:value="item.id"
				>
				</el-option>
			</el-select>
		</div>
	</div>
	<div class="main-services-2" v-if="tableData && tableData.length > 0">
		<el-row :gutter="20">
			<el-col :span="24">
				<el-table
					:data="tableData"
					border
					style="width: 100%"
					v-loading="loadingData"
					show-summary
					:summary-method="getSummariesForService"
				>
					<el-table-column
						type="index"
						width="50">
					</el-table-column>

					<el-table-column :label="$t('message.name')">
						<template slot-scope="item">
							<span v-if="item.row.deal_id">{{ item.row.service.name }}</span>
							<span v-else> {{ item.row.name }} </span>
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.percent')" width="150">
						<template slot-scope="item">
							<el-input 
								v-if="item.row.percent && item.row.percent > 0"
								v-model="item.row.percent" 
								:placeholder="$t('message.percent')" 
								size="medium" 
								@input="changePercent(item.row)"
								:disabled="disableRow"
							>
								<template slot="append">%</template>
							</el-input>
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.total_price')"  width="150">
						<template slot-scope="item">
							<crm-money-input 
								v-if="item.row.service_sum && item.row.service_sum > 0"
								:label="$t('message.total_price')"
								v-model="item.row.service_sum" 
								:old="item.row.service_sum"
								:disabled="disableRow"
							>
							</crm-money-input>
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.rate')" width="150">
						<template slot-scope="item">
							{{ item.row.rate }}
						</template>
					</el-table-column>

					<el-table-column :label="$t('message.currency')"  width="120">
						<template slot-scope="item">
							<select-currency 
								v-if="item.row.service_sum && item.row.service_sum > 0"
								v-model="item.row.currency_id" 
								:id="item.row.currency_id" 
								@c-change="updateCurrency(item.row)"
								:disabled="disableRow"
								>
							</select-currency>
						</template>
					</el-table-column>
						
					<el-table-column :label="$t('message.total_price') + ' $'" width="130">
						<template slot-scope="item">
							<span v-if="item.row.service_sum && item.row.service_sum > 0">
								{{ countTotalMoney(item.row) | formatMoney() }}
							</span>
							<span v-else>
								{{ item.row.total_money | formatMoney() }}
							</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('message.delete')" width="100">
						<template slot-scope="item">
							<el-button 
								type="danger" 
								icon="el-icon-delete" 
								circle 
								@click="item.row.deal_id ? deleteItemRow(item.row) : removeItemRow(item.row)" 
								size="small"
								v-if="permissions.some(per => per.slug == 'services.delete')"
							></el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
	</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CrmMoneyInput from '@/components/crm/crm-money-input';
import SelectCurrency from '@/components/selects/select-currency';
import _ from 'lodash';
import { formatMoney, formatNumber } from "@/filters/index";
// import form from "@/utils/mixins/form";
export default {
	// mixins: [form],
  components: { CrmMoneyInput, SelectCurrency },
	props:{
		opened:{
			type: Boolean,
			default: null
		},
		cargo_type: {
			type: String,
			default: 'cargo'
		},
		emptyData: {
			type: Boolean,
			default: false
		},
		parcel: {
			default: Object,
		}, //[Object, Array],
		allItems: {
			type: Array
		},
		deal_id: {
			default: null
		},
		deal_services: {
			type: Array,
			default: null
		},
		services: {
			type: Array,
			default: null
		}
	},
	computed: {
		...mapGetters({
			currencies: "currency/inventory",
			getServices: 'services/services',
			authUser: "auth/user",
			permissions: "auth/permissions",
		}),
		totalProductPriceParcel() {
			if(this.parcel.parcel_products && this.parcel.parcel_products.length > 0){
				return this.parcel.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
			}
			return 0;
    	},
		totalProductPriceCargo() {
			if(this.allItems && this.allItems.length > 0){
				return this.allItems.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
			}
			return 0;
    	},
		disableRow(){
			if (this.permissions.some(per => per.slug == 'services.update')) {
				return false; 
			}
			else return true;
		}
	},
	watch: {
		'services': {
			handler: function (newVal){
				if (!_.isEmpty(newVal)) {
					this.s_items = JSON.parse(JSON.stringify(newVal));
				}
			},
			deep: true
		},
		'deal_services':{
			handler: function(data){
				this.tableData = JSON.parse(JSON.stringify(data));
			}
		},
		'emptyData': {
			handler: function(newVal){
				if (newVal) {
					this.tableData = [];
					this.s_items = [];
				}
			},
			deep: true
		},
		'parcel': {
			handler: function (data){
				if (!_.isEmpty(data))
					this.transformation(data);
			},
			deep: true
		},
		'allItems': {
			handler: function (data){
				if (!_.isEmpty(data)) this.transformation(data);
			},
			deep: true
		},
		'tableData': {
			handler: function(data){
				this.updateServices(data);
			},
			deep: true
		},
	},
	data(){
		return {
			service_id: null,
			tableData: [],
			s_items: [],
			loadingData: false,
			state: ''
		}
	},
	methods: {
		...mapActions({
			show: 'services/show',
			updateServices: 'services/updateServices',
			deleteDealServiceItem: 'services/deleteDealServiceItem',
			querySearchServices: 'services/inventory'
		}),
		
		queryFilter(queryString) {
			let query = {queryString: queryString, deal_type: this.cargo_type};
				setTimeout(() => {
					this.querySearchServices(query).then(() => {
						if (!_.isEmpty(this.services)) {
							this.s_items = JSON.parse(JSON.stringify(this.services));
						}
					});
				}, 1000);
		},
		selectedServiceItem(service_id){
			if (!_.find(this.tableData, {'service_id': service_id})) {
				if (!this.loadingData) {
					this.loadingData = true;
					this.show(service_id).then(res => { 
						this.loadingData = false; 
						this.setFilterServiceData(res.service);
						this.service_id = null;
						}).catch((err) => {
							this.$alert(err);
						});
				}
			}
			else  this.service_id = null;
		},
		setFilterServiceData(data){
			let rate = null;
			let total_money = 0;
			if (data.currency) {
				rate = parseFloat(data.currency.rate).toFixed(7);
			}
			if (this.cargo_type == 'cargo' && data.percent) {
				if (!_.isEmpty(this.allItems) ) {
					total_money = this.allItems.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
				}
			}
			else if(this.cargo_type == 'parcel' && data.percent){
				if (!_.isEmpty(this.parcel.parcel_products)) {
					total_money = this.parcel.parcel_products.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
				}
			}
			total_money = _.round((total_money * (data.percent / 100)), 2); 

			this.tableData.push({
				'id': null,
				'service_id': data.id,
				'deal_id': null,
				'name': data.name,
				'percent': data.percent,
				'service_sum': data.service_sum,
				'currency_id': data.currency_id,
				'rate': rate,
				'status': data.radio,
				'currency': data.currency,
				'total_money': total_money,
				'new_service': true
			});
		},
		countTotalMoney(row){
			if (row.service_sum && row.rate) {
				let total_money = _.round((row.service_sum * row.rate), 2);
				this.$set(row, 'total_money', total_money);
				return total_money;
			}
			else { this.$set(row, 'total_money', 0); return 0; };
		},
		removeItemRow(line){
			if (this.tableData && this.tableData.length > 0) 
				this.tableData.splice(this.tableData.indexOf(line), 1);
		},
		deleteItemRow(item){
			if (item.id) {
				this.$confirm(
					this.$t('message.do_you_really_want_to_do_this'),
					this.$t("message.warning"), {
						confirmButtonText: this.$t("message.yes"),
						cancelButtonText: this.$t("message.no"),
						type: "warning"
					}
					)
					.then(() => {
						this.deleteDealServiceItem(item.id).then((res) => {
							this.tableData.splice(this.tableData.indexOf(item), 1);
							this.$alert(res)
						}).catch((err) => {
							this.$alert(err)
						})
					})
					.catch(() => {
					this.$message({
						type: "warning",
						message: this.$t("message.operation_canceled")
					});
					});
			} 
		},
		updateCurrency(item){
			if (item.currency_id) {
				let currency = _.find(this.currencies, function (c) {return c.id == item.currency_id});
				let rate = parseFloat(currency.rate.toFixed(7));

				this.$set(item, 'currency', currency);
				this.$set(item, 'rate', rate);
			}
			else {
				this.$set(item, 'rate', 0);
			}
		},
		changePercent(item){
			let total_money = 0;
			if (item.percent){
				if (this.cargo_type == 'cargo') {
					total_money = _.round(this.totalProductPriceCargo * (item.percent / 100), 2);  
				}
				else {
					total_money = _.round(this.totalProductPriceParcel * (item.percent / 100), 2);
				}
			}
			this.$set(item, 'total_money', total_money);
		},
		transformation(data){
			let total_money = 0;
			let array = _.isArray(data) ? data : data.parcel_products;

			if (!_.isEmpty(array)) {
				total_money = array.reduce((total, product) => parseFloat(total) + (parseFloat(product.product_price) || 0), 0);
			}
			else total_money = 0;
			
			if(this.tableData){
				this.tableData.forEach(item => {
					let amount = 0;
					if (item.percent) {
						amount = _.round((total_money * (item.percent / 100)), 2); 
						this.$set(item, 'total_money', amount);
					}
				});
			}
			
		},
		getSummariesForService(param) {
         const { columns, data } = param;
         const sums = [];
			let total_money = 0;
			if (!_.isEmpty(data)) {
				total_money = data.reduce((acc, item) => acc + item.total_money, 0);
			}

         columns.forEach((column, index) => {
				if (index === 1) {
					sums[index] = this.$t('message.total_amount');
					return;
				}
				if (index == 6) {
					sums[index] = formatMoney(total_money, 2);
					return;
				}
			});

			return sums;
      },
	} 
}
</script>

<style lang="scss">
.main-services {
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.select-services{
	width: 80%;
}
.main-services-2{
	margin-top: 20px;
}
  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .value {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .link {
        font-size: 12px;
        color: #b4b4b4;
      }
    }
  }
</style>