<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.source')" filterable clearable
      :size="size" :disabled="disabled" class="w-100">
      <el-option v-for="(source,index) in sortedTypes" :key="'items-' + index" :label="source.name" :value="source.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      disabled: {
        default: false,
      },
      id:{
        default: null
      },
      except_ids:{
        default: Array
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
        items: [
            {
                id: 1,
                name: this.$t('message.only_client')
            },
            {
                id: 2,
                name: this.$t('message.sender_reciever')
            },
            {
                id: 3,
                name: this.$t('message.partner')
            }
        ]
      }
    },
    computed: {
      sortedTypes(){
        return this.items.filter(el => !this.except_ids.includes(el.id));
      }
    },
    methods: {
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
