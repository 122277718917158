<template>
    <div id="chart">
        <apexchart type="radialBar" height="260" :options="chartOptions" :series="ser_series"></apexchart>
    </div>
</template>

<script>
import apexchart from "apexcharts";
export default {
    props: {
        percent: {
            default: 0,
        },
    },
    computed: {
        ser_series() {
            return [Math.round(this.percent * 100)];
        },
    },
    data: () => ({
        series: [70],
        chartOptions: {
            chart: {
                height: 260,
                type: 'radialBar',
                foreColor: '#fff',
            },
            fill: {
                colors: ['#002672']
                },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        value: {
                            fontSize: '30px',
                            offsetY: 10
                        },
                        name: {
                            fontSize: '30px',
                            show: false
                        },
                    },
                },
                
                
            },
            labels: [''],
            stroke: {
                lineCap: "round",
            },
        },

        
    }),
}
</script>

<style>

</style>
