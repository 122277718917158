import { store, directionTariffPartners, partnerDirectionTariffs, updatePartnerPricesByTariff } from "@/api/settings/partnerTariffPrices";

export const actions = {

    directionTariffPartners({ commit }, params) {
        return new Promise((resolve, reject) => {
            directionTariffPartners(params)
                .then(res => {
                    commit('PARTNER_TARIFF_PRICES', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    partnerDirectionTariffs({ commit }, params) {
        return new Promise((resolve, reject) => {
            partnerDirectionTariffs(params)
                .then(res => {
                    commit('TARIFF_PARTNER_PRICES', res.data.result.data.partnerTariffPrices);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updatePartnerPricesByTariff({ commit }, data) {
        return new Promise((resolve, reject) => {
            updatePartnerPricesByTariff(data)
                .then(res => {
                    commit('UPDATE_DEAL_PARTNER_PRICES', res.data.result.data.partnerTariffPrice);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
