import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, containers) => {              
        state.list = containers;        
    },
    SET_INVENTORY: (state, containers) => (state.inventory = containers),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_FILIAL_PRODUCTS_PAGINATION: (state, pagination) => {
        state.filial_products_pagination[pagination.key] = pagination.value
    },
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    SET_ALL_PACKAGES_AND_PRODUCTS: (state, listOfProds) => (state.allPackagesAndProducts = listOfProds),
    SET_READY_TO_FLIGHT: (state, containers) => (state.readyToFlight = containers),
    SET_INCOMING_FILIAL_CONTAINER: (state, containers) => (state.incomingFilialContainers = containers),
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, container) => {
        state.model = container
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },

    EMPTY_INVENTARIZATION: (state) => {
        state.incomingFilialContainers = [];
        state.readyToFlight = [];
    }
};
