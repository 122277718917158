import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, products) => {              
        state.list = products;        
    },
    SET_REPLACE_DETAILS: (state, replace_details) => {              
        state.replace_details = replace_details;        
    },
    SET_INVENTORY: (state, products) => (state.inventory = products),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, product) => {
        state.model = product
      
        if(!state.model.names || typeof state.model.names == "string"){
            state.model.names = {};
        }

        if (_.size(product.images) > 0) {
            // state.model.images = [];
            for (const key in product.images) {
                state.images_list.push({
                    url: product.image_url + '/' + product.images[key].path,
                    id: product.images[key].id,
                    name: 'image-' + parseInt(parseInt(key) + 1),
                });
            }
        }
    },
    SET_FILIAL_PRODUCTS: (state, products) => {
        state.filialProducts = products
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));       
        state.images_list = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    }
};
