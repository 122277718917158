import { state } from "./state";

export const getters = {
    measuringTypes: state => state.measuringTypes,
    list: state => state.list,
    inventory: state => state.inventory,
    model: state => state.model,
    dealProducts: state => state.dealProducts,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort ,
    count: state => state.count,
    filialDealProducts: state => state.filialDealProducts,
    parcelProducts: state => state.parcelProducts,
}