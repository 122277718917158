const mode = window.localStorage.getItem('mode')
const oldVersion = window.localStorage.getItem('oldVersion')
const leftVersion = window.localStorage.getItem('leftVersion')
const background = window.localStorage.getItem('background')
const radio = window.localStorage.getItem('radio')

export const state = {
    mode: mode ? JSON.parse(mode) : true,
    oldVersion: oldVersion ? JSON.parse(oldVersion) : false,
    leftVersion: leftVersion ? JSON.parse(leftVersion) : true,
    links: [
        {
            id: 3,
            name: 'message.orders_name',
            link: 'orders'
        },
    ],
    radio: radio ? JSON.parse(radio) : 1,
    is_expanded: false,
    background: background ? JSON.parse(background) : {
        id: 55,
        check: true,
        url: '/img/background/blur.jpg',
        name: 'White',
        mode: true
    },

    lock: {
        password: 0, status: false
    },
    dateToday: '',
    getLockTimer: {
        isIdle: true,
        isTime: 0,
        disable: true
    },
    systemLanguages: [
        {
            code: 'ru',
            image: '',
        },
        {
            code: 'en',
            image: ''
        },
        

    ]
}
