import { index, inventory, getTypes, getGenders, freeClients, filterClientsByType, personClients, show, clientDeals, store, 
    update, destroy, clientDashboard, sendClientProductsFromFilial, showClientProductsFromFilial, getClientAddresses, updateClientAddresses,
    recieverDealsList, } from "@/api/contractors/clients";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.clients);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    inventorySearch({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_SEARCH_INVENTORY", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    freeClients({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            freeClients(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    personClients({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            personClients(params).then(res => {
                commit("SET_PERSON_CLIENTS", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    filterClientsByType({ commit }, data) {
        return new Promise((resolve, reject) => {
            filterClientsByType(data).then(res => {
                commit("SET_PERSON_CLIENTS", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.client);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    showClientProductsFromFilial({ commit }, params) {
        return new Promise((resolve, reject) => {
            showClientProductsFromFilial(params)
                .then(res => {
                    commit('SET_CLIENT_FILIAL_PRODUCTS', res.data.result.data.deals);
                    commit('SET_MODEL', res.data.result.client);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    clientDeals({ commit }, id) {
        return new Promise((resolve, reject) => {
            clientDeals(id)
                .then(res => {
                    commit('SET_DEALS', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getClientAddresses({ commit }, params) {
        return new Promise((resolve, reject) => {
            getClientAddresses(params)
                .then(res => {
                    commit('SET_CLIENT_ADDRESSES', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateClientAddresses({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateClientAddresses(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTypes({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getTypes(params).then(res => {
                commit("SET_TYPES", res.data.result.data.types);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },

    getGenders({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getGenders(params).then(res => {
                commit("SET_GENDERS", res.data.result.data.genders);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    sendClientProductsFromFilial({ commit }, data) {
        return new Promise((resolve, reject) => {
            sendClientProductsFromFilial(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    clientDashboard({ commit }, data) {
        return new Promise((resolve, reject) => {
            clientDashboard(data)
                .then(res => {
                    commit("SET_CLIENT_DASHBOARD", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    recieverDealsList({ commit }, data) {
        return new Promise((resolve, reject) => {
            recieverDealsList(data)
                .then(res => {
                    commit("SET_RECIEVER_DEALS_LIST", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },




    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
