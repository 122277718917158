export const filter = {
  id : '',
  search: '',
  name: '',
  first_name: '',
  phone: '',
  surname: '',
  patronymic: '',
  role_id: null,
  email: '',
  password: '',
  pin_code: '',
  verified: '',
  telegram_chat_id: null,
  locked: false,
  status: '',
  last_login: '',
  is_employee: '',
  shop_id: null,
  access_token: '',
  created_at: '',
  updated_at: '',
  from_date: '',
  sip_account_id: null,
  to_date: '',
  user_telegram_status: null,
};
