export const filter = {
    id : '',  
    custom_id : '',  
    from_filial_id : '',  
    to_filial_id : '',  
    airway_bill_number : '',  
    date: '', 
    created_at: '',
    updated_at: ''  
  };
  