import {i18n} from '@/utils/i18n';

export const rules = {
    comment: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.comment')}), trigger: 'change' },
    ],
    expire_date: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.expire_date')}), trigger: 'change' },
    ],
};
