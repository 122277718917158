export const model = {
    id: null,
    name: '',
    sip_number: '',
    sip_password: '',
    number_exist: false,
    server_url: '', 
    port: null, 
    extension_type: '', 
    phones: [], 
    created_at: '',
    updated_at: '',
  };
  