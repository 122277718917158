<template>
<div class="message__all_exxpres">
    <div v-show="message" class="message__all" :class="mode ? 'message__allday' : 'message__allnight'">
        <Transition name="bounce">
            <div v-click-outside="messageOutside" v-show="message"  class="message__box">
                <div class="message__header">
                    <h5>{{$t('message.notifications')}}</h5>
                    <el-button 
                        :disabled="loadingData"
                        @click="debouncedFetchData()" 
                        type="primary" icon="el-icon-refresh" 
                        circle size="mini">
                    </el-button>
                </div>
                <div>
                    <ul class="message__ul" 
                        v-loading="loadingData"
                        element-loading-text="Loading..."
                        element-loading-spinner="el-icon-loading"   
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                    >
                        <li v-for="notification in notificationList" :key="notification.id" class="message__li">
                            <a>
                                <div class="message__info">
                                    <i v-if="notification.status == 1" @click="editProduct(notification)" class="fa-solid fa-edit success-bg mm__cursor__pointer"></i>
                                    <i v-if="notification.status == 2" class="fa-solid fa-flag warning-bg"></i>
                                    <i v-if="notification.status == 3" class="fa-solid fa-flag danger-bg"></i>
                                    <div class="message__about">
                                        <div class="mes-ynsage__date" style="display:flex; justify-content: space-between;">
                                            <span class="date__text">{{ notification.created_at }}</span>
                                            <div class="message__animation">
                                                <transition name="slide-fade">
                                                    <span v-if="notification.unread" class="message__readed">Прочитано</span>
                                                </transition>

                                                <transition name="slide-fade">
                                                    <span v-if="(!authUser.client_id  || notification.type === 4) && !notification.unread" class="message__unreaded">Не прочитано</span>
                                                    <span v-if="!notification.unread && authUser.client_id && notification.type !== 4"
                                                        @click.stop="updateNotificationStatus(notification)"  
                                                        class="message__unreaded mm__cursor__pointer">
                                                            Не прочитано
                                                    </span>
                                                </transition>
                                            </div>
                                        </div>
                                        <div>
                                            <span >{{ messageHeader(notification) }}</span>
                                        </div>
                                        <div>
                                            <p v-if="!notification.readMore">
                                                {{ notification.comment.slice(0,100) }}
                                                <br> <span class="message__read" v-if="!notification.readMore && notification.comment.length > 100" @click.stop="notification.readMore = true">...{{$t('message.show')}}</span>
                                            </p>
                                            <p v-if="notification.readMore">
                                                {{ notification.comment }}
                                                <br> <span class="message__hide" v-if="notification.readMore && notification.comment.length > 100" @click.stop="notification.readMore = false">{{$t('message.roll_up')}} </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div @click="drawerNotification = true" class="message__footer">
                    <a>{{$t('message.more')}}</a>
                </div>
            </div>
        </Transition>
    </div>
    <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          :append-to-body="true"
           class="bg-se"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <product-update
            :selectedItem="selectedItem"
            :notification_id="selectedItem.notification_id"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></product-update>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerNotification"
          :append-to-body="true"
           class="bg-se"
          size="70%"
          ref="drawerNotification"
          @opened="drawerOpened('drawerNotificationChild')"
          @closed="drawerClosed('drawerNotificationChild')"
        >
          <AllMessages
            ref="drawerNotificationChild"
            drawer="drawerNotification"
          ></AllMessages>
        </el-drawer>

    </div>


</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductUpdate from "@/views/product/components/crm-update";
import DealUpdate from "@/views/deals/components/add-modal";
import AllMessages from "./AllMessages";

export default {
    props: ['message'],
    components: {
        ProductUpdate,
        DealUpdate,
        AllMessages
    },
    data: () => ({
        notificationList: [],
        selectedItem: {},
        drawerUpdate: false,
        drawerNotification: false,
        loadingData: false,
    }),
    created() {
        this.debouncedFetchData();
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            authUser: "auth/user", 
            list: "systemNotifications/user_notifications",
        }),
    },
    methods: {
        ...mapActions({
            updateList: "systemNotifications/showWhenSomethingChanges",
            updateNotification: "systemNotifications/update",
        }),
        messageHeader(notification){
            if(notification.type === 4){
                return this.$t('message.announcement')
            }
            return notification.type_name;
        },
        editProduct(notification){
            this.selectedItem = {id: notification.notificationable_id, notification_id: notification.id};
            this.drawerUpdate = true;
        },
        updateNotificationStatus(notification){
            if(notification.type !== 4){
                notification.status = 3;
                this.updateNotification(notification)
                    .then(res => {
                        notification.unread = true;
                    }).catch(err => { })
            }

        },
        messageOutside () {
            this.$emit('outside')
        },
        debouncedFetchData: _.debounce(
        function(val) {
          this.fetchData();
        }, 300), 

        fetchData() {
            this.loadingData = true;
            this.updateList({page: 1, per_page: 30})
            .then(res => {
                this.notificationList = JSON.parse(JSON.stringify(this.list));
                for(let element of this.notificationList){
                    if(element.type == 1){
                        if(!this.authUser.client_id){
                            let comment = this.$t("message.user_created_with_id", {   
                                user: element.created_user ? element.created_user.name : '', 
                                item: element.type_name, 
                                id: element.notificationable_id,
                                item_name: element.notificationable ? element.notificationable.name : '',
                                deal_ids: element.deals ? element.deals : '',
                            });
                            this.$set(element, 'comment', comment);
                        }else{
                            let comment = this.$t("message.user_confirmed_notification", {   
                                user: element.confirmed_user ? element.confirmed_user.name : '', 
                                item: element.type_name,
                                id: element.notificationable_id,
                                item_name: element.notificationable ? element.notificationable.name : '',
                                deal_ids: element.deals ? element.deals : '',
                            });
                            this.$set(element, 'comment', comment);
                        }
                    }
                    
                    this.$set(element, 'readMore', false);
                }
                this.loadingData = false;
            }).catch(err => { this.loadingData = false; })
        },

        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.debouncedFetchData();
        },
    }
}
</script>

<style lang="scss">
.message__all_exxpres{
    .message__box {
        width: 350px;
        margin-left: auto;
        background: white;
        border-radius: 13px;
        margin-right: 170px;
        margin-top: 60px;
        position: relative;
    }

    .message__allday {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        position: fixed;
        background: rgba(0,0,0,0.1);
        width: 100%;
        height: 100%;
    }
    .message__allnight {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        position: fixed;
        background: rgba(0,0,0,0.1);
        width: 100%;
        height: 100%;
    }

  .bounce-enter-active {
        animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
        0% {
        transform: scale(0);
        }
        50% {
        transform: scale(1.10);
        }
        100% {
        transform: scale(1);
        }
    }
    .message__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        padding: 15px 20px;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .message__header h5 {
        margin: 0;
        font-size: 16px;
    }
    .message__image {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 10px;
    }
    .message__info {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .message__li {
        width: 100%;
        margin-left: 0 !important;
        padding: 10px 10px;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .message__li:last-child {
        border: none;
    }
    .message__li a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .message__dot {
        display: block;
        width: 8px;
        height: 8px;
        padding: 5px;
        border-radius: 50%;
        background: #0d67c9;
    }
    .message__about {
        width: 95%;
    }
    .message__about p {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #718096;
    }
    .message__ul {
        display: flex;
        flex-direction: column;
        height: 350px;
        overflow-y: auto;
        padding-left: 0;
    }
    .message__ul::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
        }

        .message__ul {
        scrollbar-width: thin;
        scrollbar-color: #8d969d;
        }

        .message__ul::-webkit-scrollbar-track {
        background: #ffffff;
        }

        .message__ul::-webkit-scrollbar-thumb {
        background-color: #8d969d;
        border-radius: 10px;
        border: 2px solid #fff0;
        }
    .message__footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
        cursor: pointer;
    }
    .message__footer a {
        padding: 10px 0;
        font-size: 15px;
        color: #718096;
    }
    .message__date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }

    .message__footer a:hover {
        color: #0d67c9;
    }
    .message__tracker {
        display: flex;
        flex-direction: row;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
        padding: 0 20px;
    }
    .message__tracker a {
        cursor: pointer;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .message__tracker a:hover {
        color: #0d67c9;
    }
    .message__read {
        color: #0d67c9;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
    .message__hide {
        color: #0d67c9;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
    .date__text {
        font-size: 11px;
        color: #718096;
    }
    .message__readed {
        font-size: 11px;
        color: #718096;
        display: flex;
        align-items: center;
    }
    .message__unreaded {
        font-size: 11px;
        color: #0d67c9;
    }
    .message__unreaded:hover {
        color: #718096;
    }
    .slide-fade-enter-active {
        transition: all .2s ease-in-out;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 3.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(10px);
        opacity: 0;
    }
    .message__animation {
        display: flex;
        flex-direction: column;
        text-align: right;
        align-self: end;
        right: 5px;
    }
    .warning-bg, 
    .danger-bg, 
    .success-bg {
        display: inline;
        border-radius: 50%;
        padding: 10px;
        margin-right: 10px;
    }
    .danger-bg {
        color: #F56C6C;
        background: #f56c6c24;
    }
    .success-bg {
        color: #67C23A;
        background: #67c23a38;
    }
    .warning-bg {
        color: #E6A23C;
        background: #e6a23c38;
    }
    .mm__cursor__pointer{
        cursor: pointer;
    }
}
</style>
