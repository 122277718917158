<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.region')"
      filterable
      clearable
      :size="size"
      :disabled="disabled"
      class="w-100"
      :loading="loadingData"
      remote 
      :remote-method="searchInventoryWithLoad"
    >
      <el-option
        v-for="(region, index) in regionList"
        :key="'regions-' + index"
        :label="region.name"
        :value="region.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    country_id: {
      default: null,
    },
    city_region_id: {
      default: null,
    },
    disabled: {
      default: false,
    },
    
  },
  watch: {
    id: {
      handler: function (val) {
        if(val && !this.regionList.find(el => el.id === this.id)){
          this.searchInventoryWithLoad();
        }else{
          this.selected = this.id;
        }
      },
      immediate: true,
    },
    city_region_id:{
      handler: function (val) {
        
      },
      immediate: true,
    },
    country_id: {
      handler: function (val) {
        if(this.country_id){
          this.loadingData = true;
          this.updateInventory({country_id: this.country_id, selected_id: this.id}).then(res => {
            this.regionList = JSON.parse(JSON.stringify(this.regions));
            this.loadingData = false;
            setTimeout(() => {
              this.selected = this.id;
            }, 100);
          }).catch(err => {
            this.loadingData = false;
          });
        }else{
            this.regionList = [];
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      regionList: [],
      loadingData: false
    };
  },
  computed: {
    ...mapGetters({
      regions: "regions/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "regions/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    searchInventoryWithLoad(val = ''){
      this.loadingData = true;
      this.searchInventory(val, this);
    },

    searchInventory: _.debounce(function(val, self) {
        self.updateInventory({search: val, country_id: this.country_id, selected_id: this.id})
          .then(() => {
            this.regionList = JSON.parse(JSON.stringify(this.regions));
            this.loadingData = false;
            setTimeout(() => {
              this.selected = this.id;
            }, 100);
          })
          .catch(() => this.loadingData = false)
    }, 500), 
  },
};
</script>
