<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="task_reason_id" :label="$t('message.reason')">
        <select-reason
            :size="'medium'"
            :placeholder="$t('message.reason')"
            :id="form.task_reason_id"
            v-model="form.task_reason_id"
        >
        </select-reason>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="taskDeleteClose()">{{ $t('message.cancel') }}</el-button>
      <el-button @click="destroy()" type="primary" v-loading="loadingButton"
      >{{ $t('message.confirm') }}</el-button>
    </span>
  </div>
</template>

<script>
import selectReason from "@/components/inventory/select-task-reason";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    props: {
      task_id:{
        default: null
      }
    },
    data() {
        return {
            form: {
              task_reason_id: null
            },
        };
    },

    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    created() {},
    components: {
        selectReason,
    },
    computed: {
        ...mapGetters({
            rules: "tasks/rules",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            delete: "tasks/deleteTask"
        }),
        taskDeleteClose() {
            this.$emit('deleteTaskClose', false)
        },
        destroy() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.delete({task_id: this.task_id, task_reason_id: this.form.task_reason_id})
                    .then(res => {
                        this.$alert(res);
                        this.$emit('deleteTaskClose', true);
                    });
                }
            });
            
        },
    },
};
</script>


<style>
</style>
