<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.gender')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(gender,index) in genders" :key="'genders-' + index" :label="gender.name" :value="gender.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.genders && this.genders.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        genders: 'clients/genders'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'clients/getGenders'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
