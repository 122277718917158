export const model = {
  id : null,  
  name: '', 
  code: null, 
  price: 0, 
  currency_id: null, 
  weight: 0, 
  measurement_id: null, 
  product_model_id: null, 
  color_id: null, 
  notification_id: null, 
  is_default: true,   
  categories: [], 
  packages: [], 
  images: [], 
  names: {}, 
  created_at: '',
  updated_at: ''  
  };
  