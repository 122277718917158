<template>
      <el-form-item
        class="label_mini"
        prop="client_id"
      >
      <el-col :span="24">
        <el-select
          :value="selected"
          @input="dispatch"
          :placeholder="'Добавить груз'"
          filterable
          clearable
          :size="size"
          class="w-100"
          :filter-method="filterByType"
          :loading="loadingData"
        >
          <el-option
            v-for="(client, index) in items"
            :key="'clients-' + index"
            :label="client.full_name"
            :value="client.id"
          ></el-option>
        </el-select>
      </el-col>
  
      <!-- <create @crm-close="afterCreated"></create> -->
  
      </el-form-item>
</template>
<script>
  import create from "@/components/createModalsForSelects/company-client-create-modal";
  
  import { mapGetters, mapActions } from "vuex";
  export default {
    components: { create },
    props: {
      size: {
        default: "small",
      },
      placeholder: {
        default: null,
      },
      id: {
        default: null,
      },
    
      model: {
        default: null,
      },
    },
    watch: {
    
      id: {
        handler: function () {
          this.selected = this.id;
        },
        immediate: true,
      },
    },
    data() {
      return {
        selected: null,
        clients: [],
        loadingData: false,
        items: [
            {
                id: 1,
                full_name: 'Macbook pro 13 2019'
            },
            {
                id: 2,
                full_name: 'Airpods 3'
            }
        ]
      };
    },
    computed: {
      ...mapGetters({
        getPersonClients: "clients/personClients",
      }),
    },
    methods: {
      ...mapActions({
        personClients: "clients/personClients",
        filteredClients: "clients/filterClientsByType",
      }),
      afterCreated(data) {
          this.personClients()
      },
      dispatch(e) {
        this.$emit("input", e);
        this.selected = e;
      },
      filterByType(filterValue){
          let filter = null;
          if(filterValue){
            filter = filterValue
          }
          let data = {typeName: filter};
          this.loadingData = true;
          this.debouncedFilter(data, this);
      },
  
      debouncedFilter: _.debounce((data, self) => {
        if (_.isFunction(self.filteredClients)) {
          self.filteredClients(data)
            .then((res) => {
                self.loadingData = false;
                self.clients = self.getPersonClients;
            }).catch((err) => {
                self.loadingData = false;
            });
        }
      }, 2000),
    },
  };
  </script>
  