export const filter = {
    id : '',  
    barcode: '', 
    airway_bill_number: '',
    weight: '', 
    from_filial_id: '', 
    to_filial_id: '', 
    created_at: '',
    updated_at: ''  
  };
  