import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    average_days : {
        show: true,
        title: i18n.t('message.average_days'),
        sortable: true,
        column: 'average_days'
    },
    from_filial_id : {
        show: true,
        title: i18n.t('message.from_where'),
        sortable: true,
        column: 'from_filial_id'
    },
    to_filial_id : {
        show: true,
        title: i18n.t('message.to_where'),
        sortable: true,
        column: 'to_filial_id'
    },
    for_site: {
        show: false,
        title: i18n.t('message.for_site'),
        sortable: true,
        column: 'for_site'
    },
    for_systeam: {
        show: false,
        title: i18n.t('message.for_systeam'),
        sortable: true,
        column: 'for_systeam'
    },
    price_delivery_to_door: {
        show: true,
        title: i18n.t('message.price_delivery_to_door'),
        sortable: false,
        column: 'price_delivery_to_door'
    },
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
