import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import "@/utils/permissions";
import router from './router'
import store from './store'
// links khan
import VueApexCharts from 'vue-apexcharts'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "../public/css/main.css";
import "../public/fonts/fonts/fontawsome/css/all.min.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
Vue.use(PerfectScrollbar);
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { i18n } from "./utils/i18n";
import "@/utils/library";
import '@/utils/v-can';
import * as filters from './filters'; // global filters
import "./directive/index";

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VuePhoneNumberInput);

Vue.use(ElementUI, { locale });

const files = require.context('./components/crm/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import Inputmask from "inputmask";
Vue.directive("mask", {
  bind: function (el, binding) {
    Inputmask(binding.value).mask(el.getElementsByTagName("INPUT")[0]);
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
