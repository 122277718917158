<template>
    <aside class="menu" :class="[ leftVersion ? (mode ? 'theme-Dark-style-left' : 'theme-Light-style-left') : '']">
        <div class="menu-wrapper" :class="{ dark: mode }">
            <!-- menu -->
            <ul class="menu-wrapper-list">
                <!-- items -->
                <li @mouseenter="drawer = false">
                    <router-link class="menu-wrapper-list__item" to="/">
                        <i class="fa-solid fa-chart-simple"></i>
                        Главная
                    </router-link>
                </li>
                <button
                    @click="item.uniqueKey && item.uniqueKey == 'settings' && $route.name !== 'category-layout' ? $router.push({ name: 'category-layout' }) : false"
                    @mouseenter="action(item)" v-for="(item, index) in findPath" :key="index" v-can-or="item.is_show"
                    class="menu-wrapper-list__item"
                    :class="{ active: item.items && item.items.length ? item.items.some(el => el.active) : $route.name == 'settings.index' }">
                    <i :class="item.icon"></i>
                    {{ $t(item.name) | truncate(8, '...') }}
                </button>
            </ul>
        </div>

        <!-- sub menu -->
        <div class="menu-drawer" :class="{ active: drawer }">
            <ul @mouseenter="drawer = true" @mouseleave="drawer = false" class="menu-drawer-submenu"
                :class="{ dark: mode }" v-if="(subMenu && subMenu.length)">
                <div class="menu-drawer-submenu__header">
                    <h3 class="menu-drawer-submenu__title">{{ $t(subMenuTitle)  }}</h3>
                    <i @click="drawer = false" class="el-icon-close"></i>
                </div>

                <li v-if="permissions.some(perm => perm.slug == route.is_show)" @click="drawer = false" v-for="(route, subIndex) in subMenu" :key="subIndex">
                   
                    <router-link v-if="route.link == 'warehousesDashboard.index'" class="menu-drawer-submenu__item"
                        :to="{ name: route.link, params: { warehouse_type: route.params } }">
                        {{ $t(route.name) }}
                    </router-link>
                    <router-link v-else class="menu-drawer-submenu__item" :to="{ name: route.link }">
                        {{ $t(route.name) }}
                    </router-link>
                </li>
            </ul>
            <div @mouseover="drawer = false" class="menu-drawer__bg"></div>
        </div>
    </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        drawer: false,
        direction: 'ltr',
        subMenu: [],
        subMenuTitle: '',
        activeNames: [],
        activeRouteIndex: null,
        activeRouteSubIndex: null,
        
        list: [
        {
            link: false,
            name: 'message.deals',
            tooltip: 'Dashboard',
            icon: 'fa-solid fa-handshake-simple',
            open: false,
            listOne: false,
            is_show:[
                'deals.index',
                'deals.archivedDeals',
                'deals.deletedDeals',
                'dealTypes.index',
                'reasons.index',
            ],
            items: [
                {
                    name: 'message.deal_name',
                    link: 'deals',
                    is_show: 'deals.index'
                },
                {
                    name: 'message.batch_delete',
                    link: 'batch-delete',
                    is_show: 'deals.batch_delete'
                },
                {
                    name: 'message.deletedDeals',
                    link: 'deletedDeals',
                    is_show: 'deals.deletedDeals'
                },
                {
                    name: 'message.deal_reasons',
                    link: 'reasons',
                    is_show: 'reasons.index'
                },
            ]
        },
        {
            link: '/purchase',
            name: 'message.warehouse',
            tooltip: 'User',
            icon: 'fa-solid fa-warehouse',
            open: false,
            listOne: false,
            is_show:[
                'productCirculations.index.waiting',
                'productCirculations.index.toFilial',
                'productCirculations.index.fromFilial',
                'containers.index',
                'containerTypes.index',
            ],
            items: [
                {
                    name: 'message.waiting_product',
                    link: 'waiting_product',
                    is_show: 'productCirculations.index.waiting'
                },
                {
                    name: 'message.sender_warehouse',
                    link: 'warehouse',
                    is_show: 'productCirculations.index.fromFilial'
                },
                {
                    name: 'message.containers',
                    link: 'containers',
                    is_show: 'containers.index'
                },
                {
                    name: 'message.parties',
                    link: 'parties',
                    is_show: 'containers.index'
                },
                {
                    name: 'message.reciever_warehouse',
                    link: 'warehouseget',
                    is_show: 'productCirculations.index.toFilial'
                },
                {
                    name: 'message.shipping_cargo',
                    link: 'deliveryParties',
                    is_show: 'productCirculations.index.toFilial'
                },
                {
                    name: 'message.containerTypes',
                    link: 'containerTypes',
                    is_show: 'containerTypes.index'
                },
            ]
        },
        // {
        //     link: '/purchase',
        //     name: 'message.deliveries',
        //     tooltip: 'User',
        //     icon: 'fa-solid fa-truck',
        //     open: false,
        //     listOne: false,
        //     is_show:[
        //         'deliveries.index',
        //         'transports.index',
        //         'couriers.index'
        //     ],
        //     items: [
        //         {
        //             name: 'message.deliveries',
        //             link: 'delivery',
        //             is_show: 'deliveries.index'
        //         },
        //         {
        //             name: 'message.transports',
        //             link: 'transports',
        //             is_show: 'transports.index'
        //         },
        //         {
        //             name: 'message.couriers',
        //             link: 'couriers',
        //             is_show: 'couriers.index'
        //         },
        //     ]
        // },
        {
            link: '#',
            name: 'message.counterparties',
            tooltip: 'Files',
            icon: 'fa-solid fa-user-group',
            open: false,
            listOne: false,
            is_show:[
                'clients.index'
            ],
            items: [
                {
                    name: 'message.clients',
                    link: 'clients',
                    is_show: 'clients.index'
                }
            ]
        },
        {
            link: '/purchase',
            name: 'message.cash_register',
            tooltip: 'User',
            icon: 'fa-solid fa-sack-dollar',
            open: false,
            listOne: false,
            is_show:[
                'payments.index',
                'costTransactions.index',
                'costTypes.index',
                'bankAccounts.index',
                'currencies.index'
            ],
            items: [
                {
                    name: 'message.payments',
                    link: 'payments',
                    is_show: 'payments.index'
                },
                {
                    name: 'message.payments_for_costs',
                    link: 'costTransactions',
                    is_show: 'costTransactions.index'
                },
                {
                    name: 'message.costTypes',
                    link: 'costType',
                    is_show: 'costTypes.index'
                },
                {
                    name: 'message.bankAccounts',
                    link: 'bankAccounts',
                    is_show: 'bankAccounts.index'
                },
                {
                    name: 'message.currencies',
                    link: 'currency',
                    is_show: 'currencies.index'
                },
            ]
        },
        {
            link: '#',
            name: 'message.tasks',
            tooltip: 'Messages',
            icon: 'fa-regular fa-circle-check',
            open: false,
            listOne: false,
            is_show:[
                'taskBoards.index',
                'tasks.archivedTasks',
                'tasks.deletedTasks',
                'taskReasons.index',
            ],
            items: [
                {
                    name: 'message.tasks',
                    link: 'tasks',
                    is_show: 'taskBoards.index'
                },
                {
                    name: 'message.completed_tasks',
                    link: 'archivedTasks',
                    is_show: 'tasks.archivedTasks'
                },
                {
                    name: 'message.deletedTasks',
                    link: 'deletedTasks',
                    is_show: 'tasks.deletedTasks'
                },
                {
                    name: 'message.taskReasons',
                    link: 'taskReasons',
                    is_show: 'taskReasons.index'
                }
            ]
        },
        {
            link: '#',
            name: 'message.calls',
            tooltip: 'Analytics',
            icon: 'fa-solid fa-phone',
            open: false,
            listOne: false,
            is_show:[
                'sipAccounts.index',
                'phoneContacts.index',
                'phoneNumberForSipAccountOutsideCall.index',
                'callHistory.index'
            ],
            items: [
                {
                    name: 'message.call_history',
                    link: 'callHistories',
                    is_show: 'callHistory.index'
                },
                {
                    name: 'message.phoneContacts',
                    link: 'phoneContacts',
                    is_show: 'phoneContacts.index'
                },
                {
                    name: 'message.sipAccounts',
                    link: 'sipAccount',
                    is_show: 'sipAccounts.index'
                },
                {
                    name: 'message.phoneNumberForSipAccountOutsideCall',
                    link: 'phoneNumberForSipAccountOutsideCall',
                    is_show: 'phoneNumberForSipAccountOutsideCall.index'
                }
            ]
        },
        {
            link: '#',
            name: 'message.h_r',
            tooltip: 'Order',
            icon: 'fa-solid fa-user-gear',
            open: false,
            listOne: false,
            is_show:[
                'users.index',
                'roles.index',
                'permissions.index',
            ],
            items: [
                {
                    name: 'message.users',
                    link: 'users',
                    is_show: 'users.index'
                },
                {
                    name: 'message.cabinet_users',
                    link: 'cabinet_users',
                    is_show: 'users.index'
                },
                {
                    name: 'message.roles',
                    link: 'roles',
                    is_show: 'roles.index'
                },
                {
                    name: 'message.permissions',
                    link: 'permissions',
                    is_show: 'permissions.index'
                }
            ]
        },
        {
            link: '#',
            name: 'message.settings',
            tooltip: 'Saved',
            icon: 'fa-solid fa-gear',
            open: false,
            listOne: false,
            is_show:[
                'systemNotifications.index',
                'filials.index',
                'countries.index',
                'regions.index',
                'cities.index',
                'categories.index',
                'clientTypes.index',
                'dealGoals.index',
				'services.index',
            ],
            items: [
                {
                    name: 'message.systemNotifications',
                    link: 'systemNotifications',
                    is_show: 'systemNotifications.index'
                },
                {
                    name: 'message.registerRules',
                    link: 'registerRules',
                    is_show: 'registerRules.index'
                },
                {
                    name: 'message.filial',
                    link: 'filial',
                    is_show: 'filials.index'
                },
                {
                    name: 'message.directionTariffs',
                    link: 'directionTariffs',
                    is_show: 'directionTariffs.index'
                },
                {
                    name: 'message.countries',
                    link: 'country',
                    is_show: 'countries.index'
                },
                {
                    name: 'message.regions',
                    link: 'regions',
                    is_show: 'regions.index'
                },
                {
                    name: 'message.cities',
                    link: 'city',
                    is_show: 'cities.index'
                },
                {
                    name: 'message.categories',
                    link: 'category',
                    is_show: 'categories.index'
                },
                {
                    name: 'message.clientTypes',
                    link: 'clientTypes',
                    is_show: 'clientTypes.index'
                },
					 {
                    name: 'message.services',
                    link: 'services',
                    is_show: 'services.index'
                },
	
            ]
        },
        {
            link: '#',
            name: 'message.product_settings',
            tooltip: 'Setting',
            icon: 'fa-solid fa-bag-shopping',
            open: false,
            listOne: false,
            is_show:[
                'products.index',
                'measurements.index',
                'productSizes.index',
                'productModels.index',
                'products.replaceProducts'
            ],
            items: [
                {
                    name: 'message.products',
                    link: 'product',
                    is_show: 'products.index'
                },
                {
                    name: 'message.replace_products',
                    link: 'replaceProducts',
                    is_show: 'products.replaceProducts'
                },
                // {
                //     name: 'message.measurements',
                //     link: 'measurements',
                //     is_show: 'measurements.index'
                // },
            ]
        }
    ],
        fixed: false
    }),
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
        }),
        // aside is expanded
        isExpanded() {
            return this.$store.getters.MENU
        },
        // dark mode
        mode() {
            return !this.$store.getters.MODE
        },
        leftVersion () {
        return this.$store.getters.LEFTVERSION
        },
        findPath() {
            this.list.map(item => {
                if (item.items && item.items.length) {
                    return item.items.map(route => {
                        Object.assign(route, { active: route.link == this.$route.name });
                    })
                }
            })
            return this.list
        },
    },
    watch: {
        isExpanded() {
            if (!this.isExpanded) {
                this.activeNames = []
            }
        },
    },
    methods: {
        accordion() {
            if (!this.isExpanded) {
                this.activeNames = []
            }
        },
        openToggle() {
            this.$store.commit('toggleMenu')
        },
        action(item) {
            if (!item.uniqueKey) {
                this.drawer = true
                this.subMenuTitle = item.name
                this.subMenu = item.items
            } else {
                this.drawer = false
            }
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix
            } else {
                return text
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    position: relative;
    z-index: 1001;
    width: 70px;

    // menu-wrapper
    &-wrapper {
        width: inherit;
        height: 100%;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // border-right: 1px solid #E6E9F2;
        padding: 58.5px 0px 0;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        direction: rtl;
        z-index: 999;
        background-color: #fff;

        // menu-wrapper-list
        &-list {
            display: flex;
            flex-direction: column;
            direction: ltr;
            gap: 6px;
            padding: 0;
            margin: 0;
            list-style: none;

            // menu-wrapper-list__item
            &__item {
                font-size: 11px;
                font-weight: 500;
                color: #92929f;
                background-color: transparent;
                margin: 0;
                padding: 10px;
                border: none;
                outline: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                transition: all .2s ease-in-out;
                cursor: pointer;

                &.router-link-exact-active {
                    color: #fff;
                    background: #0d67c9;
                }

                &.active {
                    color: #fff;
                    background: #0d67c9;
                }

                &:hover {
                    color: #0d67c9;
                    background: #f8f6f2;
                }

                i {
                    font-size: 20px;
                }
            }
        }

        // scrool bar style
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #ebeef5;
            border: 2px solid #fff0;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #8a909c;
            border-color: #8a909c;
        }

        // dark mode
        &.dark {
            background-color: #080c24;
            border: none;

           .menu-wrapper-list__item i {
                margin-bottom: 5px;
                &:hover {
                    color: #0d67c9;
                    // background-color: #323248;
                }

                &.router-link-exact-active {
                    color: #fff;
                    background: #0d67c9;
                }
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #1f2937;
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: #374151;
                border-color: #374151;
            }
        }
    }
}

//  SUBMENU
.menu-drawer {
    position: fixed;
    padding-top: 57px;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 998;
    transition: all .35s ease-in-out;

    &.active {
        left: 70px;
    }

    // menu-drawer-submenu
    &-submenu {
        width: 18%;
        height: 100%;
        padding: 1rem;
        margin: 0;
        list-style: none;
        position: relative;
        background-color: #fff;
        z-index: 998;
        overflow-x: hidden;
        overflow-y: auto;
        // box-shadow: 1px 0px 4px rgb(0 0 0 / 20%);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #ebeef5;
            border: 2px solid #fff0;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #d1d7e4;
            border-color: #d1d5db;
        }

        // menu-drawer-submenu__header
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 4px 0;

            i {
                font-size: 25px;
                cursor: pointer;
            }
        }

        // menu-drawer-submenu__item
        &__item {
            color: #55555A;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 10px 6px;
            border-radius: 4px;
            text-decoration: none;
            transition: all .2s ease-in-out;
            cursor: pointer;

            &.router-link-exact-active {
                color: #0d67c9;
                background: #f8f6f2;

                i {
                    color: #0d67c9;
                }
            }

            i {
                color: #91A2B6;
            }

            &:hover {
                color: #0d67c9;
                background: #f8f6f2;

                i {
                    color: #0d67c9;
                }
            }
        }

        /* ----------------------- DARK MODE -----------------------*/
        &.dark {
            background: #080c24a6;
            backdrop-filter: blur(10px);

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #1f2937;
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: #374151;
                border-color: #374151;
            }

            .menu-drawer-submenu {

                &__title {
                    color: #fff;
                }

                &__header i {
                    color: #fff !important;
                }

                // menu-drawer-submenu__item
                &__item {
                    color: #fff;
                    opacity: .5;

                    i {
                        opacity: .5;
                        color: #fff;
                    }

                    &.router-link-exact-active,
                    &:hover {
                        opacity: 1;
                        // background-color: #2a2a3f;
                        background-color: #0e2c4685;

                        i {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba($color: #000000, $alpha: .5);
        background-color: transparent;
        z-index: 997;
        transition: all .35s ease-in-out;
    }
}
</style>
