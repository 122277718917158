export const ru_messages = {
    yes: 'Да',
    no: 'Нет',
    n: "№",
    total_amount: "Общая сумма",
    deals: "Заявки",
    deal: "Заявка",
    create: "Создать",
    create_board: "Создать доску",
    update_board: "Изменить доску",
    update: "Изменить",
    delete: "Удалить",
    board_name: "Имя доски",
    The_field_must_not_be_empty: "Поля не должно быть пустым",
    header_color: "Цвет заголовки",
    New_header_color: "Новый цвет заголовки",
    See_board_name: "Введите имя доски",
    New_board_name: "Новое имя доски",
    company: "Компания",
    companies: "Компании",
    client: "Клиент",
    user: "Пользователь",
    name: "Наименование",
    first_name: "Имя",
    surname: "Фамилия",
    patronymic: "Отчество",
    phone: "Телефон",
    gender: "Пол",
    email: "Эл. адрес",
    role_id: "Роль",
    nameFull: "Имя",
    new_source: "Новый Источник",
    save: "Сохранить",
    close: "Закрыть",
    search: "Поиск",
    source: "Источник",
    sources: "Источники",
    text: "Сообщение",
    text_t: "Текст",
    type_id: "Тип",
    settings: "Настройки",
    color: "Цвет",
    colors: "Цвета",
    date: "Дата",
    status: "Статус",
    count_product: "Количество",
    total_price: "Сумма",
    download: "Скачать",
    currency: "Валюта",
    currencies: "Валюты",
    begin_date: "Дата начала",
    end_date: "Дата окончания",
    without_finish_date: "Без даты окончания",
    deal_type: "Тип сделки",
    dealStage: "Стадия",
    comment: "Комментарий",
    dealType: "Тип сделки",
    created_at: "Дата создания",
    updated_at: "Дата изменения",
    symbol: "Символ",
    edit_m: "Изменить  {m}",
    new_m: "Новый  {m}",
    mfo: "МФО",
    okonx: "ОКОНХ",
    oked: "ОКЭД ",
    rkp_nds: "РКП НДС",
    work_phone: "Рабочий телефон",
    work_place: " Место работы",
    legal_address:"Юридический адрес",
    landmark: "Ориентир",
    residence_place:"Адрес прописки",
    costType: 'Тип расхода',
    costTypes: 'Типы расходов',
    dealTypes: 'Тип сделки',
    measurements: 'Измерения',
    dealStages: "Стадия",
    // mi
    patronymic:'Отчество',
    surname: 'Фамилия',
    pin_code: 'Пин код',
    is_employee:'Сотрудник',
    //end 
    nameGiven: "Название",
    nameFull: "Имя",
    requirements: "Требования",
    requirements_y: "Требовании",
    reason: "Причина",
    reason_y: "Причины",
    priority: " Приоритет",
    priority_y: " Приоритеты",
    position:"Должность",
    position_p:"Должности",
    graphicType: "Тип графика",
    graphicType_p: "Типы графика",
    currency: "Валюта",
    currency_y: "Валюту", 
    filter: "Фильтр",
    total_working_day: "Рабочая неделя",
    graphic: "График работы",
    language_graphic_type: "Пожалуйста, введите название",
    employmentType : "Виды занятости",
    province: "Город",
    region:"Город",
    region_: "Регион",
    vacancy:"Вакансии",
    last_name:"Фамилия",
    middle_name:"Отчество",
    date_of_birth:"Дата рождения",
    phone:"Телефон",
    email:"Эл. адрес",
    status:"Статус",
    role:"Роль",
    phone_number_y:"Номер тел",
    summary: "Резюме",
    UploadFile: "Загрузить файл",
    UploadFileText: " файлы jpg / png размером менее 500 КБ ",
    serial_number: "Серийный номер",
    ip_address: "IP адрес",
    device:"Устройства",
    device_y:"Устройство",
    income: "Уровень дохода",
    work_type: "Статус Сотрудника",
    district_id:"Район ",
    district:"Район",
    nation:"Нация",
    nation_y:"Нация",
    code: "Код",
    okurs: "Обратный курс",
    profile: "Профиль",
    dateAdoption: "Дата принятия",
    staffTest: "Испытательный ",
    operating: "Действующий",
    dismissed: "Уволенённый",
    save: "Сохранить",
    close: "Закрыть",
    candidates: "Кандидаты",
    find: "Поиск",
    daily: "Ежедневно",
    from: "От",
    to: "До",
    before: "До",
    dinner: "Обед",
    users: "Пользователи",
    user: "Пользователь",
    user_id: "Пользователь",
    password: "Пароль",
    new_password: "Новый Пароль",
    current_password: "Текущий Пароль",
    confirm_password: "Повторить пароль",
    finance: "Финансы",
    money_amount: "Сумма",
    access_rights: "Права доступа",
    active: "Активный", 
    is_active: "Активный",
    inactive: "Не активный",
    working_hours_report: "Отчет по рабочему времени",
    age: "Возраст",
    nv_monitoring: "Мониторинг НВ",
    o_salary: "Оплата труда",
    new_incoming_payment: "Новый входящий платеж",
    incoming: "Входящий",
    outgoing: "Исходящий",
    employees: "Сотрудники",
    paymentTypes: "Тип оплаты",
    amount: "Сумма",
    check: "Счет",
    check_a: "Счета",
    salary_type: "Тип зарплаты",
    salary: "Зарплата",
    bank: "Банк",
    checking_account: "Расчетный счет",
    expiration_date: "Дата оканчания",
    comment: "Комментарий",
    overtime: "Сверхурочный",
    total: "Итого:",
    being_late: "Опаздания",
    other_penalties: "Другие штрафы",
    penalties: "Штрафы",
    bonuses: "Бонусы",
    on_hands: "На руки",
    passport_issued_by: "Кем выдан паспорт",
    passport_valid_until: "Срок действия паспорта",
    inn: "ИНН",
    job: "Работа",
    dashboard: "Дашборд",
    hiring_date: "Дата найма",
    manager: "Менеджер",
    allow_th_employee_to_access_the_system: "Разрешить сотруднику доступ к системе.",
    add_candidates: "Добавить кандидатов",
    description: "Описание",
    photo: "Фото",
    min: "мини",
    max: "макс",
    recruiting: "Рекрутинг",
    desktop: "Рабочий стол",
    indicators: "Показатели",
    journal: "Журнал",
    quit: "Выйти",
    branch_name: "Название отделения",
    mfo: "МФО",
    number: "Номер",
    account: "Счет",
    card_number:"Номер карты",
    penalty_name: "Название штрафа",
    bonus_name: "Название бонуса",
    payment_date:"Дата платежа",
    adaptation: "Адаптация",
    agreement_employees: "Договора(СОТРУДНИКИ)",
    login: "Авторизоваться",
    permission: "Разрешения",
    synchronization: "Синхронизация",
    tests: "Тесты",
    wage: "Заработная плата",
    dafault: "По умолчанию",
    real_amount:"Реальная сумма",
    bonus_amount: "Бонусная сумма",
    payment_for:"Платеж за",
    code:"Код",
    measurement_id:"Измерение",
    measurement:"Измерение",
    buy_price:"Цена покупки",
    buy_price_currency_id:"Валюта покупки",
    selling_price:"Цена продажи",
    selling_price_currency_id:"Валюта продажи",
    deal_id:"Заявка",
    product_id:"Продукт",
    price:"Цена",
    currency_id:"Валюта",
    quantity:"Количество",
    discount:"Скидка",
    nds:"НДС",
    slug:"Слизняк",
    file:"Файл",
    full_name:"Полное имя",
    company_id:"Компания клиента",
    date_of_birth:"Дата рождения",
    passport_serial_number:"Серийный номер паспорта",
    passport_validity:"Срок действия паспорта",
    actual_address:"Адрес",
    inn:"ИНН",
    gender_id:"Пол",
    type:"Тип",
    responsible:"Ответственный",
    country:"Страна",
    countries:"Страны",
    city:"Город",
    cities:"Города",
    city_id:"Город",
    country_id:"Страна",
    category:"Категория",
    category_id:"Категория",
    categories:"Категории",
    parent_id:"Родитель",
    product:"Продукт",
    products:"Продукты",
    weekly:'Неделя',
    monthly: 'Месяц',
    yearly: 'Год',
    per: 'за',
    is_super_admin: 'Супер админ',
    new:"Новый",
    cancel:"Отмена",
    files:"Файлы",
    profit:"Прибыль",
    profit_in_percents:"Прибыль в процентах",
    profit_in_money:"Прибыль в деньгах",
    archivedDeals:"Заархивированные Заявки",
    deletedDeals:"Удаленные Заявки",
    roles: "Роли",
    permissions: "Права доступа",
    show: "Показать",
    unzip: "Разархивировать",
    restore: "Восстановить",
    rate: "Курс",
    new_deal: "Новая заявка",
    new_task: "Новая задача",
    new_contact: "Новый контакт",
    no_data_yet: "Пока нет данных",
    reasons: "Причины",
    deal_reasons: "Причины сделки",
    reason: "Причина",
    clientType: "Тип клиента",
    clientTypes: "Типы клиентов",
    client_with_search: "Введите тип клиента для поиска",
    sale_by_period: "Продажа по периоду",
    finish_date: "Фактическая дата окончания",
    check_list_id: "Список",
    is_done: "Сделано",
    task_board_id: "Доска",
    board: "Доска",
    task_id: "Задача",
    task: "Задача",
    archivedTasks: "Заархивированные задачи",
    deletedTasks: "Удаленные задачи",
    dealGoal: "Цель сделки",
    dealGoals: "Цели сделки",
    year: "Год",
    month: "Месяц",
    count: "Количество",
    by_count: "ПО количеству",
    by_money_amount: "ПО бюджету",
    by_profit: "ПО прибыли",
    color_id: "Цвет",
    dealCondition: "Условие оплаты",
    dealConditions: "Условия оплат",
    shipmentTerm: "Условие поставки",
    shipmentTerms: "Условия поставок",
    taskReason: "Причина задачи",
    taskReasons: "Причины задач",
    payment_in_percents:"Оплата в процентах",
    payment_in_money:"Оплата в деньгах",
    telegram_chat_id:"Идентификатор чата Telegram",
    package:"Упаковка",
    packages:"Упаковки",
    productSize:"Размер продукта",
    productSizes:"Размеры продукта",
    productModel:"Модель",
    productModels:"Модели",
    is_default:"По умолчанию",
    emails:"Электронные письма",
    email:"Электронное письмо",
    Unseen:"Непрочитанный",
    Seen:"Прочитанный",
    Answered:"Отвечено",
    kpTemplate:"КП шаблон",
    kpTemplates:"КП шаблоны",
    header:"Верхний текст",
    footer:"Нижний текст",
    columns:"Столбцы",
    sip_number:"Внутренний номер",
    sip_password:"Пароль внутреннего номера",
    sipAccount:"Внутренний номер",
    sipAccounts:"Внутренние номера",
    name_title: "Обращение",
    phone_number: "Телефонный номер",
    website: "Веб-сайт",
    contact_type: "Тип контакта",
    phoneContact: "Контакт",
    phoneContacts: "Контакты",
    reception_address: "Адрес приемной",
    control_user: "Пользовательский контроль",
    user_permission_type: "Тип пользовательского контроля",
    calendarNotifications: "Напоминания",
    calendarNotification: "Напоминания",
    title: "Титул",
    server_url: "Адрес сервера",
    port: "Порт",
    extension_type: "Тип внутреннего номера",
    additional: "Допольнительно",
    spectators: "Наблюдатели",
    caller: "Исходящий номер",
    called_to: "Входящий номер",
    billsec: "Время",
    callStatus: "Статус",
    calldate: "Дата и время",
    recordingfile: "Запись",
    user_telegram_status: 'Статус телеграма',
    department_list: 'Отделы',
    department: 'Отдел',
    select_status: 'Выберите статус',
    available: 'Доступно',
    not_disturb: 'Не беспокоить',
    busy: 'Занят',
    absent: 'Нет на месте',
    invisible: 'Невидимый',
    outgoing: 'Исходяший',
    incoming: 'Входящий',
    missed: 'Пропущенный',
    deal_by_phone_number: 'Заявка по номеру телефона',
    no_paymnets: 'Нет никаких платежей',
    profit_m: 'Маржа',
    assign_role: 'Назначить роль',
    view: 'Простмотр',
    link: 'Ссылка',
    display_and_wallpaper: 'Дисплей и обои',
    dynamic_wallpaper: 'Динамические обои',
    please_select: 'Пожалуйста выберите',
    message: 'Сообщение',
    more: 'Более',
    accepted: 'Принято',
    not_accepted: 'Непринято',
    working: 'Рабочий',
    accounts: 'Счета',
    history: 'История',
    applications: 'Приложения',
    who_gave_the_task: 'Кто дал задание',
    task_in_project: 'Задача в проекте (группе)',
    do_you_really_want_to_do_this: 'Вы действительно хотите это сделать?',
    deal_deleted: 'Заявка удалена',
    warning: 'Предупреждение',
    operation_canceled: 'Операция отменена',
    deal_restored: 'Заявка восстановлена',
    participants: 'Участники',
    no_deadline: 'Нет срока',
    labels: 'Метки',
    deadline: 'Срок',
    restore_to_board: 'Востановить на доску',
    delete_task: 'Удалить задачу',
    unable_to_delete_last_form: 'Невозможно удалить последнюю форму',
    'Enter your email address': 'Введите адрес электронной почты',
    'Please enter your email correctly!': 'Введите почту правильно !',
    'You cannot add more than 5 email addresses!': 'Вы не можете добавить более 5 адресов электронной почты !',
    'Problem checking connection, please try again': 'Проблема при проверке подключения, попробуйте еще раз',
    'To test the connection, first enter your IMAP and SMTP details.': 'Для проверки соединения сначала введите данные IMAP и SMTP.',
    'Are you sure you want to activate the task ?': 'Вы действительно хотите активировать задачу ?',
    'Are you sure you want to activate the deal ?': 'Вы действительно хотите активировать Заявку ?',
    completed_tasks: 'Выполненные задачи',
    confirm: 'Подтвердить',
    day: 'День',
    today: 'Сегодня',
    call_history: 'История звонков',
    no_audio_file: 'Нет аудиофайла',
    profit_sum: 'Сумма маржи',
    corr_account: 'Корр. счет',
    delete_field: 'Удалить Поле',
    add_field: 'Добавить Поле',
    contact_person: 'Контактное лицо',
    no_email: 'Нет электронной почты',
    all_deals: 'Всего Заявок',
    succesfull: 'Успешные',
    succesfully: 'Успешно',
    fact: 'Факт',
    difference: 'Разница',
    active_d: 'Активные',
    all_tasks: 'Всего задач',
    tasks: 'Задачи',
    client_type: 'Тип клиентов',
    no_task: 'Нет задач',
    additional_client_data: 'Дополнительные данные клиента',
    individual_info: 'Информация физическое лицо',
    January: 'Январь',
    February: 'Февраль',
    March: 'Март',
    April: 'Апрель',
    May: 'Май',
    June: 'Июнь',
    July: 'Июль',
    August: 'Август',
    September: 'Сентябрь',
    October: 'Октябрь',
    November: 'Ноябрь',
    December: 'Декабрь',
    payment_start_date: 'Дата начала срок оплаты',
    payment_end_date: 'Дата завершения срок оплаты',
    delete_payment: 'Удалить платеж',
    add_payment: 'Добавить платеж',
    client_with_contact: 'Компании клиентов, связанные с контактом',
    loading: 'Загрузить',
    add_participant: 'Добавить наблюдателя',
    add: 'Добавить',
    'Payment cannot be more than 100%, please correct before saving': 'Оплата не может быть больше 100%, пожалуйста, исправьте перед сохранением',
    'One of the payments is 0, please enter the percentage for the existing payment first': 'Один из платежей равен 0, пожалуйста, сначала введите процент для существующего платежа',
    file_limit_warning: 'Можно выбрать только {number} файлов!',
    file_size_limit_warning: 'Загрузите файл размером менее {number} МБ',
    file_already_exists: 'Этот файл уже существует',
    update_deal: 'Изменить сделку',
    'Payment cannot be more than 100%': 'Оплата не может быть больше 100%',
    'Do you want to send a message to the client': 'Вы хотите отправить сообщение клиенту',
    send: 'Отправить',
    something_wrong_with_connection_of_email: 'Что-то пошло не так при подключении к электронной почте, попробуйте еще раз, чтобы перезагрузить, нажмите кнопку «Эл-письмо» еще раз.',
    error_m: 'Ошибка',
    'There is no email in the transaction to search for messages!': 'В сделке нет электронной почты для поиска сообщений !',
    'Enter one from text or file': 'Введите один из текста или файла',
    'Please select an email address if you would like to send an email': 'Пожалуйста, выберите адрес электронной почты, если вы хотите отправить письмо',
    all_emails: 'Все электронные почты',
    'Date difference should not be more than 4 days to search all email addresses !': 'Разница в датах не должна быть больше 4 дней для поиска по всем адресам электронной почты !',
    'The date difference should not be more than 10 days for the search!': 'Разница в датах не должна быть больше 10 дней для поиска !',
    no_data_to_search: 'Нет дат для поиска !',
    no_email_to_search_messages: 'В Заявке нет электронной почты для поиска сообщений !',
    no_selected_deal_to_save: 'Нет выбранных Заявки для сохранения !',
    product_list_is_empty: 'Список продукта пуст',
    'Are you sure you want to uninstall the product?': 'Вы действительно хотите удалить продукт?',
    deleting_product_canceled: 'Удаление продукта отменено',
    overall: 'Общие',
    archivate_deal: 'Архивировать Заявку',
    delete_deal: 'Удалить Заявку',
    offer: 'Предложения',
    add_task: 'Добавить задачу',
    kp: 'КП',
    update_payment: 'Изменить оплаты',
    do_you_want_to_delete_participant: 'Вы действительно хотите удалить наблюдателя',
    do_you_want_to_archivate_task: 'Вы действительно хотите архивировать Заявку ?',
    archivating_completed: 'Архивирование завершено',
    archivating_canceled: 'Архивирование отменено',
    company_y: 'Компания',
    do_you_want_to_delete_board: 'Вы действительно хотите удалить доску ?',
    message_m: 'Сообщения',
    activating_deal_canceled: 'Активирование Заявки отменено',
    activating_task_canceled: 'Активирование задачи отменено',
    delete_completely: 'Удалить окончательно',
    please_enter_input: 'Пожалуйста, введите {input}',
    length_must_be: 'Длина должна быть {number}',
    length_must_be_min: 'Длина должна быть минимум {number} характеров',
    length_must_be_max: 'Длина должна быть максимум {number} характеров ',
    'Do you really want to import products from another project': 'Вы действительно хотите импорт продуктов из другого  проекта',
    auto_lock_when_inactive: 'Автоблокировка при неактивности',
    turned_off: 'Выключено',
    numner_of_secunds: '{number} секунд',
    numner_of_minutes: '{number} минуты',
    numner_of_hours: '{number} часов',
    auto_lock: 'Автоблокировка',
    passcode: 'Код-пароль',
    enter_your_passcode: 'Введите текущий код-пароль',
    enter_new_password: 'Введите новый код-пароль',
    repeat_passcode: 'Повторите код-пароль',
    enter_your_password_again: 'Пожалуйста, введите пароль еще раз',
    password_does_not_match: 'Пароль не совпадает!',
    number_found_on_server: 'Внутренний номер найден на сервере',
    check_if_number_exists_in_server: 'Проверить, существует ли номер на сервере',
    waiting_to_complete: 'Ждет выполнения',
    delete_participant_from_list: 'Удалить из списка участников',
    completed: 'Выполнено',
    check_task: 'Задание на проверку',
    in_process: 'В процессе',
    task_checked: 'Задача проверена',
    ready_to_check: 'Готов к проверке',
    add_description: 'Добавить Описание',
    add_item: 'Добавить элемент',
    downloaded_files: 'Загруженные файлы',
    recommended_settings: ' Настройки рекомендаций',
    add_to_card: 'Добавить на карточку',
    check_list: 'Чек-лист',
    attechment: 'Вложение',
    add_to_completed_tasks: 'Добавить в выполненные задачи',
    operation_completed: 'Операция завершено',
    adding_a_task_list: 'Добавление список задач',
    dates: 'Даты',
    create_new_label: 'Создать новую метку',
    update_label: 'Изменить метку',
    without_deals: 'Без Сделок',
    please_select_input: 'Пожалуйста, выберите {input}',
    user_email: 'Электронная почта пользователя',
    email_password: 'Пароль от электронной почты',
    smtp_server: 'SMTP сервер',
    smtp_port: 'SMTP порт',
    imap_server: 'IMAP сервер',
    imap_port: 'IMAP порт',
    pop3_server: 'POP3 сервер',
    pop3_port: 'POP3 порт',
    add_email: 'Добавить почта',
    deal_c: 'Сделок',
    overdue_tasks: 'Просроченные задачи',
    completed_tasks_for_today: 'Выполненные задачи за сегодня ',
    task_to_complete: 'Задачи к выполнению',
    deal_source: 'Источники сделок',
    deals_by_managers: 'Заявки по менеджерам',
    goals: 'Цели',
    deals_by_country: 'Заявки по странам',
    latest_files: 'Последние файлы',
    no_files: 'Нет никаких файлов',
    deal_delete_reason: 'Причины удаления Заявки',
    payments_by_period: 'Выплаты по периодам',
    by_days: 'По дням',
    by_months: 'По месяцам',
    by_years: 'По годам',
    selling_product_statistic: 'Статистика по продажам грузов',
    conter_agent: 'Контрагент',
    top_deals: 'Топ 5 успешных сделок ',
    overall: 'Общий',
    total_amount_t: 'Итоговая сумма',
    sale: 'Продажа',
    profit: 'Прибыль',
    no_deal: 'Нет сделок',
    locations: 'Расположения',
    'A file with the same name already exists, choose another one or change the name': 'Файл с таким именем уже существует, выберите другой или измените имя',
    write_text: 'Напишите текст',
    'There is a problem on the server side': 'Есть проблема на стороне сервера',
    information: 'Информация',
    no_phone: 'Нет номера',
    enter_phone_number_to_call: 'Введите номер для звонка !',
    'Are you sure to close this window': 'Вы уверены, что закрываете это окно?',
    phone_registered_message: 'Телефон {phone} зарегистрирован !',
    register: 'Зарегистрировать',
    phone_not_registered_message: 'Телефон {phone} не зарегистрирован обновите страницу еще раз',
    wiev_all: 'Посмотреть все',
    cash_box: 'Касса',
    payments_for_expenses: 'Платежи за расходы',
    calls: 'Звонки',
    counterparties: 'Контрагенты',
    h_r: 'H/R',
    product_settings: 'Продукт Н',
    calendar: 'Календарь',
    russia: 'Russia',
    exit: 'Выйти',
    deletion_reason: 'Причина удаления',
    text_length_must_be: 'Длина должна быть от {min} до {max}',
   
    'ANSWERED': "Отвечено",
    'NO ANSWER': "Не отвечено",
    'BUSY': "Занят",
    'settings profile': 'Настройки профиля',
    'settingCompany': 'Настройки компании',
    'logout': 'Выйти',
    'available': 'Доступно',
    'invisable': 'Невидимый',
    'NotDisturb': 'Не беспокоить',
    'busy': 'Занят',
    'absent': 'Нет на месте',
    "Amount without discount and NDS": "Сумма без скидки и налогов",
    "Discount amount": "Сумма скидки",
    "NDS amount": "Сумма налога",
    "Total amount": "Общая сумма",



    // not translated to turkish

    prefix: 'Префикс',
    phoneNumberForSipAccountOutsideCalls: 'Телефоны для исходящих звонков',
    phoneNumberForSipAccountOutsideCall: 'Телефон для исходящих звонков',
    clients: "Клиенты",
    phone_number_is_not_valid: 'Пожалуйста, введите правильный номер. Номер телефона неверен',
    validation: 'Проверка',
    phones: 'Телефоны',
    add_participants: 'Добавить участников',
    call: 'Звонок',
    you_can_not_call_yourself: 'Вы не можешь позвонить себя !',
    caller_name: "Имя исход",
    target_name: "Имя вход",
    invalid_phone_alert: "Телефон недействителен, проверьте учетные данные и повторите попытку !",
    calling: "Вызов",
    home: "Главная",
    goal_in: "Цель в",
    task_map: "Карта задач",
    please_enter_phone_number: "Пожалуйста, введите телефон",
    phone_already_exits: "Телефон уже существует введите другой",
    unregister: "Отменить регистрацию",
    phone_unregistered: "Телефон {phone} незарегистрирован",
    registered: "Зарегистрирован",
    unregistered: "Незарегистрирован",
    phone_not_registered_please_register_phone_to_make_a_call: 'Телефон не зарегистрирован пожалуйста зарегистрируйте телефон чтобы позвонить',
    edit: "Изменить",
    delete: "Удалить",
    assign_role:"Назначить роль",
    orders: "Заказы",
    create: "Создать",
    accept: "Принять",
    products: "Груз",
    nomination: "Наименование",
    quantity_y: "Кол-во",
    dimension: "Габариты",
    price: "Цена",
    save: "Сохранить",
    close: "Закрыть",
    filial: 'Филиал',
    pick_filial: "Выбрать склад филиала",
    amount_delivery: "Количкство грузов",
    weight_delivery: "Вес груза",
    amount_palet: "Количество палетов или коробок",
    email_notif: "Уведомления отправлять на",
    send_country: "Страна отправки",
    address_warehouse: "Адрес склада",
    flight: "Рейс",
    flights: "Рейсы",
    process: "Процесс",
    delivered_warehouse_material: "Доставлено грузов на склад",
    delivered_material_client: "Доставлено грузов клиенту",
    orders_name: "Заказы",
    pay: "Оплата",
    all_weight: "Общий вес",
    add_flight: "Добавить рейс",
    pick_flight: "Выбрать рейс",
    pick_send: "Выбрать дату вылета",
    pick_arrive: "Выбрать дату прилета",
    pick_product: "Выбрать грузы",
    accepted__orders: "Принятые заказы",
    agreement: "Договор",
    deal_name: "Заявки",
    departure_date: "Дата вылета",
    arrival_date: "Дата прилета",
    numbers_of_orders: "Количество заказов",
    numbers_of_goods: "Количество грузов",
    information_flight: "Информация о рейсе",
    direction: "Направления",
    warehouse: "Склад",
    number_of_cargo: "Количество грузов",
    employee_information: "Информация о сотрудниках",
    create_by: "Создал",
    cargo: 'Груз',
    Snap: 'Привязать',
    "Payment Binding": "Привязка платежа",
    "Select the documents you want to pay": "Выберите документы, которые вы хотите оплатить",
    "Amount of payment": "Сумма платежа",
    "Not tied": "Не привязано",
    "Bind Payment": "Привязать платеж",
    "type_document": "Тип документа",
    "datetime": "Время",
    "contract": "Договор",
    "paid": "Оплачено",
    "Paid from this payment": "",
    "Tied": "Привязано",
    from_filial: "Филиал отправки",
    to_filial: "Филиал прибытия",

    notification_type: "Тип уведомления",
    shipping_service: "Службы доставки",
    shipping_address: "Адрес доставки",
    delivery: "Доставка",
    deliveries: "Доставки",
    weight: "Масса",
    width: "Ширина",
    length: "Длина",
    height: "Высота",
    transport: "Транспорт",
    transports: "Транспорты",
    transport_number: "Номер транспорта",
    driver: "Водитель",
    load_capacity: "Грузоподъемность",
    courier: "Курьер",
    couriers: "Курьеры",
    container: "Паллет",
    containers: "Паллеты",
    barcode: "Штрих-код",
    containerType: "Тип упаковки",
    containerTypes: "Типы упаковок",
    location: "Местоположение",
    notification: "Напоминания",
    weight_in_kg: "Масса (кг)",
    kg: "кг",
    for: "за",
    from_where: "Откуда",
    to_where: "Куда",
    transportation_details: "Данные о перевозке",
    sender_warehouse: "Склад отправителя",
    reciever_warehouse: "Склад получателя",
    found_nothing: "Ничего не найдено",
    additional_params: "Дополнительные параметры",
    load_capacity_in_kg: "Грузоподъемность (кг)",
    incoming_quantity: "Вход к-во",
    incoming_products: "Входящие продукты",
    remainder: 'Остаток',
    debt: 'Долг',
    shipping_cost: 'Cтоимость доставки',
    capitalize: 'Оприходовать',
    capitalize_cargo: 'Оприходовать груз',
    add_cargo: 'Добавить груз',
    print: 'Печать',
    current_quantity: 'Текущ к-во',
    total_quantity: 'Общая к-во',
    "there is no incoming products check again": 'Нет никаких входящих продуктов, пожалуйста, проверьте еще раз',
    directionTariffs: 'Тарифы направления',
    directionTariff: 'Тариф направления',
    price_list: 'Прайс-лист',
    select_all: 'Выбрать все',
    airway_bill_number: 'Авианакладная',
    container_info: 'Информация о паллете',
    recieve_country: "Страна прибытия",
    airwaybill_input: "Введите авианакладную",
    send_from_warehouse: "Отправить со склада",
    send_containers_to_aerport: "Отправить паллеты в аэропорт",
    scan: 'Сканировать',
    inventarization: 'Инвентаризация',
    incoming_containers : 'Паллеты склад получателя',  
    filials: 'Филиали',
    filial_containers: 'Паллеты филиала',
    new_containers: 'Новые паллеты',
    in_airport: 'В аэропорту',
    on_flight: 'Перелет',
    in_sending_filial: 'Филиал отправки',
    in_recieving_filial: 'Филиал прибытия',
    container_inventorization: 'Принять паллеты',
    'select container to store': 'Выберите Паллет для хранения',
    client_products: 'Продукты клиента',
    inventorization_products: 'Инвентаризация продуктов',
    paying_money: "Текущий платеж",
    client_balance: "Баланс клиента",
    remainder_for_client_balance: "Остаток для клиентского баланса",
    enter_money_correctly: "Введите сумму правильно",
    payment_from_client_balance: "Оплата с баланса клиента",
    no_payments_to_deals: "Нет выплат по сделкам",
    payments_for_costs: 'Платежи за расходы',
    outgoing_cost: 'Исходящий платеж',
    outgoing_costs: 'Исходящие платежи',
    outgoing_money: 'Исходящая сумма',
    measurement_short: "Изм",
    calculate_money: "Рассчитать сумму по",
    cash_register: 'Касса',
    payments: 'Платежи',
    payment: 'Платеж',
    bankAccounts: 'Банковские счета',
    bankAccount: 'Банковский счет',
    paymentType: "Тип оплаты",
    unpaidClientDeals: "Неоплаченные сделки клиента",
    pay_for_deals: "Платить за сделки",
    paid_money: "Оплачено",
    remainder: "Остаток",
    insufficient_money: "Недостаточно денег, пожалуйста, проверьте еще раз !",
    
    batches: "Партии",
    send_products_to_client: "Отправить груз клиенту",
    product_inventorization: "Инвентаризация грузов",
    packing: 'Упаковывается',
    products_locations: 'Расположение грузов',
    unpacking: 'Распаковывается',
    insufficient_goods: 'Недостаточно груза на складе',
    select: 'Выбрать',
    container_already_exists: 'Паллет уже существует',
    product_not_found: 'Продукт не найден',
    incorrect_money_for_deal: 'Неверная сумма для оплаты сделки',
    goods_in_circulation: 'Груз в обороте',
    return: 'Возврат',
    tracking: 'Отслеживание',
    client_info: 'Информация о клиенте',
    deal_info: 'Информация о сделке',
    total_money_of_incoming_product: 'Итого входящего продукта',
    collect_packaging: 'Собирать упаковку',
    check_prod_quantity: 'Паллет не может быть пустым. Пожалуйста, добавьте продукт или проверьте количество продуктов',
    measuring_type: 'Тип измерения',   
    weight_cant_be_0: 'Вес не может быть 0',   
    sender: 'Отправитель',   
    reciever: 'Получатель',   
    partner: 'Партнер',   
    product_list : 'Список грузов',
    check_prod_quantity_inventor: 'Пожалуйста, добавьте продукт или проверьте количество продуктов',
    code_for_client_custom_id: 'Код клиента',   
    custom_id: 'ID',   
    old: 'Старый',   
    tracking_code: 'Код отслеживания',
    passport_given_by: 'Кем выдан',   
    passport_given_date: 'Дата выдачи',   
    passport_series: 'Серия паспорта',   
    passport_number: "Номер паспорта", 
    region: 'Область',   
    street: 'Улица',   
    house: 'Дом',   
    flat: 'Квартира',   
    zip_code: 'Zip код (индекс)', 
    only_client: 'Только клиент',  
    sender_reciever: 'Отправитель получатель',  
    product_already_exists: 'Продукт уже существует!',  
    weight_in_pounds: 'Масса (lbs)',   
    pack_product: 'Упаковать продукты',   
    add_new_address: 'Добавить новый адрес',   
    select_address: 'Пожалуйста, выберите адрес',   
    address: 'Адрес',   
    reciever_address: 'Адрес получателя',

    barcode_with_address: 'Этикетка с получателем',   
    barcode_without_address: 'Этикетка без получателя',   
    average_days: 'Средний день',   
    address_could_be_used_on_antoher_deals: 'Адрес может быть использован для других заявок. Убедитесь, прежде чем вносить изменения!',   
    inventarization_all: 'Инвентаризация всех грузов',
    invalid_barcode: 'Введен неверный штрих-код !',   
    ru: 'Русский',
    en: 'Английский',
    tr: 'Турецкий ',
    new_package: 'Новая упаковка',
    delete_package: 'Удалить упаковку',
    all_incoming_products_will_be_deleted: 'Все сопутствующие груз к этой партии будут удалены!',
    update_package: 'Изменить упаковку',
    products_will_be_returned_back_to_sender_filial: 'Продукты будут возвращены обратно на склад отправителя!',
    product_will_be_removed_from_sender_warehouse: 'Груз также будет удален со склада отправителя!',
    waiting_product: 'Склад ожидания',
    party: 'Партия',
    parties: 'Партии',
    product_price: 'Цена продукта',
    containersCount: 'Количество паллетов',
    plugin: 'Плагин',
    make_a_payment: 'Провести оплату',
    add_package: 'Добавить упаковку',
    shipping_to_address: 'Доставка до двери',
    lbs: 'lbs',
    sm: 'см',
    inch: 'inch',
    add_product: 'Добавить товар',
    product_name_cant_be_empty: 'Название продукта не может быть пустым. Пожалуйста, введите название продукта', 
    user_created_with_id: '{user} создал новый {item}. № {id}, {item_name}. Включен в заявки № {deal_ids}',
    notifications: 'Уведомления',
    roll_up: 'Свернуть',
    user_confirmed_notification: '{user} установил цену {item}: {item_name}. Обновлена цена товара в Заявках № {deal_ids}',
    systemNotification: "Системное уведомление",
    systemNotifications: "Системные уведомления",
    expire_date: 'Срок давности',
    announcement: 'Объявление',
    product_validation_error: 'Ошибка проверки количество продукта',
    item: 'Предмет',
    product_price_list: 'Прайс-лист на товары',
    partner_price_list: 'Прайс-лист для партнеров',
    price_without_delivery: 'Цена без доставки',
    price_with_delivery: 'Цена с доставкой',
    parcel: 'Посылка',
	 'Does not let delete': 'Не дает удалить',
    max_weight_limit_message: 'Ограничение максимального веса {weight} {measure}',
    size_summ: 'Cумма габаритов',
    sender_address: 'Адрес отправителя',
    parcels: 'Посылки',
    parcel_weight_limit : 'Вес посылки превышает разрешенного веса. Пожалуйста создайте другой тип заказа.',   
    cargo_type: 'Тип груза',
    commertial_cargo: 'Коммерческий груз',
    no_selected_containers: 'Нет выбранных контейнеров',
    enter_product_name: 'Название продукта не может быть пустым',
    marketplace: 'Marketplace',
    balance: 'Баланс',
    max_size_limit_message: 'Сумма габаритов не должна превышать {size} {measure}',
    parcel_size_limit: 'Сумма габаритов превышает разрешенных {size} {measure}. Пожалуйста создайте другой тип заказа.',
    package_empty_message: 'Упаковки № {numbers} пустые. Укажите груз или удалите упаковки.',
    region: 'Область (Регион)',
    regions: 'Область (Регион)',
    services: 'Услуги',
    service: 'Услуга',
    percent: 'Процент',
    for_site: 'Актив для сайта',
    for_systeam: 'Актив для системы',
    registerRule: 'Правила регистрации',
    registerRules: 'Правила регистрации',
    rules: 'Правила',
    services: 'Услуги',
    service: 'Услуга',
    percent: 'Процент',
    for_site: 'Актив для сайта',
    for_systeam: 'Актив для системы',
    actual_weight: 'Фактическая масса (кг)',
    deal_weight: 'Масса заявки (кг)',
    cabinet_users: 'Пользователи личного кабинета',
	tracking_code_new: 'Трекинг код доставки',
	tracking_code_deal: 'Трекинг код',
    insufficient_goods_ordered: 'Количество товара превышает заказанное количество!',
    product_already_exists: 'Продукт уже существует!',
    shipping_cargo: 'Доставка груза',
    price_delivery_to_door: 'Цена доставка до двери',
    price_delivery_to_door_per_kg: 'Цена доставки до двери за кг',
    price_delivery_to_door_per_weight: 'Цена доставка до двери {price} $ / кг',    
    currently_price_delivery_to_door_per_weight: 'На данный момент цена доставки до двери изменилась до {price}$ за 1кг.',    
    price_delivery_to_door_not_set: 'На данном направлении нет доставки до двери.',
    sender_warehouseman: 'Кладовщик склад отправителя',
    client_country_changed_id_of_the_client_will_be_changed: 'Страна клиента изменилась, это приведет к изменению идентификатора клиента, вы действительно хотите его изменить?',
	replace_products: 'Заменить продукты',
    replaceable_product_id: 'Идентификаторы заменяемые продуктов',
	replace_to_product_id: 'Заменить на идентификатор продукта',
    delete_replaced_products: 'Удалить замененные продукты',
    replacables_not_indicated: 'Заменяемые продукты не указаны',
    add_to_product_list : 'Добавить в список грузов',
    select_products_to_add_to_list : 'Выберите грузов для добавления в список!',
    add_to_product_list : 'Добавить в список грузов',
    dimension_sm: "Габариты (см)",
    package_includes_multi_direction_deals: 'Упаковка включает в себя заявки с несколькими адресами!',
    reciever_deals: 'Заявки получателя',
    set_address: "Указать адрес",
    invalid_product_weight: "Вес товара не может быть 0 пожалуйста введите вес товара, сделка №{deal}",
    sending_cargo: "Отправки груза",
    add_to_party: "Добавить в партию", 
    create_party: "Создать партию",
    arrange_delivery: "Оформить доставки",
    empty_party: "Партия пуста или все товары успешно отправлены",
    cannot_delete_active_party: 'Запрещено удалять активную Партию!',

    new_deals  : 'Новые заявки',
    active_deals: 'Активные заявки',
    sender_filial: 'Филиал отправителя',
    packing: 'Упаковывается',
    on_flight: 'Груз отправлен',
    reciever_filial: 'Доставлено в филиал',
    delivered_to_client: 'Доставлено клиенту',
    successfull: 'Успешные',
    waiting_payment: 'Ожидание оплаты',
    partner_delivery: 'Партнерская доставка',
    delivery_id: 'ID доставки',
    product_added_success: 'Продукт успешно добавлен!',
    passport_iin: 'ИИН',
    identification_type: 'Тип идентификации',
    passport: 'Паспорт',
    payer:  'Плательщик',
    login_to_cabinet:  'Вход в кабинет',   
    login_to_cabinet_of_user:  'Вход в кабинет {name}',   
    please_enter_barcode:  'Вход в кабинет',
    fill_the_required_fields:  'Пожалуйста, заполните обязательные поля перед сканированием',
    product_not_found_in_warehouse: 'Товар не найден на складе!',
    batch_delete: 'Выбираемое удаление',
    there_is_no_deals_selected: 'Пожалуйста, выберите сделку. Не выбрано ни одного заявки!',
    deleted_at: 'Дата удаление',
    client_not_found: 'Клиент не найден!',
    referral_users_count: 'К-во рефералов',
    referral_users: 'Рефералы',
}
