<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.containerType')" filterable :clearable="clearable"
      :size="size" class="w-100" :disabled="disabled">
      <el-option v-for="(containerType,index) in containerTypes" :key="'containerTypes-' + index" :label="containerType.name" :value="containerType.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      disabled:{
        default: false
      },
      select_default:{
        default: false
      },
      clearable:{
        default: true
      },
    },
    watch:{
      id:{
        handler: function() {
          this.selected = this.id
        },
        immediate: true
      },
      watchTypeAndDefault:{
        handler: function() {
          if(this.select_default && this.containerTypes.length > 0){
            this.dispatch(this.containerTypes[0].id);
          }
        },
        immediate: true
      },
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.containerTypes && this.containerTypes.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        containerTypes: 'containerTypes/inventory'
      }),
      watchTypeAndDefault(){
        return this.containerTypes.length + this.select_default;
      }
    },
    methods: {
      ...mapActions({
        updateInventory: 'containerTypes/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
