import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.nameFull')}), trigger: 'change' },
    ],
    phone_number: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}), trigger: 'change' },
    ],
    
};
