import {i18n} from '@/utils/i18n';

export const rules = {
    from_filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.from_filial')}), trigger: 'change' },
    ],
    to_filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.to_filial')}), trigger: 'change' },
    ],
    container_type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.containerType')}), trigger: 'change' },
    ],
    // deal_id: [
    //     { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.deal')}), trigger: 'change' },
    // ],
};
