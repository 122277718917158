export const model = {
    id : null,  
    barcode: '', 
    in_filial: true, 
    from_filial_id: null, 
    to_filial_id: null, 
    container_type_id: null, 
    to_filial_id: null, 
    airway_bill_number: '',
    weight: '', 
    products: [],
    created_at: '',
    updated_at: '' 
  };
  