import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, deals) => {              
        state.list = deals;        
    },
    SET_TOTALS: (state, data) => {              
        state.total = data.total ? data.total : 0;        
        state.total_paid = data.total_paid ? data.total_paid : 0;        
    },
    SET_ARCHIVED_DEALS_LIST: (state, deals) => {              
        state.archivedDeals = deals;        
    },
    PHONE_NUMBER_DEALS: (state, deals) => {              
        state.phone_number_deals = deals;        
    },
    SET_PAID_MONEY_LIST: (state, paidMoneyList) => {              
        state.paidMoneyList = paidMoneyList;        
    },
    SET_NOTIFICATION_TYPES: (state, notificationTypes) => {              
        state.notificationTypes = notificationTypes;        
    },
    SET_FILTER_OPTIONS: (state, filterOptions) => {              
        state.filterOptions = filterOptions;        
    },
    SET_UNPAID_DEALS_LIST: (state, deals) => {              
        state.unpaidDealsList = deals;        
    },
    SET_DELETED_DEALS_LIST: (state, deals) => {              
        state.deletedDeals = deals;        
    },
    SET_DEAL_TASKS: (state, dealTasks) => {              
        state.dealTasks = dealTasks;        
    },
    GET_CALENDAR_DEALS: (state, deals) => { state.dealsForCalendar = deals },    
    SET_INVENTORY: (state, deals) => (state.inventory = deals),
    SET_DEAL_STAGES: (state, data) => {
        state.dealStages = data.dealStages,
        state.deletedDealsForStage = data.deletedDeals
    },
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    TOGGLE_COLUMNS: (state, updateable_columns) => {
        updateable_columns.forEach(column => {
            if(state.columns[column]){
                state.columns[column].show = !state.columns[column].show
            }
        });
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, deal) => {
        state.model = deal
    },
    SET_FORM: (state, deal) => {
		 	state.model = deal;
      	// state.form = deal;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
        state.form = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
    EMPTY_DEAL_TASKS: (state) => {
        state.dealTasks = [];
    },
    EMPTY_ARCHIVED_DEALS_LIST: (state) => {
        state.archivedDeals = [];
    },
    EMPTY_DELETED_DEALS_LIST: (state) => {
        state.deletedDeals = [];
    },
};
