<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.userEmail')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(userEmail,index) in userEmails" :key="'userEmails-' + index" :label="userEmail.email" :value="userEmail.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      user_id: {
        default: null,
      },
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      user_id: {
        handler: function (val) {
          if(val){
            this.updateInventory({user_id: val, connected: true})
          }
        },
        immediate: true,
      },  
    },
    data() {
      return {
        selected: null,
      }
    },
    // mounted() {
    //   if (this.userEmails && this.userEmails.length === 0) this.updateInventory()
    // },
    computed: {
      ...mapGetters({
        userEmails: 'users/userEmailInventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'users/userEmailInventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
