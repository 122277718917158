export const model = {
    id: null,
    name: '',
	 deal_types: [],
	 percent: null,
	 service_sum: 0,
	 currency_id: null,
	 radio: true,
    created_at: '',
    updated_at: '',
  };
  