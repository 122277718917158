import {i18n} from '@/utils/i18n';

export const rules = {
    title: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.title')}), trigger: 'change' },
    ],
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    categories: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.categories')}), trigger: 'change' },
    ],
    country_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.country_id')}), trigger: 'change' },
    ],
    city_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.city_id')}), trigger: 'change' },
    ],
    category_ids: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.categories')}), trigger: 'change' },
    ],
};
