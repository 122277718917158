<template>
    <div v-if="series.length > 0">
        <apexchart type="donut" style="border-right: 1px solid rgb(53, 55, 68)" :width="chart_size" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
export default {
    props:{
        labels:{
            default: Array
        },
        chart_size:{
            default: 515
        },
        chart_height:{
            default: 350
        },
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
        parent_id: {
            default: null,
        },

    },
    data: () => ({
        category: [],
        child_category: null,
        series: [],
        chartOptions: {
            chart: {
                type: 'donut',
            },
            labels: [],
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            plotOptions: { 
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '15px',
                                color: '#fff',
                            },
                            value: {
                                show: true,
                                fontSize: '15px',
                                color: '#fff',
                                formatter: function (val) {
                                    return  'Сумма: ' + formatMoney(val, 1);
                                },
                            },
                            total: {
                                show: true,
                                fontSize: '15px',
                                color: '#fff',
                                label: 'Общий',
                            }
                        },
                    }
                },
            },
            legend: {
                show: true,
                fontSize: '13px',
                width: 170,
                height: 250,
                labels: {
                    colors: '#fff',
                    useSeriesColors: false
                },
                markers: {
                    strokeColor: '#fff',
                },
            },
        },
    }),
    watch: {
        update_data: {
            handler: async function (val) {
                // setTimeout(() => {
                //     this.updateChartData({
                //         parent_id: this.parent_id,
                //         period: this.period,
                //         filter: this.filterForm,
                //     });
                // }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            categoryStatistics: "home/categoryStatisticBySellingProduct",
        }),
        update_data() {
            return this.period + this.parent_id;
        }
    },
    methods: {
        ...mapActions({
            categoryStatisticsData: "home/categoryStatisticBySellingProduct",
        }),
        updateChartData(data) {
            this.categoryStatisticsData(data)
                .then(res => {
                    this.category = JSON.parse(JSON.stringify(this.categoryStatistics)); 
                    this.series = this.category.map(item => parseInt(item.money_amount))
                    let self = this;
                    this.chartOptions = {...this.chartOptions, ...{
                        labels: JSON.parse(JSON.stringify(this.category.map(item => item.name))),

                        chart: {
                            events: {
                                legendClick: function(chartContext, seriesIndex) {
                                    let cat = self.category[seriesIndex];
                                    if(cat.parent_id){
                                        self.child_category = cat;
                                        self.updateChartData({
                                            parent_id: cat.id,
                                            period: self.period,
                                            filter: self.filterForm,
                                        });
                                    }
                                }
                            }
                        },
                        plotOptions: { 
                            pie: {
                                donut: {
                                    labels: {
                                        total: {
                                            show: this.category.length > 1 ? true : false,
                                            label: 'Общий',
                                            formatter: function (w) {
                                                let money_amount = self.category.map(item => item.money_amount).reduce((a, b) =>parseInt(a) + parseInt(b), 0);
                                                return  'Сумма: ' + formatMoney(money_amount, 1);
                                            },
                                        }
                                    }
                                }
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return formatMoney(val, 2);
                                },
                            },
                        },
                        

                    }}

                })
        }
    },
}
</script>

<style>

</style>
