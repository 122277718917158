export const actions = {
    NEW_DAY_CHECK({state, commit}, payload){
        if(state.dateToday === ''){ // first day
            commit( 'setTheDate', payload);
        }else{
          if(state.dateToday !== payload.date){
            commit( 'setTheDate', payload);
            commit( 'setLockPassword', {password: ''});
            commit( 'changeLockStatus', {status: false});
          }
        }
    },
    TRIGGER_LOCK({commit}, payload){
        commit( 'changeLockStatus', payload);
    },
    UPDATE_PASSWORD({commit}, payload){
        commit( 'setLockPassword', payload);
    },
    UPDATE_TIMER({commit}, payload) {
        commit('setLockTimer', payload)
    }
}
