import { index, excelFileDownload } from "@/api/warehouses/parties";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.parties);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.parties);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    excelFileDownload({commit}, params = {}){
        return new Promise((resolve, reject) => {
            excelFileDownload(params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
