import { index, show, store, update, getMeasuringTypes, destroy, filialDealProducts, deleteParcelProduct, waitingParcels, showParcelProducts } from '@/api/deals/dealProducts';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.dealProducts)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })  
        })
    },

    waitingParcels({commit}, params = {}){
        return new Promise((resolve, reject) => {
            waitingParcels(params).then(res => {
                commit('SET_LIST', res.data.result.data.productCirculations)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })  
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    show({ commit }, params) {
        return new Promise((resolve, reject) => {
            show(params).then(res => {
                commit("SET_DEALPRODUCTS", res.data.result.data.dealProducts);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getMeasuringTypes({ commit }, ) {
        return new Promise((resolve, reject) => {
            getMeasuringTypes().then(res => {
                commit("SET_MEASURING_TYPES", res.data.result.data.measuringTypes);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    showParcelProducts({ commit }, params) {
        return new Promise((resolve, reject) => {
            showParcelProducts(params).then(res => {
                commit("SET_PARCEL_PRODUCTS", res.data.result.data);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    filialDealProducts({ commit }, params) {
        return new Promise((resolve, reject) => {
            filialDealProducts(params).then(res => {
                commit("SET_FILIAL_DEAL_PRODUCTS", res.data.result.data.dealProducts);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteParcelProduct({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteParcelProduct(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    
    emptyList({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_LIST");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    

}

