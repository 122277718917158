export const filter = {
    id : '',  
    year: '', 
    month: '', 
    count: '', 
    money_amount: '', 
    profit: '', 
    created_at: '',
    updated_at: ''  
  };
  