<template>
  <div id="app" :class="mode ? 'l-modal-style' : 'd-modal-style'">
    <div class="app-wrap d-flex f-column">
      <component v-show="!lockStatus" :is="layout">
        <router-view />
      </component>
      <twostep v-if="lockStatus" :show-lock="lockStatus"></twostep>
    </div>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import LockScreenMixin from './utils/mixins/LockScreenMixin'
import twostep from './views/twostep/index.vue'
import {mapGetters} from 'vuex';
export default {
  mixins: [LockScreenMixin],
  mounted(){
    let date = new Date()
    let today = `${date.getDate()} ${(date.getMonth()+1)} ${date.getFullYear()}`;
    this.NEW_DAY_CHECK({date: today});
  },
  watch: {
      idle(newValue) {
          if (!this.disable) {
              if(this.idle) {
                  this.lockUser()
              }
          } else {

          }
      }
  },
  computed: {
    ...mapGetters({
        timer: 'getLockTimer',
        idle: 'getLockBool',
        disable: 'getLockDisable',
        mode: "MODE"
    }),
    isIdle() {
        return this.$store.state.idleVue.isIdle && this.$route.path.split('/')[2] != 'login';
    },
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  methods: {
    lockUser(){
        // set lock password if it's not set
        if(this.lockPassword === ''){
            this.showSetLockPasswordModal = true;
        } else {
            this.TRIGGER_LOCK({ status: true });
        }
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
    LockScreenMixin,
    twostep
  },
};
</script>
<style lang="scss">
.app-modal__header.p-5 {
    padding: 15px 20px !important;
}
.large--title.m-0{
  margin-top: 10px !important;
}
.d-modal-style{
    // Color
    h3,
    .large--title,
    .el-checkbox__label,
    .flight__edit a,
    .timeline-items__right h4,
    .deal-tab-wrap .el-tabs__item,
    .text-descr,
    .el-tabs__item,
    .dashboard__info,
    .user-info,
    .el-tab-pane h2,
    .timeline-items__right span.ml-5,
    .my-button,
    .el-switch__label,
    .button-style-my i,
    .el-icon-circle-plus-outline.task-select-plus-icon,
    .button-style-my,
    .deal-task-tracker .font-bold ,
    .deal-task-tracker i ,
    .gc-card__header,
    .el-dialog__title,
    .el-upload--picture-card i,
    .el-collapse-item__header,
    .input--label,
    .el-form-item__label,
    .tracking__light,
    .large--title{
        color: #fff ;
    }    

    // border bg-color
    .el-divider__text,
    .el-drawer__body,
    .change-data-card,
    .body_scroll .el-drawer__body,
    .gc-card,
    .mm__tab__color,
    .table-my-code,
    .add-participants .el-dialog,
    .el-dialog__body,
    .el-upload--picture-card,
    .el-collapse-item__header, 
    .el-collapse-item__wrap,
    .app-modal .el-drawer,
    .timeline-items__right,
    .el-drawer__body .gc-card,
    .modal-color-bg .app-modal__box,
    .responsible-users-dropdown,
    .bg-se .el-drawer__body{
        background: #080c24 !important;
    }

    // border table
    
    .bg-rem,
    .timeline-textarea,
    .button__settingsnight button,
    .el-input__inner,
    .el-drawer__body .el-input__inner,
    .deal__backnight,
    .responsible-block,
    .responsible-block-dropdown,
    .gc-country-input,
    .el-textarea__inner,
    .input__night .el-input__inner,
    .filter__night .el-input__inner,
    .height__full .table-my-code,
    .height__full .table-my-code th,
    .height__full .table-my-code td,
    .ali.table-my-code>thead>tr>th,
    .ali.table-my-code>tbody>tr>th{
        border: 1px dashed #2a2f34 !important;
        background-color: #0e2c4685 !important;
        color: #ced4da;
    }
    .app-modal__header95,
    .modal-footer-buts,
    .el-dialog__header,
    .gc-card__header,
    .app-modal__header,
    .app-modal__header.r-d,
    .body_scroll .app-modal__header95,
    .body_scroll_70 .app-modal__header,
    .body_scroll_80 .app-modal__header,
    .create-task-modal .el-dialog__header{
        background: #0e2c46 !important;
    }
    // BORDER CORD STYLE COLOR 
    .gc-card,
    .request__linight,
    .el-drawer__body .gc-card{
        border: 1px dashed #2a2f34 !important;
    }
   
    .timeline-items__right{
      border: 1px dashed #dce2e633;
      border-left: 4px solid #409eff !important;
    }
    .el-collapse-item__wrap{
      border-bottom: 1px dashed #2a2f34 !important;
    }
    .responsible-block-dropdown::after {
      border-color: transparent transparent #153f78 transparent;
    }
    .responsible-users-dropdown .user-item-avatar{
      background-color: white;
    }
    .users-dropdown-items:hover,
    .users-dropdown-items.active{
      background-color: #0e2c4685 !important;
    }
    .el-range-input{
      background-color: initial;
    }
    .el-input__inner:hover,
    .button__settingsnight button:hover{
      border-color: #fff !important;
    }
    .input-group-wrap{
     border: none;
    }
    .gc-card__header,
    .app-modal__header.p-5 {
       border-bottom: 1px dashed #2a2f34 !important;
    }
    .el-table,
    .el-table__expanded-cell,
    .el-table td, 
    .el-table th.is-leaf{
      background-color: #0e2c46 !important;
      color: #fff;
    }
}
// .l-modal-style{
  
// }

</style>