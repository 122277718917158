export const filter = {
    id : '',  
    deal_id: '', 
    deal_product_id: '',
    client_id: null,
    to_client_id: null,
    partner_client_id: null,
    from_filial_id: '',
    to_filial_id: '',
    product_name: '', 
    barcode: '', 
    date: '',
    circulated: false,
    showHistory: false,
    shipping_cost: '',
    created_at: '',
    updated_at: '',
    search: ''   
  };
  