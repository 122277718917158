import Vue from 'vue'
import store from '../store'
/**
 * global variables
 */

Vue.prototype.$kg_to_pound = parseFloat(2.20462);
Vue.prototype.$sm_to_inch = parseFloat(0.3937);


/**
 * timeline component
 */

import { Timeline } from 'vue2vis'
Vue.component('timeline', Timeline)
import 'vue2vis/dist/vue2vis.css'
 
 /**
  * end vue modal components
  */

/**
 * vue modal component
 */

 import VModal from 'vue-js-modal'
 Vue.use(VModal);
 
 /**
  * end vue modal components
  */

/*
    Progress Bar
*/
import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: store.getters.getLockTimer, // 3 seconds
  startAtIdle: false,
  events:['mousemove', 'keydown', 'mousedown', 'touchstart',"scroll"]
});
/**
 * end progress bar
 */


import money from 'v-money'
Vue.use(money, { precision: 2, decimal: ',', thousands: ' ', masked: false, prefix: '', suffix: '' });
/**
 * Element UI
 */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI,{locale});
/**
 * end element ui
 */
/**
 * Vue moment
 */
// import moment from 'moment'
// import 'moment/locale/uz'

// Vue.use(require('vue-moment'), {
//     moment
// })

// /**
//  * money format
//  */
// import money from 'v-money'
// Vue.use(money, {precision: 2})


// /**
//  * For export excel
//  */
// import excel from 'vue-excel-export'
// Vue.use(excel);


/**
 * For alert
 */
import { notify, error__message, warning__message, info__message} from './index'
Vue.prototype.$alert = notify;
Vue.prototype.$error_message = error__message;
Vue.prototype.$warning_message = warning__message;
Vue.prototype.$info_message = info__message;

/**
 * For cursor
 */
import { loadingCursor } from './index'
Vue.prototype.$loadingCursor = loadingCursor;



Vue.mixin({
    data() {
        return {
            base_url: process.env.VUE_APP_URL,
            date_format: 'yyyy-MM-dd',
            date_time_format: 'yyyy-MM-dd HH:mm',
        }
    },
    methods: {
        formatDate(date = new Date) {
            if(date){
                var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hour = d.getHours(),
                minute = d.getMinutes();
                
                if (month.length < 2) month = '0' + month;
                if (day.length < 2)  day = '0' + day;
                if (hour.length < 2)  hour = '0' + hour;
                if (minute.length < 2)  minute = '0' + minute;
        
                return [year, month, day].join('-') + ' ' + [hour, minute].join(':');
            }else{
                return '';
            }
            
        },
        
    }
});
