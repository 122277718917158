import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/containers',
        method: 'get',
        params
    })
}

export function showIncomingFilialContainers(params) {
    return request({
        url: '/containers/showIncomingFilialContainers',
        method: 'get',
        params
    })
}

export function store(data) {
    return request({
        url: '/containers',
        method: 'post',
        data
    })
}

export function sendReadyContainersToFlight(data) {
    return request({
        url: '/containers/sendReadyContainersToFlight',
        method: 'post',
        data
    })
}

export function inventory(params) {
    return request({
        url: "/containers/inventory",
        method: "get",
        params
    });
}

export function show(params) {
    return request({
        url: `/containers/${params.id}`,
        method: 'get',
        params
    })
}
export function update(data) {
    return request({
        url: `/containers/${data.id}`,
        method: 'put',
        data
    })
}

export function printContainerBarcode(params) {
    return request({
        url: `/containers/printContainerBarcode`,
        method: 'get',
        params
    })
}

export function scanContainer(params) {
    return request({
        url: `/containers/scanContainer`,
        method: 'get',
        params
    })
}

export function storeContainerProductsInFilial(data) {
    return request({
        url: `/containers/storeContainerProductsInFilial/${data.id}`,
        method: 'put',
        data
    })
}

export function setAirwayBillNumber(data) {
    return request({
        url: `/containers/setAirwayBillNumber/${data.id}`,
        method: 'put',
        data
    })
}

export function readyToSendToFlight(params) {
    return request({
        url: `/containers/readyToSendToFlight`,
        method: 'get',
        params
    })
}

export function getAllPackageAndProductsToSend(params) {
    return request({
        url: `/containers/getAllPackageAndProductsToSend`,
        method: 'get',
        params
    })
}

export function setContainersInFilial(data) {
    return request({
        url: '/containers/setContainersInFilial',
        method: 'post',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/containers/${id}`,
        method: 'delete',
    })
}
export function removeProductFromContainer(params) {
    return request({
        url: `/containers/removeProductFromContainer/${params.id}`,
        method: 'delete',
        params
    })
}

export function removePackageFromContainer(params) {
    return request({
        url: `/containers/removePackageFromContainer/${params.id}`,
        method: 'delete',
        params
    })
}
