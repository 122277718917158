<template>
  <div class="product-add">
    <div class="product-add-table mt-0">
        <div class="flight__flex">
            <div class="mb-4 ds__flex">
                <span class="align-items-center align-self-center pr-2">
                    <span class="el-tag el-tag--light info__width" :class="mode ? 'price__day' : 'price__night blue__night'">
                        Общее количество грузов: 3
                    </span>
                </span>
                <span class="align-items-center align-self-center pr-2">
                    <span class="el-tag el-tag--success el-tag--light info__width" :class="mode ? 'price__day' : 'price__night green__night'">
                        Распределенных грузов: 9
                    </span>
                </span> 
                <span class="align-items-center align-self-center pr-2">
                    <span class="el-tag el-tag--success el-tag--light info__width" :class="mode ? 'price__day' : 'price__night green__night'">
                        Осталось грузов: 9
                    </span>
                </span>
            </div>
            <el-row :gutter="20">
                
                <el-col :span="12">
                    <div class="order__border px-3 py-2 mb-4">
                        <div class="order__box">
                            <span>Заказ №123 1</span>
                            <el-button class="order__padding" circle icon="el-icon-edit"></el-button>
                        </div>
                        <div class="mb-2">
                            <span>Рейс: HY 301 США-Ташкент</span>
                        </div>
                        <div class="mb-2">
                            <span>Количество товаров: 5</span>
                        </div>
                        <div class="mb-2">
                            <span>Вес: 6кг</span>
                        </div>
                        <div class="order__status">
                            <el-button class="w100" type="success">На складе</el-button>
                            <el-button class="w100" type="success">В пути</el-button>
                            <el-button class="w100">Доставлено</el-button>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="order__border px-3 py-2 mb-4">
                        <div class="order__box">
                            <span>Заказ №123 1</span>
                            <el-button class="order__padding" circle icon="el-icon-edit"></el-button>
                        </div>
                        <div class="mb-2">
                            <span>Рейс: HY 301 США-Ташкент</span>
                        </div>
                        <div class="mb-2">
                            <span>Количество товаров: 5</span>
                        </div>
                        <div class="mb-2">
                            <span>Вес: 6кг</span>
                        </div>
                        <div class="order__status">
                            <el-button class="w100" type="success">На складе</el-button>
                            <el-button class="w100" type="success">В пути</el-button>
                            <el-button class="w100">Доставлено</el-button>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-button @click="drawerAddFlight = true" type="primary">Распределить на заказы</el-button>

            
        </div>
        <el-drawer
                class="bg-se"
                :visible.sync="drawerAddFlight"
                :with-header="false"
                ref="drawerAddFlight"
                @opened="drawerOpened('drawerAddFlightChild')"
                @closed="drawerClosed('drawerAddFlightChild')"
                :append-to-body="true"
                size="50%"
            >
                <edit-flight />
            </el-drawer>
  </div>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import EditFlight from './components/edit-flight.vue';

export default {
    name: "dealOffer",
    props: {
        deal_id: {
            default: null,
        },
    },
    data() {
        return {
            loadingData: false,
            filterForm: {},
            getList: [],
            activeOfferId: null,
            drawerAddFlight: true
        };
    },
    created() {
        this.drawerAddFlight = true
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
    },
    watch: {
        deal_id(val) {
            if (val) {
                this.loadingData = true;
                this.updateList(val).then(() => {
                    this.getList = JSON.parse(JSON.stringify(this.list));
                    this.loadingData = false;
                })
                    .catch((err) => {
                    this.loadingData = false;
                });
            }
        },
        list() {
            this.getList = JSON.parse(JSON.stringify(this.list));
        },
    },
    computed: {
        ...mapGetters({
            list: "dealOffers/dealOfferList",
            columns: "dealOffers/columns",
            pagination: "dealOffers/pagination",
            filter: "dealOffers/filter",
            sort: "dealOffers/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["delete"];
        },
    },
    methods: {
        ...mapActions({
            downloadDealOffer: "dealOffers/downloadDealOffer",
            updateStatus: "dealOffers/update",
            updateList: "dealOffers/getDealOffers",
            setPagination: "dealOffers/setPagination",
            updateSort: "dealOffers/updateSort",
            updateFilter: "dealOffers/updateFilter",
            updateColumn: "dealOffers/updateColumn",
            updatePagination: "dealOffers/updatePagination",
            show: "dealOffers/show",
            empty: "dealOffers/empty",
            delete: "dealOffers/destroy",
            refreshData: "dealOffers/refreshData",
        }),
        changeStatus(offer) {
            this.$confirm(this.$t("message.do_you_really_want_to_do_this"), this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
            })
                .then(() => {
                this.$emit("listChanged");
                this.debouncedUpdateStatus(offer, this);
            })
                .catch(() => {
                offer.status = !offer.status;
                this.$message({
                    type: "warning",
                    message: this.$t("message.operation_canceled")
                });
            });
        },
        debouncedUpdateStatus: _.debounce((offer, self) => {
            if (_.isFunction(self.updateStatus)) {
                self.updateStatus(offer);
            }
        }, 500),
        downloadOffer(id) {
            let fileName = "file.pdf";
            this.downloadDealOffer(id)
                .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            })
                .catch((err) => {
                this.$alert(err);
            });
        },
    },
    components: { EditFlight }
};
</script>



<style>
    .ds__flex {
        display: flex;
        flex-direction: row;
    }
    .info__width {
        width: 200px;
    }    
</style>
