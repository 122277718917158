export default [
   {
        path: '/deliveryParties',
        name: 'deliveryParties',
        meta: { layout: "main" },
        component: () => import('@/views/integration/index'),
    },
    {
        path: '/deliveryParties/:party_id',
        name: 'deliveryParties.show',
        meta: { layout: "main" },
        component: () => import ('@/views/integration/party-index'),
    }
]
