export const model = {
    id : null,  
    transport_id: null, 
    driver: '', 
    transport_number: '', 
    phone: '', 
    phone_country_code: 'UZ', 
    email: '', 
    created_at: '',
    updated_at: '' 
  };
  