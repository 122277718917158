<template>
    <div class="khan-chart-a">
        <div id="chart">
            <apexchart
                type="line"
                height="410"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
    },
    data() {
        return {
            updated_money_amounts: [],
            series: [
                {
                    name: 'total_amount_t',
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 388,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.$emit("dataPointSelection", {index: config.dataPointIndex+1, period: this.period});

                            this.$emit("dataPointAmount", this.dealPayments.money_amounts[config.dataPointIndex]);
                        }
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return formatMoney(val);
                    },
                },

                stroke: {
                    curve: "smooth",
                },
                title: {
                    // text: "Product Trends by Month",
                    align: "left",
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0,
                    },
                },
                markers: {
                    size: 7,
                    shape: "circle",
                },
                
                tooltip: {
                    shared: false,
                    intersect: true,
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: "",
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (value) {
                            return formatMoney(value);
                        },
                    },
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                },
            },
        };
    },
    watch: {
        watchAll: {
            handler: async function (val) {
                // setTimeout(() => {
                //     this.updateChartData({
                //         period: this.period,
                //         filter: this.filterForm,
                //     });
                // }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            dealPayments: "home/dealPayments",
        }),
        watchAll(){
          return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            dealPaymentsDiagram: "home/dealPayments",
        }),

        updateChartData(data) {
            // this.dealPaymentsDiagram(data).then((res) => {
            //     this.series[0].name = this.$t('message.total_amount_t');
            //     this.series[0].data = this.dealPayments.money_amounts;
            //     this.chartOptions.xaxis.categories = this.dealPayments.dates;
            //     let self = this;
            //     this.chartOptions = {
            //         ...this.chartOptions,
            //         ...{
            //             chartOptions: {
            //                 xaxis: {
            //                     categories: self.dealPayments.dates,
            //                 },
            //             },
            //         },
            //     };
            // });
        },
    },
};
</script>

<style>
</style>
