import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    driver : {
        show: true,
        title: i18n.t('message.driver'),
        sortable: true,
        column: 'driver'
    },
    transport_id : {
        show: true,
        title: i18n.t('message.transport'),
        sortable: true,
        column: 'transport_id'
    },
    transport_number : {
        show: true,
        title: i18n.t('message.transport_number'),
        sortable: true,
        column: 'transport_number'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
