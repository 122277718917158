<template>
  <div>
    <div class="d-flex">
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.product')" clearable
        filterable remote :remote-method="searchWithLoad" :disabled="disabled" :loading="loadingData" :size="size" class="w-100">
        <el-option v-for="(product,index) in inventoryItems" :key="'products-' + index" :label="product.name" :value="product.id"></el-option>
      </el-select>
      <el-button v-if="(create === true) && permissions.some(per => per.slug == 'products.create')" @click="drawerCreate = true" :disabled="disabled" :size="size" type="primary" icon="el-icon-plus" class="ml-5" plain circle></el-button>
      <el-button v-if="authUser.client_id ? true : false" @click="createNewProduct()" :size="size" type="primary" icon="el-icon-plus" class="ml-5" plain circle> {{ $t('message.add_product') }} </el-button>
    </div>
    <!-- Create -->
    
    <div class="app-modal app-modal__full modal-color-bg">
      
      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="create === true" 
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          class="body_scroll_70"
          :append-to-body="true"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
      >
          <div>
              <crm-create
                @newInventoryItem="newInventoryItem"
                ref="drawerCreateChild"
                drawer="drawerCreate"
              >
              </crm-create>
          </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import CrmCreate from "@/views/product/components/crm-create";
  import select_create_drawer from "@/utils/mixins/select_create_drawer";

  export default {
    mixins: [select_create_drawer],
    components: {
      CrmCreate
    },
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      disabled:{
        default: false
      },
      add_query:{
        default: Object
      },
      create:{
        default: false
      }
    },
    watch:{
      // id:{
      //   handler: function() {
      //       this.selected = this.id
      //   },
      //   immediate: true
      // }
    },
    data() {
      return {
        selected: null,
        filterInput: '',
        loadingData: false,
      }
    },
    mounted() {
      if (this.inventoryItems && this.inventoryItems.length === 0) this.updateInventory(this.add_query)
    },
    computed: {
      ...mapGetters({
        mode: "MODE",
        authUser: "auth/user",
        permissions: "auth/permissions", 
        inventoryItems: 'products/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'products/inventory'
      }),
      createNewProduct(){
        this.$emit('createNewProduct', this.filterInput);
      },
      searchWithLoad(val){
        this.loadingData = true;
        this.filterInput = val;
        this.deabouncedFilterProduct(val);
      },
      deabouncedFilterProduct: _.debounce(
        function(val) {
          this.updateInventory({search: val, ...this.add_query})
            .then(res => {
              this.loadingData = false;
            }).catch(err => {
              this.loadingData = false;
            })
        }, 500),  
      
      dispatch(e) {
        this.$emit('get_selected_product', e)
      }
    },
  }

</script>
