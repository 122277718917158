<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.city')"
      filterable
      clearable
      :size="size"
      :disabled="disabled"
      class="w-100"
      :loading="loadingData"
      remote 
      :remote-method="searchInventoryWithLoad"
    >
      <el-option
        v-for="(city, index) in cityList"
        :key="'cities-' + index"
        :label="city.name"
        :value="city.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    country_id: {
      default: null,
    },
    region_id: {
      default: null,
    },
    disabled: {
      default: false,
    },
    
  },
  watch: {
    id: {
      handler: function (val) {
        if(val && !this.cityList.find(el => el.id === this.id)){
          this.searchInventoryWithLoad();
        }else{
          this.selected = this.id;
        }
      },
      immediate: true,
    },

    country_region: {
      handler: function (val) {
        if(this.country_id){
          this.loadingData = true;
          this.updateInventory({country_id: this.country_id, region_id: this.region_id, selected_id: this.id})
          .then(res => {
            this.cityList = JSON.parse(JSON.stringify(this.cities));
            this.loadingData = false;
          }).catch(err => {
              this.loadingData = false;
          });
        }else{
          this.cityList = [];
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      cityList: [],
      loadingData: false
    };
  },
  computed: {
    ...mapGetters({
      cities: "cities/inventory",
    }),

    country_region(){
      return this.country_id + this.region_id + 'aa';
    }
  },
  methods: {
    ...mapActions({
      updateInventory: "cities/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      let city = this.cityList.find(el => el.id === e);
      if(city){
        this.$emit("cityRegion", city.region_id);
      }
    },
    searchInventoryWithLoad(val = ''){
      this.loadingData = true;
      this.searchInventory(val, this);
    },

    searchInventory: _.debounce(function(val, self) {
        self.updateInventory({search: val, country_id: this.country_id, region_id: this.region_id, selected_id: this.id})
          .then(() => {
            this.cityList = JSON.parse(JSON.stringify(this.cities));
            this.loadingData = false;
            setTimeout(() => {
              this.selected = this.id;
            }, 100);
          })
          .catch(() => this.loadingData = false)
    }, 500), 
  },
};
</script>
