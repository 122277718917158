export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    types: state => state.types,
    deals: state => state.deals,
    genders: state => state.genders,
    personClients: state => state.personClients,
    model: state => state.model,
    rules: state => state.rules,
    client_product_rules: state => state.client_product_rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    client_account: state => state.client_account, 
    contact_people: state => state.contact_people,
    clientDashboard: state => state.clientDashboard,
    clientFilialProducts: state => state.clientFilialProducts,
    searchInventory: state => state.searchInventory,
    client_address_rules: state => state.client_address_rules,  
    clientAddresses: state => state.clientAddresses,
    recieverDealsList: state => state.recieverDealsList,
  };
  