export const model = {
  id : null,  
  barcode: '', 
  in_filial: true, 
  from_filial_id: null, 
  to_filial_id: null, 
  deal_id: null, 
  client_id: null, 
  container_type_id: null, 
  country_id: null, 
  products: [],
  created_at: '',
  updated_at: '' 
};
