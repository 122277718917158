import { index, inventory, show, store, update, destroy, printPackageBarcode, getPackageByBarcode, getProdOrPackByBarcode,
    showProducts, deletePackageProduct, deleteDealPackage, waitingIndex, showPackageDealProducts } from "@/api/productCirculations/packageForProductCirculations";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.packageForProductCirculations);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.packageForProductCirculations);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    waitingIndex({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            waitingIndex(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.packageForProductCirculations);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.packageForProductCirculations);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    waitingPackages({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            waitingIndex(params)
                .then(res => {
                    commit("SET_WAITING_PACKAGES", res.data.result.data.packageForProductCirculations);
                    resolve(res.data.result.data.packageForProductCirculations);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },
    getProdOrPackByBarcode({ commit }, params) {
        return new Promise((resolve, reject) => {
            getProdOrPackByBarcode(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    printPackageBarcode({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            printPackageBarcode(params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },

    getPackageByBarcode({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getPackageByBarcode(params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data)
                })
        })
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.packageForProductCirculations);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.packageForProductCirculation);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    showProducts({ commit }, id) {
        return new Promise((resolve, reject) => {
            showProducts(id)
                .then(res => {
                    commit('SET_PACKAGE_PRODUCTS', res.data.result.data.productCirculations);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    showPackageDealProducts({ commit }, id) {
        return new Promise((resolve, reject) => {
            showPackageDealProducts(id)
                .then(res => {
                    commit('SET_PACKAGE_DEAL_PRODUCTS', res.data.result.data.dealProducts);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, params) {
        return new Promise((resolve, reject) => {
            destroy(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deletePackageProduct({ commit }, params) {
        return new Promise((resolve, reject) => {
            deletePackageProduct(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteDealPackage({ commit }, params) {
        return new Promise((resolve, reject) => {
            deleteDealPackage(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    updateEditedRow({ commit }, data) {
        commit("UPDATE_EDITED_RAW", data)
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
