import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal_id'),
        sortable: true,
        column: 'deal_id'
    },
    date : {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date'
    },
    end_date : {
        show: true,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    currency : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency'
    },
    count_product : {
        show: true,
        title: i18n.t('message.count_product'),
        sortable: true,
        column: 'count_product'
    },
    status : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status'
    },
    total_price : {
        show: true,
        title: i18n.t('message.total_price'),
        sortable: true,
        column: 'total_price'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: false,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },
    download : {
        show: true,
        title: i18n.t('message.download'),
        sortable: false,
        column: 'download'
    },

};
