export const model = {
    id: null,
    title: '', 
    name: '',
    country_id: null, 
    city_id: null, 
    address: '', 
    phone_1: '', 
    phone_2: '', 
    plugin: '', 
    phone_country_code_1: 'UZ', 
    phone_country_code_2: 'UZ', 
    created_at: '',
    updated_at: '',
  };
  