<template>
  <div>
    <div id="chart" v-if="dealSources.ids && dealSources.ids.length > 0">
      <apexchart
        type="radialBar"
        :height="size"
        ref="chart"
        :options="chartOptions"
        :series="series"
        :labels="labels"
      ></apexchart>
    </div>
    <div v-else class="eror">
      <img src="/img/dashboard.png" alt="Logo" class="d-block" />
      <h1>
        {{ $t('message.no_data_yet') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApexCharts from "apexcharts";
export default {
  components: {
    ApexCharts,
  },
  props: {
    period: {
      default: 'weekly'
    },
    size: {
        default: '417'
    },
    filterForm: {
      default: null
    }
  },
  data() {
    return {
      innerText: 'overall',
      series: [],
      labels: [],
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
                          style: {
                            colors: ["#9C27B0"],
                          },
                          name: {
                            fontSize: "22px",
                          },
                          value: {
                            fontSize: "16px",
                            color: "#fff"
                          },

                          total: {
                              color: "#fff",
                              show: true,
                              label: 'overall',
                              formatter: function(w) {
                                  return w.globals.series;
                              }
                          }
                        }
                    }
                },
                
                labels: []
                // dataLabels: {
                //     style: {
                //         fontSize: "14px",
                //         fontFamily: "Helvetica, Arial, sans-serif",
                //         fontWeight: "bold",
                //         colors: undefined
                //     }
                // }
            }
        };
    },
    watch: {
      watchAll: {
        handler: async function(val) {
          // setTimeout(() => {
          //   this.updateChartData({period: this.period, filter: this.filterForm});
          // }, 500);
        },
        deep: true,
        immediate: true
      },
    },
    computed: {
        ...mapGetters({
            dealSources: "sources/dealSources"
        }),
        watchAll(){
          return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updateDealSources: "sources/dealSources"
        }),

      updateChartData(data){
        
        this.updateDealSources(data)
          .then(() => {
              let self = this;
              if(this.dealSources && this.dealSources.ids && this.dealSources.ids.length === 1){
                this.innerText = this.dealSources.names[0];
              }else{
                this.innerText = this.$t('message.overall')
              }
              this.chartOptions = {...this.chartOptions, ...{
                labels: JSON.parse(JSON.stringify(this.dealSources.names)),
                
                plotOptions: {
                  radialBar: {
                    dataLabels: {
                      total: {
                        show: true,
                        label: self.innerText,
                        formatter: function (w) {
                          return self.dealSources.counts.reduce((a, b) => a + b, 0);
                        },
                      },
                    }
                  }
                },
                
              }}
                this.series = JSON.parse(
                    JSON.stringify(this.dealSources.percents)
                );

                this.dealSources.counts.reduce((a, b) => a + b, 0);
            });
      }
    }
};
</script>

<style>
.eror{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}
.eror h1{
    color: #fff;
    font-size: 20px;
}
.eror img{
  width: 160px;
}
</style>
