<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.kpTemplate')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(kpTemplate,index) in kpTemplates" :key="'kpTemplates-' + index" :label="kpTemplate.name" :value="kpTemplate.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      filial_id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      filial_id:{
        handler: function() {
            if (this.filial_id && this.kpTemplates && this.kpTemplates.length === 0) this.updateInventory({filial_id: this.filial_id})
        },
        immediate: true
      },

    },
    data() {
      return {
        selected: null,
      }
    },
    computed: {
      ...mapGetters({
        kpTemplates: 'kpTemplates/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'kpTemplates/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
