import {i18n} from '@/utils/i18n';

export const rules = {
    year: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.year')}), trigger: 'change' },
    ],
    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
    filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filial')}), trigger: 'change' },
    ],
};
