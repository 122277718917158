export const filter = {
    id: null,
    user_id:  null,
    delivery_deal_id: null,
    tracking_code: '',
    from_filial_id:  null,
    to_filial_id:  null,
    deal_stage_id:  null,
    notification_type:  '',
    shipping_service:  '',
    shipping_address:  '',
    phone: '',
    email: '',
    begin_date: '', 
    end_date: '',
    finish_date: '',
    client_id:  null,
    to_client_id:  null,
    partner_client_id:  null,
    payer_client_id:  null,
    reason_id:  null,
    comment: '',
    created_at: '',
    updated_at: '',         
    spectators:  [],
    to: null,
    from: null   
};