import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/products',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/products',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/products/inventory",
        method: "get",
        params
    });
}
export function getWithTariffPrice(params) {
    return request({
        url: "/products/getWithTariffPrice",
        method: "get",
        params
    });
}

export function filialProducts(filial_id) {
    return request({
        url: `/products/getFilialProducts/${filial_id}`,
        method: "get",
    });
}

export function show(id) {
    return request({
        url: `/products/${id}`,
        method: 'get'
    })
}
export function update(data) {

    let data_id = null;
    if (data instanceof FormData) {
        data_id = data.get('id')
    }
    else {
        data_id = data.id;
    }
    return request({
        url: `/products/${data_id}?_method=PUT`,
        method: 'post',
        data
    })
}

export function replaceDealProductIds(data) {
    return request({
        url: `/products/replaceDealProductIds/${data.replace_to_product_id}`,
        method: 'put',
        data
    })
}
export function replaceProductDetails(params){
    return request({
        url: `/products/replaceProductDetails`,
        method: 'get',
        params
    })
}

export function destroy(id) {
    return request({
        url: `/products/${id}`,
        method: 'delete'
    })
}

export function getProducts() {
    return request({
        url: '/products/getProducts',
        method: 'get'
    })
}

export function deleteImage(data) {
    return request({
        url: '/products/images/delete',
        method: 'post',
        data
    })
}