export const model = {
  id: null,
  name: '',
  user_id: null,
  participants: [],
  labels: [],
  checkLists: [],
  miniTasks: [],
  begin_date: '',
  end_date: '',
  finish_date: '',
  deal_id: '',
  comments: [],
  files: [],
  task_board_id: '',
  index: 0,
  status: '',
  created_at: '',
  updated_at: '',
};
  