<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.notifications")}}</p>

            <crm-store-update-close
                :button_type="'close'"
                @c-close="close()"
            ></crm-store-update-close>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 message__all_exxpres">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div>
                <ul v-loading="loadingData"
                    element-loading-text="Loading..."
                    element-loading-spinner="el-icon-loading"   
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                    <li v-for="notification in notificationList" :key="notification.id" class="message__li">
                        <a>
                            <div class="message__info">
                                <i v-if="notification.status == 1" @click="editProduct(notification)" class="fa-solid fa-edit success-bg mm__cursor__pointer"></i>
                                <i v-if="notification.status == 2" class="fa-solid fa-flag warning-bg"></i>
                                <i v-if="notification.status == 3" class="fa-solid fa-flag danger-bg"></i>
                                <div class="message__about">
                                    <div class="mes-ynsage__date" style="display:flex; justify-content: space-between;">
                                        <span class="date__text">{{ notification.created_at }}</span>
                                        <div class="message__animation">
                                            <transition name="slide-fade">
                                                <span v-if="notification.unread" class="message__readed">Прочитано</span>
                                            </transition>

                                            <transition name="slide-fade">
                                                <span v-if="(!authUser.client_id  || notification.type === 4) && !notification.unread" class="message__unreaded">Не прочитано</span>
                                                <span v-if="!notification.unread && authUser.client_id && notification.type !== 4"
                                                    @click.stop="updateNotificationStatus(notification)"  
                                                    class="message__unreaded mm__cursor__pointer">
                                                        Не прочитано
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                    <div>
                                        <span >{{ messageHeader(notification) }}</span>
                                    </div>
                                    <div>
                                        <p>
                                            {{ notification.comment }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          :append-to-body="true"
           class="bg-se"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <product-update
            :selectedItem="selectedItem"
            :notification_id="selectedItem.notification_id"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></product-update>
        </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import ProductUpdate from "@/views/product/components/crm-update";

export default {
    mixins: [form, drawer],
    components: {
        ProductUpdate
    },
    data() {
        return {
            notificationList: [],
            selectedItem: {},
            drawerUpdate: false,
            drawerNotification: false,
            loadingData: false,
        };
    },
    watch: {
        'pagination.page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        'pagination.per_page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            authUser: "auth/user", 
            list: "systemNotifications/user_notifications",
            pagination: "systemNotifications/pagination",      
        }),
    },
    methods: {
        ...mapActions({
            updatePagination: "systemNotifications/updatePagination", 
            updateList: "systemNotifications/showWhenSomethingChanges",
            updateNotification: "systemNotifications/update",
        }),
        // afterLeave(){
        //     this.fetchData(true);
        // },
        messageHeader(notification){
            if(notification.type === 4){
                return this.$t('message.announcement')
            }
            return notification.type_name;
        },
        editProduct(notification){
            this.selectedItem = {id: notification.notificationable_id, notification_id: notification.id};
            this.drawerUpdate = true;
        },
        updateNotificationStatus(notification){
            if(notification.type !== 4){
                notification.status = 3;
                this.updateNotification(notification)
                    .then(res => {
                        notification.unread = true;
                    }).catch(err => { })
            }

        },
        debouncedFetchData: _.debounce(
        function(val) {
          this.fetchData();
        }, 300), 

        fetchData(first_page = false) {
            this.loadingData = true;
            let query = {};
            if(!first_page){
                query = this.pagination;
            }
            this.updateList(query)
            .then(res => {
                this.notificationList = JSON.parse(JSON.stringify(this.list));
                for(let element of this.notificationList){
                    if(element.type == 1){
                        if(!this.authUser.client_id){
                            let comment = this.$t("message.user_created_with_id", {   
                                user: element.created_user ? element.created_user.name : '', 
                                item: element.type_name, 
                                id: element.notificationable_id,
                                item_name: element.notificationable ? element.notificationable.name : '',
                                deal_ids: element.deals ? element.deals : '',
                            });
                            this.$set(element, 'comment', comment);
                        }else{
                            let comment = this.$t("message.user_confirmed_notification", {   
                                user: element.confirmed_user ? element.confirmed_user.name : '', 
                                item: element.type_name,
                                id: element.notificationable_id,
                                item_name: element.notificationable ? element.notificationable.name : '',
                                deal_ids: element.deals ? element.deals : '',
                            });
                            this.$set(element, 'comment', comment);
                        }
                    }
                    
                    this.$set(element, 'readMore', false);
                }
                this.loadingData = false;
            })
            .catch(err => { this.loadingData = false; })
        },

        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.debouncedFetchData();
        },
    },
};
</script>
<style lang="scss">
</style>