export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  usersDeals: state => state.usersDeals,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  search: state => state.search,
  statues: state => state.statues,
  permissions: state => state.permissions,
  userEmailInventory: state => state.userEmailInventory,
  userPermissionTypes: state => state.userPermissionTypes,
  getUserTelegramStatuses: state => state.userTelegramStatuses, 
};
