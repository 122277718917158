import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    year : {
        show: true,
        title: i18n.t('message.year'),
        sortable: true,
        column: 'year'
    },

    filial_id : {
        show: true,
        title: i18n.t('message.filial'),
        sortable: true,
        column: 'filial_id'
    },

    month : {
        show: true,
        title: i18n.t('message.month'),
        sortable: true,
        column: 'month'
    },
    count : {
        show: true,
        title: i18n.t('message.count'),
        sortable: true,
        column: 'count'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    profit : {
        show: true,
        title: i18n.t('message.profit'),
        sortable: true,
        column: 'profit'
    },

    currency_id : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_id'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
