<template>
  <el-select
    :value="selected"
    :multiple="is_multiple"
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.deal')"
    :size="size"
    class="email-zdelka mt-3"
    filterable 
    clearable
  >
    <el-option
      v-for="(deal, index) in deals"
      :key="'deal-' + index"
      :label="'№' + deal.id + ' -> ' +deal.name"
      :value="deal.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    deal_email: {
      default: null,
    },
  },
  watch: {
    deal_email: {
      handler: function (val) {
        this.updateInventory({email: val, user_id: this.authUser.id});
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
        is_multiple: true,
    };
  },
  computed: {
    ...mapGetters({
      deals: "deals/inventory",
      authUser: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "deals/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>