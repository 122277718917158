import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    // user_id : {
    //     show: true,
    //     title: i18n.t('message.user'),
    //     sortable: true,
    //     column: 'user_id'
    // },
    client_id : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client_id'
    },
    filial_id : {
        show: false,
        title: i18n.t('message.filial'),
        sortable: true,
        column: 'filial_id'
    },
    payment_type_id : {
        show: true,
        title: i18n.t('message.paymentType'),
        sortable: true,
        column: 'payment_type_id'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    currency_rate : {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'currency_rate'
    },
    bank_account_id : {
        show: true,
        title: i18n.t('message.bankAccount'),
        sortable: true,
        column: 'bank_account_id'
    },
    currency : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    date : {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
