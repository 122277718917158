export const getters = {
    money: state => state.money,  
    token: state => state.auth.token,
    name: state => state.auth.name,
    phone: state => state.auth.phone,
    role: state => state.auth.role,
    current_tab: state => state.current_tab,
    
    AUTOMATIC: state => state.automatic,
    MODE: state => state.mode,
    LINKS: state => state.links,
    LEFTVERSION: state => state.leftVersion,
    OLDVERSION: state => state.oldVersion,
    OPENVERSION: state => state.openVersion,
    MENU: state => state.is_expanded,
    BACKGROUND: state => state.background,
    RADIO: state => state.radio,

    getLockPassword: state => state.lock.password,
    getLockStatus: state => state.lock.status,
    getLockTimer: state => state.getLockTimer.isTime,
    getLockBool: state => state.idleVue.isIdle,
    getLockDisable: state => state.getLockTimer.disable,

    systemLanguages: state => state.systemLanguages
  }
  