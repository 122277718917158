<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.category')"
      filterable
      clearable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(category, index) in categoriesList"
        :key="'categories-' + index"
        :label="category.name"
        :value="category.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    selected_id: {
      default: null,
    },
    only_parents: {
      default: false,
    },
  },
  watch: {
    watchAll: {
      handler: function () {
        this.selected = this.id;
        
        if(this.selected_id){
          this.categoriesList = JSON.parse(JSON.stringify(this.categories)).filter(item => item.id !== this.selected_id);
          let filter_category = this.categoriesList.filter(item => item.parent_id === this.selected_id)
          if(filter_category.length > 0){
            let count = 0;
            filter_category.forEach(element => {
                count += this.categoriesList.filter(item => item.parent_id === element.id).length;
            });
            if(count > 0){
              this.categoriesList = []
            }else{
              this.categoriesList = this.categoriesList.filter(item => item.parent_id === null);
            }
          }else{
            this.unrelatedCategory()
            this.categoriesList = this.categoriesList.filter(item => item.id !== this.selected_id);
          }
        }else{
          this.unrelatedCategory()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      categoriesList: [],
    };
  },
  mounted() {
    if (this.categories && this.categories.length === 0) 
      this.updateInventory().then(res => {
        this.unrelatedCategory();
      })
  },
  computed: {
    ...mapGetters({
      categories: "categories/inventory",
    }),
    watchAll(){
      return (this.id + this.selected_id);
    }
  },
  methods: {
    ...mapActions({
      updateInventory: "categories/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    unrelatedCategory(){
      if(this.categories && this.categories.length > 0){

        if(this.only_parents){
          if(this.id){
           this.categoriesList = JSON.parse(JSON.stringify(this.categories)).filter(item => item.parent_id === this.id);
          }else{
           this.categoriesList = JSON.parse(JSON.stringify(this.categories)).filter(item => item.parent_id === null);
          }
        }else{
          let list = JSON.parse(JSON.stringify(this.categories));
          let null_filter = list.filter(item => item.parent_id === null)
          let category_list = null_filter;
            null_filter.forEach(element => {
            category_list = category_list.concat(list.filter(item => item.parent_id === element.id));
          });
          this.categoriesList = JSON.parse(JSON.stringify(category_list));
        }
      
      }
    }
  },
};
</script>
