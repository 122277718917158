<template>
  <div>
        <ul class="team__ul">
            <li class="team__item" :class="[mode ? 'team__itemday' : 'team__itemnight']">
                <a @click.prevent="setActive('weekly')" :class="{[mode ? 'isActiveday' : 'isActive']: isActive('weekly')}">{{ $t("message.weekly") }}</a>
            </li>
            <li class="team__item" :class="[mode ? 'team__itemday' : 'team__itemnight']">
                <a @click.prevent="setActive('monthly')" :class="{[mode ? 'isActiveday' : 'isActive']: isActive('monthly')}">{{ $t("message.monthly") }}</a>
            </li>
            <li class="team__item" :class="[mode ? 'team__itemday' : 'team__itemnight']">
                <a @click.prevent="setActive('yearly')" :class="{[mode ? 'isActiveday' : 'isActive']: isActive('yearly')}">{{ $t("message.yearly") }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: [],
    data: () => ({
        radio: 'weekly'
    }),
    computed: {
      ...mapGetters({
            mode: "MODE"
        }),
    },
    methods: {
        isActive (menuItem) {
            return this.radio === menuItem
        },
        setActive (menuItem) {
            this.radio = menuItem
            this.$emit('input', menuItem)
        },
    }
}
</script>

<style>
.team__ul {
  display: flex;
  padding: 0;
}
.team__item {
  display: flex;
}
.team__itemnight a {
  color: #fff;
}
.team__itemday {
    border: 1px solid #bdc2c7;
}
.team__itemday:first-child {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}
.team__itemday:last-child {
    border-radius: 0 4px 4px 0;
    border-left: 0px;
}
.team__itemday a {
  color: #606266;
}

.team__item {
    border: 1px solid #bdc2c7;
}
.team__item:first-child {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}
.team__item:last-child {
    border-radius: 0 4px 4px 0;
    border-left: 0px;
}
.team__item a {
  font-size: 12.075px;
  font-weight: 600;
  padding: 6px 12px;
  cursor: pointer;
  -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
.isActive {
  color: #ffffff;
  background: #0d67c9bf;
  transition: opacity 0.1s ease-out;
}
.isActiveday {
  color: #fff !important;
  background: #0d67c9bf;
  transition: opacity 0.1s ease-out;
}
</style>
