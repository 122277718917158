<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.clientType')"
    :size="size"
    class="w-100"
    filterable 
    clearable
  >
    <el-option
      v-for="(clientType, index) in clientTypes"
      :key="'clientType-' + index"
      :label="clientType.name"
      :value="clientType.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    clientType_ids: {
      default: Array,
    },
  },
  watch: {
    clientType_ids: {
      handler: function () {
        this.selected = this.clientType_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.clientTypes && this.clientTypes.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      clientTypes: "clientTypes/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "clientTypes/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>