import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, dealCorrespondences) => {
        dealCorrespondences.forEach(element => {
            state.list.push(element);                    
        });              
    },
    SET_LAST_ID: (state, dealCorrespondences) => { 
        if(dealCorrespondences[0]){
            state.last_id = dealCorrespondences.at(-1).id;
        }             
    },
    SET_COUNT: (state, count) => { 
        state.count = count;
    },
    SET_EMAIL_LIST: (state, {all_emails_messages, search_message}) => { 
        if(search_message){
            if(all_emails_messages.length === 1){
                state.single_email_search = all_emails_messages[0];
                let email_index = null;
                let message_count_diff = 0;
                state.all_emails_messages.forEach((element, index) => {
                    if(element.id === all_emails_messages[0].id){
                        email_index = index;
                        message_count_diff = state.total_email_message_count - element.messages.length;
                        message_count_diff = message_count_diff + all_emails_messages[0].messages.length;
                        state.total_email_message_count = message_count_diff;
                    }
                });
                if(email_index >=0){
                    state.all_emails_messages[email_index] = all_emails_messages[0];
                }

            }else{
                state.all_emails_messages = all_emails_messages
            }
        }else{
            state.all_emails_messages = all_emails_messages;
        }
    },
    SET_EMAIL_COUNT: (state, {total_email_message_count, all_emails_messages}) => { 
        if(all_emails_messages.length > 1){
            state.total_email_message_count = total_email_message_count;
        }
    },
    SET_INVENTORY: (state, dealCorrespondences) => (state.inventory = dealCorrespondences),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, dealCorrespondence) => {
        state.model = dealCorrespondence
    },
    SET_TYPES: (state, types) => {
        state.types = types;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    EMPTY_LIST: (state) => {
        state.list = [];        
        state.last_id = null;        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    }
};
