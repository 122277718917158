<template>
  <div class="modal-add-task-participants">
    <div>
      <el-form ref="form" :model="form" :rules="rules">
        <div class="app-form__group mb-4">
          <el-form-item :label="$t('message.name')" prop="name" class="label_mini">
              <el-input
                :placeholder="$t('message.name')"
                 size="medium"
                v-model="form.name">
              </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <ul class="mini-metka">
        <li class="metka mb-2" v-for="color in colors" :key="color.id">
           <el-radio :class="color.name" v-model="form.color_id" :label="color.id"><i class="el-icon-check"></i></el-radio>
        </li>
    </ul>
    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
        ></crm-store-update-close>
      </div>
    </div>


  
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  mixins: [form, show, drawer],

  data() {
    return {
      checked_1: false,
      input : '',
      colors: [],
      color1: "#409EFF",
       radio: ''
    };
  },

  computed: {
      ...mapGetters({
        colorsInventory: "labels/colorsInventory",
        rules: "labels/rules",
        model: "labels/model",
        mode: "MODE"
      }),
  },
  methods: {
    ...mapActions({
        updateInventory: "labels/colorsInventory",
        updateLabelsInventory: "labels/inventory",
        save: "labels/update",
        show: "labels/show",
    }),
    afterOpen(){
        this.fetchData()
        this.updateInventory()
          .then(res => {
            this.colors = JSON.parse(JSON.stringify(this.colorsInventory));
            this.colors.map(color => color.checked = false)
          });
    },
    changeChacked(color){
      this.colors.map(item => delete item.checked);
      this.form.color_id = color.id;
    },

    submit(close){
      if(!this.form.color_id){
        this.form.color_id = 1;
      }

      this.$refs["form"].validate((valid) => {
              if (valid) {
                this.loadingButton = true;
                this.save(this.form)
                    .then((res) => {
                        this.loadingButton = false;
                        this.updateLabelsInventory();
                        this.$alert(res);
                        this.parent().listChanged();
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
              }
          });
    },
    afterLeave(){
      this.form = {};
      this.colors = [];
    }
  },

};
</script>

<style>


</style>
