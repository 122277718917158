<template>
  <div>
    <div class="app-modal_In">
      <div class="app-modal__header d-flex f-between p-5 m-title-modal">
        <p class="large--title m-0">{{ $t('message.update_deal') }}</p>
        <div>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
    </div>
    <!-- app-modal__header end -->
    <div
      class="change-data-wrap p-relative w-100 crm-create-deal"
      ref="showChangeContent"
      v-loading="loadingData"
    >
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div class="change-data-card rounded-sm mb-4">
          <div class="gc-card__body px-3 py-4">
            
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="app-form__group mb-0">
                        <div class="input-group-wrap p-3 rounded">
                            <div class="input-group__list">
                                <div class="app-form__group mb-0">
                                  <el-form-item
                                    prop="deal_type_id"
                                    >    
                                    <select-type
                                      :disabled="authUser.client_id ? true : false"
                                      :size="'large'"
                                      class="w-100"
                                      :placeholder="$t('message.dealType')"
                                      :id="form.deal_type_id"
                                      @change="updateCheckedPayer(form.deal_type_id)"
                                      v-model="form.deal_type_id"
                                    >
                                    </select-type>
                                  </el-form-item>
                                  <el-collapse class="mb-5" v-model="activeCollapseNames">
                                    <!-- sender Cridentials -->
                                    <el-collapse-item name="1" v-if="form.deal_type_id">
                                      <template slot="title">
                                        <h4>{{ (!form.deal_type_id || form.deal_type_id === 1) ?  $t('message.client') : $t('message.sender') }}</h4>
                                      </template>
                                      <div class="input-group-wrap p-4 rounded bg-rem">
                                        <el-form-item
                                          prop="client_id"
                                          >    
                                          <select-sender-client
                                            key="sender"
                                            name="sender"
                                            :size="'large'"
                                            class="w-100"
                                            :placeholder="(!form.deal_type_id || form.deal_type_id === 1) ? $t('message.reciever') : $t('message.sender')"
                                            :id="form.client_id"
                                            :deal_id="form.id"
                                            v-model="form.client_id"
                                            :query="{selected_id: (form.client_id ? form.client_id : 1)}"
                                            @updateClientCridentials="updateClientCridentials"
                                            @updateDealAddressID="updateDealAddressID"
                                            :update="true"
                                            :create="true"
                                            :address="allowAdress()"
                                          >
                                          </select-sender-client>
                                        </el-form-item>

                                        <el-row :gutter="20" style="mb-0 pb-0">
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.custom_id')}}</span>
                                            {{form.custom_id}}
                                          </el-col>
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.country')}}</span>
                                            {{form.country ? form.country.name : ''}}
                                          </el-col>
                                          <el-col :span="8" v-show="!authUser.client_id">
                                            <span class="d-block">{{$t('message.payment')}}</span>
                                            <el-checkbox 
                                                v-model="checkedClientForPayment.client_id" 
                                                @change="updatePayerId('client_id')"
                                                :disabled="checkedClientForPayment.client_id"
                                                :style="checkedClientForPayment.client_id ? 'background-color: rgb(57, 180, 57);' : ''"
                                                :label="$t('message.payment')" border>
                                              </el-checkbox>
                                          </el-col>
                                        </el-row>
                                        <div class="app-form__group mb-0 mt-2">
                                          <el-input
                                              v-if="allowAdress()"
                                              :disabled="true"
                                              :placeholder="$t('message.address')"
                                              v-model="form.client_deal_address"
                                              size="medium"
                                          ></el-input>
                                        </div>
                                        
                                        <div class="app-form__group mb-0">
                                          <crm-phone-input
                                              :inputValue="form.phone" 
                                              v-model="form.phone"
                                              @getPhoneStatus="getClientPhoneStatus"
                                              :form_prop="'phone'"
                                              :country_code="form.phone_country_code"
                                          ></crm-phone-input>
                                        </div>

                                        <div class="app-form__group mb-0">
                                          <el-form-item
                                              :label="$t('message.email')"
                                              class="mb-0"
                                          >
                                              <el-input
                                              :placeholder="$t('message.email')"
                                              v-model="form.email"
                                              size="medium"
                                              ></el-input>
                                          </el-form-item>
                                        </div>
                                      </div> 
                                    </el-collapse-item>
                                    <!-- reciever Cridentials -->
                                    <el-collapse-item v-if="form.deal_type_id && form.deal_type_id !== 1" name="2">
                                      <template slot="title">
                                        <h4>{{ $t('message.reciever') }}</h4>
                                      </template>
                                      <div class="input-group-wrap p-2 rounded bg-rem">
                                        <el-form-item
                                          prop="to_client_id"
                                          >    
                                          <select-reciever-client
                                            key="reciever"
                                            name="reciever"
                                            :size="'large'"
                                            class="w-100"
                                            :placeholder="$t('message.reciever')"
                                            :id="form.to_client_id"
                                            :deal_id="form.id"
                                            v-model="form.to_client_id"
                                            :query="{selected_id: form.to_client_id}"
                                            @updateClientCridentials="updateRecieverCridentials"
                                            @updateDealAddressID="updateDealAddressID"
                                            :update="true"
                                            :create="true"
                                            :address="true"
                                          >
                                          </select-reciever-client>
                                        </el-form-item>
                                        <el-row :gutter="20" style="mb-0 pb-0">
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.custom_id')}}</span>
                                            {{form.to_custom_id}}
                                          </el-col>
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.country')}}</span>
                                            {{form.to_country ? form.to_country.name : ''}}
                                          </el-col>
                                          <el-col :span="8" v-show="!authUser.client_id">
                                            <span class="d-block">{{$t('message.payment')}}</span>
                                            <el-checkbox 
                                              v-model="checkedClientForPayment.to_client_id" 
                                              @change="updatePayerId('to_client_id')"
                                              :disabled="checkedClientForPayment.to_client_id"
                                              :style="checkedClientForPayment.to_client_id ? 'background-color: rgb(57, 180, 57);' : ''"
                                              :label="$t('message.payment')" border>
                                            </el-checkbox>
                                          </el-col>
                                        </el-row>

                                        <div class="app-form__group mb-0 mt-2">
                                          <el-input
                                              :disabled="true"
                                              :placeholder="$t('message.address')"
                                              v-model="form.to_client_deal_address"
                                              size="medium"
                                          ></el-input>
                                        </div>
                                       
                                        <div class="app-form__group mb-0">
                                          <crm-phone-input
                                              :inputValue="form.to_client_phone" 
                                              v-model="form.to_client_phone"
                                              @getPhoneStatus="getRecieverPhoneStatus"
                                              :form_prop="'to_client_phone'"
                                              :country_code="form.to_client_phone_country_code"
                                          ></crm-phone-input>
                                        </div>

                                        <div class="app-form__group mb-0">
                                          <el-form-item
                                              :label="$t('message.email')"
                                              class="mb-0"
                                          >
                                              <el-input
                                              :placeholder="$t('message.email')"
                                              v-model="form.to_client_email"
                                              size="medium"
                                              ></el-input>
                                          </el-form-item>
                                        </div>
                                      </div> 
                                    </el-collapse-item>
                                    <!-- Partner Cridentials -->
                                    <el-collapse-item v-if="form.deal_type_id && form.deal_type_id === 3" name="3">
                                      <template slot="title">
                                        <h4> {{ $t('message.partner') }} </h4>
                                      </template>
                                      <div class="input-group-wrap p-2 rounded bg-rem">
                                        <el-form-item
                                          prop="partner_client_id"
                                          >    
                                          <select-partner
                                            key="partner"
                                            name="partner"
                                            :disabled="authUser.client_id ? true : false"
                                            :size="'large'"
                                            class="w-100"
                                            :placeholder="$t('message.partner')"
                                            :id="form.partner_client_id"
                                            v-model="form.partner_client_id"
                                            :query="{clientType_id: 1, selected_id: form.partner_client_id}"
                                            @updateClientCridentials="updatePartnerCridentials"
                                          >
                                          </select-partner>
                                        </el-form-item>
                                        <el-row :gutter="20" style="mb-0 pb-0">
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.custom_id')}}</span>
                                            {{form.partner_custom_id}}
                                          </el-col>
                                          <el-col :span="8">
                                            <span class="d-block">{{$t('message.country')}}</span>
                                            {{form.partner_country ? form.partner_country.name : ''}}
                                          </el-col>
                                          <el-col :span="8" v-show="!authUser.client_id">
                                            <span class="d-block">{{$t('message.payment')}}</span>
                                            <el-checkbox 
                                              v-model="checkedClientForPayment.partner_client_id" 
                                              @change="updatePayerId('partner_client_id')"
                                              :disabled="checkedClientForPayment.partner_client_id"
                                              :style="checkedClientForPayment.partner_client_id ? 'background-color: rgb(57, 180, 57);' : ''"
                                              :label="$t('message.payment')" border>
                                            </el-checkbox>
                                          </el-col>
                                        </el-row>
                                       
                                        <div class="app-form__group mb-0">
                                          <crm-phone-input
                                              :inputValue="form.partner_phone" 
                                              v-model="form.partner_phone"
                                              @getPhoneStatus="getPartnerPhoneStatus"
                                              :form_prop="'partner_phone'"
                                              :country_code="form.partner_phone_country_code"
                                          ></crm-phone-input>
                                        </div>

                                        <div class="app-form__group mb-0">
                                          <el-form-item
                                              :label="$t('message.email')"
                                              class="mb-0"
                                          >
                                              <el-input
                                              :placeholder="$t('message.email')"
                                              v-model="form.partner_email"
                                              size="medium"
                                              ></el-input>
                                          </el-form-item>
                                        </div>
                                      </div> 
                                    </el-collapse-item>
                                  </el-collapse>
                                  <div class="sub-form-group">
                                    <div class="app-form__group mb-0">
                                      <!-- <el-form-item
                                          :label="$t('message.email_notif')"
                                          class="label_mini"
                                      >
                                          <select-notification-type
                                            :size="'medium'"
                                            :placeholder="columns.notification_type.title"
                                            :id="form.notification_type"
                                            v-model="form.notification_type"
                                          >
                                          </select-notification-type>
                                          
                                      </el-form-item> -->
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="text-descr mb-0">
                                <span class="text-descr__label d-block">{{
                                    $t("message.responsible")
                                }}</span>
                                <div
                                    class="
                                    responsible-block
                                    d-flex
                                    align-center
                                    font-medium
                                    p-2
                                    rounded-sm
                                    "
                                >
                                    <div
                                    class="gc-small-block rounded-circle hidden mr-3"
                                    style="border: 1px solid gray"
                                    >
                                    <img
                                        :src="
                                        authUser
                                            ? (authUser.avatar
                                            ? authUser.avatar
                                            : '/img/default-user-icon.png')
                                            : '/img/default-user-icon.png'
                                        "
                                        alt="Default Avatar"
                                        class="d-block"
                                    />
                                    </div>
                                    <div class="f-fill">
                                    <span class="text-descr__text f-fill">{{
                                        authUser.name
                                    }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-descr mb-0">
                                <span class="text-descr__label d-block">{{ $t('message.participants') }}</span>
                                <div
                                    class="
                                    responsible-block
                                    d-flex
                                    align-center
                                    font-medium
                                    p-2
                                    rounded-sm
                                    pointer
                                    p-relative
                                    "
                                >
                                    <div
                                    class="gc-small-block rounded-circle hidden mr-3"
                                    @click="getSelectedUsers"
                                    >
                                    <img
                                        src="/img/default-user-icon.png"
                                        alt="Default Avatar"
                                        class="d-block"
                                    />
                                    </div>
                                    <div
                                    class="f-fill d-flex align-center"
                                    @click="getSelectedUsers"
                                    >
                                    <span class="text-descr__text f-fill"
                                        >{{ $t('message.add_participant') }}</span
                                    >
                                    <i class="el-icon-plus ml-auto"></i>
                                    </div>

                                    <div
                                    class="
                                        responsible-block-dropdown
                                        p-absolute
                                        w-100
                                        rounded
                                        p-3
                                    "
                                    >
                                        <div class="responsible-block-search">
                                            <div class="app-form__group">
                                                <div class="responsible-block-input">
                                                    <input
                                                    type="text"
                                                    class="gc-country-input"
                                                    :placeholder="$t('message.search')"
                                                    @input="findResponsibleUsers"
                                                    ref="responsibleUsersInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="responsible-users-dropdown rounded p-3 mt-2"
                                            ref="usersDropdown"
                                        >
                                            <perfect-scrollbar style="height: 200px">
                                            <div
                                                v-for="user in usersList"
                                                :key="user.id"
                                                @click="selectUsers(user)"
                                                class="
                                                users-dropdown-items
                                                d-flex
                                                p-2
                                                rounded
                                                transition
                                                "
                                            >
                                                <div class="user-items-left mr-2">
                                                    <div
                                                        class="
                                                        user-item-avatar
                                                        w-100
                                                        h-100
                                                        rounded-pill
                                                        hidden
                                                        "
                                                    >
                                                            <img
                                                            :src="
                                                                user
                                                                ? (user.avatar
                                                                    ? user.avatar
                                                                    : '/img/default-user-icon.png')
                                                                : '/img/default-user-icon.png'
                                                            "
                                                            alt
                                                            />
                                                        </div>
                                                </div>
                                                <div class="user-items-right">
                                                    <h4 class="my-1">
                                                        {{ user.name }}
                                                    </h4>
                                                    <p class="m-0">
                                                        {{ user.phone }}
                                                    </p>
                                                </div>
                                            </div>
                                            </perfect-scrollbar>

                                            <div class="custom__button mt-3">
                                            <el-button
                                                @click="setUsers"
                                                type="primary"
                                                icon="el-icon-user"
                                                size="medium"
                                                > {{$t('message.add')}} </el-button
                                            >
                                            </div>
                                        </div>

                                        <div
                                            v-for="user in selectedUsersList"
                                            :key="user.id"
                                            class="users-dropdown-items d-flex p-2 rounded transition"
                                        >
                                            <div class="user-items-left mr-2">
                                                <div
                                                    style="border: 1px solid gray"
                                                    class="user-item-avatar w-100 h-100 rounded-pill hidden"
                                                >
                                                    <img
                                                    :src="
                                                        user
                                                        ? (user.avatar
                                                            ? user.avatar
                                                            : '/img/default-user-icon.png')
                                                        : '/img/default-user-icon.png'
                                                    "
                                                    alt
                                                    />
                                                </div>
                                            </div>
                                            <div class="user-items-right">
                                                <h4 class="my-1">
                                                    {{ user.name }}
                                                </h4>
                                                <p class="m-0">
                                                    {{ user.phone }}
                                                </p>
                                            </div>
                                            <div
                                                class="f-fill d-flex align-center"
                                                @click="removeFromSelectedUsers(user)"
                                                >
                                                <i class="el-icon-delete icon__delete ml-auto"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="input-group-wrap rounded p-3">
                      <div class="change-data-card rounded-sm mb-0">
                        <div class="gc-card__header d-flex align-center px-3 py-4">
                          <i class="fa-regular fa-file-lines mr-2"></i>
                              <div class="d-flex w-100">
                                  <span class="font-bold">{{ $t("message.transportation_details") }}</span>
                              </div>
                          </div>
                        </div>
                        <div class="flex__where">
                            <div class="flex__select" id="select_filials">
                               <el-form-item
                                    :label="$t('message.from_where')"
                                    prop="from_filial_id"
                                    class="label_mini"
                                >
                                  <select-from-filial 
                                    v-model="form.from_filial_id" 
                                    :id="form.from_filial_id" 
                                    :placeholder="$t('message.from_where')" 
                                    :size="'large'"
                                  />
                                </el-form-item>
                                <div class="select__reverse" @click="changeFilials()" style="align-items: center; padding-top: 18px;">
                                    <a>
                                        <span class="el-icon-sort"></span>
                                    </a>
                                </div>
                                <el-form-item
                                    :label="$t('message.to_where')"
                                    prop="to_filial_id"
                                    class="label_mini"
                                >
                                  <select-to-filial 
                                    v-model="form.to_filial_id" 
                                    :id="form.to_filial_id" 
                                    :placeholder="$t('message.to_where')" 
                                    :size="'large'"
                                    class="mm__custom__search__width" />
                                </el-form-item>
                            </div>
                            <div class="select__data">
                              <el-form-item v-show="form.direction_tariff_id" prop="date" :label="$t('message.date')" class="label_mini">
                                <div class="block">
                                    <el-date-picker
                                      v-model="form.date"
                                      clearable
                                      type="datetimerange"
                                      :start-placeholder="$t('message.begin_date')"
                                      :end-placeholder="$t('message.end_date')"
                                      format="yyyy-MM-dd HH:mm"
                                      value-format="yyyy-MM-dd HH:mm"
                                      :default-time="['10:00:00', '17:00:00']">
                                    </el-date-picker>
                                </div>
                              </el-form-item>
                            </div>
                        </div>
                        <div>
                          <el-row :gutter="20">
                            <el-col :span="16">
                              <el-form-item prop="direction_tariff_id" :label="$t('message.directionTariff')" class="label_mini">
                                <select-tariff
                                  :size="'medium'"
                                  ref="tariffReff"
                                  :placeholder="$t('message.directionTariff')"
                                  :id="form.direction_tariff_id"
                                  :from_filial_id="form.from_filial_id"
                                  :to_filial_id="form.to_filial_id"
                                  v-model="form.direction_tariff_id"
                                  @input="updateFormDate()"
                                >
                                </select-tariff>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="checkTariffPrice">
                              <el-form-item prop="shipping_service" :label="$t('message.shipping_to_address')" class="label_mini">
                                <el-checkbox 
                                  v-model="form.shipping_service" 
                                  class="w-100"
                                  size="large"
                                  :label="$t('message.shipping_to_address')"
                                  border>
                                </el-checkbox>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24" v-if="form.direction_tariff_id && form.cargo_type != 'parcel'" class="mb-5">
                              <span v-if="selectedTariffCurrentPrice && selectedTariffCurrentPrice > 0 && form.shipping_service" class="coo1"> 
                                <div v-if="!authUser.client_id" class="d-flex">
                                  <el-form-item prop="delivery_price" :label="$t('message.price_delivery_to_door_per_kg')" class="label_mini">
                                    <el-input 
                                      v-model="form.delivery_price"
                                      type="number" 
                                      class="mm-max-150 mr-4" 
                                      :placeholder="$t('message.price_delivery_to_door_per_kg')" 
                                      >
                                      <template slot="append">$</template>
                                    </el-input>   
                                    <el-button class="mr-2" @click="form.delivery_price = selectedTariffCurrentPrice" type="primary" icon="el-icon-caret-left"></el-button>
                                    <span>
                                      {{ $t('message.currently_price_delivery_to_door_per_weight', {price: selectedTariffCurrentPrice}) }} 
                                    </span>
                                  </el-form-item>
                                </div>
                                <span v-else>
                                  {{ $t('message.price_delivery_to_door_per_weight', {price: form.delivery_price}) }} 
                                </span>
                              </span>
                              <span v-if="!selectedTariffCurrentPrice || selectedTariffCurrentPrice == 0" class="coo2">
                                {{ $t('message.price_delivery_to_door_not_set') }} 
                              </span>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- Service component start -->
                          <crm-services 
                            :cargo_type="form.cargo_type"
                            :emptyData.sync="afterLeaveWorked"
                            :services="services" 
                            :parcel="form.parcel"
                            :allItems="allItems"
                            :deal_services="form.services"
                          ></crm-services>
                        <!-- Service component end -->

                        <div v-if="form.cargo_type === 'parcel'" class="change-data-card rounded-sm mb-0">
                          <parcel-products 
                            v-model="form.parcel" 
                            @getDeliveryPrice="getDeliveryPrice"   
                            :mainForm="getForm.parcel" 
                            :shipping_service="form.shipping_service"
                            :partner_id="form.partner_client_id" 
                            :loadingData="loadingData"
                            :deal_id="form.id"
                            :direction_tariff_id="form.direction_tariff_id" 
                          >
                          </parcel-products>
                        </div>
                        <div v-if="form.cargo_type !== 'parcel'" class="change-data-card rounded-sm mb-0">
                            <div class="gc-card__header d-flex align-center px-3 py-4">
                                <i class="el-icon-share mr-2"></i>
                                <div class="d-flex w-100">
                                  <span class="font-bold">
                                    {{ $t("message.cargo") }} 
                                  </span>
                                </div>
                                <el-button class="flex-style text-right" @click="openProductList()" type="primary" plain>{{ $t('message.product_list') }}</el-button>  
                            </div>
                            <div class="mm_custom_input_padding">
                                <template>
                                  <el-table
                                    :data="allItems"
                                    show-summary 
                                    :summary-method="getSummaries" 
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                      type="index"
                                      width="50">
                                    </el-table-column>
                                    <el-table-column :label="$t('message.name')">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type && scope.row.type !== 'package'">{{ scope.row.product ? scope.row.product.name : '' }}</span>
                                        <span v-else> {{ $t('message.package') }} </span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.product_price')" width="140">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type == 'product'">{{(scope.row.product_price ? scope.row.product_price : 0) | formatNumber(1) }}</span>
                                        <span v-else>{{(scope.row.product_price ? scope.row.product_price : 0) | formatNumber(1) }}</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column width="90" :label="$t('message.quantity_y')">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type == 'product'"> {{ roundUpVal(scope.row.quantity) + ' / ' + roundUpVal(scope.row.sum_coming_qty) }}</span>
                                        <span v-else>
                                          <span v-if="!scope.row.new_product"> {{roundUpVal(scope.row.dp_quantity) + ' / ' + roundUpVal(scope.row.pc_quantity)}} </span>
                                          <span v-else>{{roundUpVal(scope.row.quantity)}}</span>
                                        </span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.weight')" width="130">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type == 'product'">
                                          {{roundUpVal(scope.row.old_weight) +' / '+ roundUpVal(scope.row.sum_coming_weight) + ' ' + $t('message.kg')}} <br>
                                          {{roundUpVal(scope.row.old_weight * $kg_to_pound) +' / '+ roundUpVal(scope.row.sum_coming_weight  * $kg_to_pound) + ' ' + $t('message.lbs')}}
                                        </span>
                                        <span v-else>
                                          <span v-if="!scope.row.new_product">
                                            {{ roundUpVal(scope.row.dp_weight) +' / ' + roundUpVal(scope.row.pc_weight) + ' ' + $t('message.kg')}} <br>
                                          	{{ roundUpVal(scope.row.dp_weight * $kg_to_pound) +' / ' + roundUpVal(scope.row.pc_weight * $kg_to_pound) + ' ' + $t('message.lbs')}}
														              </span>
                                          <span v-else>
															              {{ roundUpVal(scope.row.weight) + ' ' + $t('message.kg') }} <br>
                                          	{{ roundUpVal(scope.row.weight * $kg_to_pound) + ' ' + $t('message.lbs') }}
														              </span>
                                        </span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.price')"  width="110">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type == 'product'">{{parseFloat(scope.row.price) | formatMoney(2)}}</span>
                                        <span v-else>
                                          <span v-if="!scope.row.new_product">{{roundUpVal(scope.row.dp_price) | formatMoney(2) }} / {{ roundUpVal(scope.row.pc_price) | formatMoney(2) }}</span>
                                          <span v-else>{{roundUpVal(scope.row.price) | formatMoney(2) }}</span>
                                        </span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      prop="comment"
                                      :label="$t('message.comment')">
                                    </el-table-column>
                                    <el-table-column :label="$t('message.total')"  width="130">
                                      <template slot-scope="scope">
                                        <span v-if="scope.row.type && scope.row.type === 'package'" >
                                          {{parseFloat(scope.row.total_money) | formatMoney(2)}} / {{parseFloat(scope.row.incoming_total_money) | formatMoney(2)}}
                                        </span>
                                        <span v-else>
                                          {{ productRowTotal(scope.row) | formatMoney(2)}} / {{ productRowIncomingTotal(scope.row) | formatMoney(2)}}
                                        </span>
                                      </template>
                                    </el-table-column>
                                  </el-table>
                                </template>
                            </div>
                        </div>
                        <div class="gc-card__body px-0 py-4">
                            <div class="app-form__group mb-0">
                                <el-form-item
                                    :label="$t('message.comment')"
                                    class="mb-0"
                                >
                                    <textarea
                                        v-model="form.comment"
                                        :placeholder="$t('message.comment')"
                                        value="asdasdasd"
                                        class="
                                            timeline-textarea
                                            w-100
                                            rounded-sm
                                            p-3
                                        "
                                    ></textarea>
                                </el-form-item>
                            </div>
                        </div>

                        <div class="app-form__group mb-0">
                            <el-form-item :label="$t('message.file')" class="label_mini">
                                <el-upload
                                    v-model="form.files"
                                    class="upload-demo"
                                    action="/"
                                    :limit="file_limit"
                                    :accept="fileAccepts"
                                    :on-exceed="handleExceed"
                                    :on-change="createImageList"
                                    :on-remove="handleRemove"
                                    :file-list="fileList"
                                    :auto-upload="false"
                                >
                                    <el-button size="small" type="primary"> {{ $t('message.loading') }} </el-button>
                                </el-upload>
                            </el-form-item>
                        </div>
                    </div>
                </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>

    <div>
      <el-drawer
          :append-to-body="true"
          size="95%"
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          :with-header="false"
          :visible.sync="drawerProductList"
          :close-on-press-escape="false"
          :wrapperClosable="false"
          class="body_scroll bg-se"
          @opened="drawerOpened('productsTable')"
          @closed="drawerClosed('productsTable')"
      >
        <div class="m-5">
          <div class="app-modal_In">
              <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                  <p class="large--title m-0">{{$t('message.products')}}</p>
                  <div>
                      <crm-store-update-close
                          :permission="$options.name"
                          :button_type="''"
                          :loading="loadingButton"
                          @c-close="closeProductListCheck()"
                      ></crm-store-update-close>
                  </div>
              </div>
          </div>
          <deal-products 
            ref="productsTable" 
            v-model="form.products"
            @updateDealPackages="updateFormPackages" 
            :deal_packages="getForm.packages"
            :deal_products="getForm.products"
            :direction_tariff_id="getForm.direction_tariff_id"
				    :deal_id="getForm.id"
            >
          </deal-products>
        </div>
      </el-drawer> 
    </div>
  </div>
</template>


<script>
import CountryPhoneInput from "@/components/CountryPhoneInput";
import selectSource from "@/components/inventory/select-source";
import selectDealStage from "@/components/inventory/select-dealStage";
import selectCurrency from "@/components/inventory/select-currency";
import selectRecieverClient from "@/components/inventory/select-client";
import selectSenderClient from "@/components/inventory/select-client";
import selectPartner from "@/components/inventory/select-client";
import selectProduct from "@/components/selectsWithCreate/select-product";

import selectDealType from "@/components/inventory/select-dealType";
import selectDealCondition from "@/components/inventory/select-deal-condition";
import selectShipmentTerm from "@/components/inventory/select-shipment-term";
import selectNotificationType from "@/components/inventory/select-deal-notification-type";
import dealProducts from "@/views/deals/components/dealProduct/deal-product-list-with-packages";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectTariff from "@/components/selects/select-direction-tariff";
import selectType from "@/components/inventory/select-static-deal-type";
import ParcelProducts from "@/views/deals/components/dealProduct/parcel-products";

import { formatMoney, formatNumber } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import CrmServices from '../services/crm-services';

import _ from "lodash";

export default {
  mixins: [form, drawer, show],

  props: {
      dealEdit: {
          default: false,
      },
  },

  components: {
    CountryPhoneInput,
    selectFromFilial,
    selectToFilial,
    dealProducts,
    selectSource,
    selectDealStage,
    selectCurrency,
    selectRecieverClient,
    selectSenderClient,
    selectPartner,
    selectDealType,
    selectDealCondition,
    selectShipmentTerm,
    selectProduct,
    selectNotificationType,
    selectTariff,
    selectType,
    ParcelProducts,
	 CrmServices
  },
  data() {
    return {
      selectedTariffCurrentPrice: 0,
      parcel_delivery_price: 0,
      incomingToDoorDeliverySum: 0,
      toDoorDeliverySum: 0,
      maxSize: 300,
      newDeal: false,
      secondLastStageId: null,
      drawerProductList: false,
      checkedClientForPayment: {
        client_id: false,
        to_client_id: false,
        partner_client_id: false,
      },
      total_weight: 0,
      incoming_total_weight: 0,
      totalQuantity: 0,
      incomingTotalQuantity: 0,
      totalProductPrice: 0,
      incomingTotalAmount: 0,
      activeCollapseNames: ['1', '2', '3'],
      usersList: [],
      firstOpen: false,
      selectedUsersList: [],
      selectedUsersContainer: false,
      fileList: [],
      updateFile: [],
      paymentPercentage: 0,
      file_limit: 5,
      customColors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#6f7ad3', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#5cb87a', percentage: 100},
      ],
		  afterLeaveWorked: false,
      fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
    };
  },
  computed: {
    ...mapGetters({
      getUserSearch: "users/search",
      getInventoryUsers: "users/inventory",
      authUser: "auth/user",
      model: "deals/model",
      getForm: "deals/form",
      rules: "deals/rules",
      columns: "deals/columns",
      directionTariffs: 'directionTariffs/inventory',
      mode: "MODE",
      services: 'services/inventory',
      formServices: 'services/services'
    }),

    checkTariffPrice(){
      let shipment = false;
      if(this.form.direction_tariff_id){
        if(this.form.cargo_type == 'parcel' && this.form.parcel && (this.form.shipping_service ||  this.parcel_delivery_price && parseFloat(this.parcel_delivery_price) > 0)){
          shipment = true;
        }else if (this.form.cargo_type != 'parcel' && this.selectedTariffCurrentPrice && this.selectedTariffCurrentPrice > 0){
          shipment = true;
        }
      }
      return shipment;
    },

    totalAmount: function () {
      let total = 0;
      this.incomingTotalAmount = 0;
      
      this.toDoorDeliverySum = 0;
      this.incomingToDoorDeliverySum = 0;

      this.total_weight = 0;
      this.incoming_total_weight = 0;

      this.totalQuantity = 0;
      this.incomingTotalQuantity = 0;
      
      this.totalProductPrice = 0;
      let delivery_price = this.form.delivery_price ? parseFloat(this.form.delivery_price) : 0;

      if(this.form.products || this.form.packages){
        this.form.products.concat(this.form.packages).forEach((item) => {
          if(item.type && item.type == 'package'){
            total += parseFloat(item.total_money);
            this.incomingTotalAmount += parseFloat(item.incoming_total_money);

            this.total_weight += item.new_product ? parseFloat(item.weight) : (parseFloat(item.dp_weight) || 0);
            this.incoming_total_weight += item.new_product ? parseFloat(item.weight) : (parseFloat(item.pc_weight) || 0);

            this.totalQuantity += item.new_product ? parseFloat(item.quantity) : (parseFloat(item.dp_quantity) || 0); 
            this.incomingTotalQuantity += item.new_product ? parseFloat(item.quantity) : (parseFloat(item.pc_quantity) || 0);
          }else{
            total += parseFloat(item.currency_rate) * parseFloat(item.price) * parseFloat(item.weight);
            this.incomingTotalAmount += parseFloat(item.currency_rate) * parseFloat(item.price) * parseFloat(item.sum_coming_weight);

				    this.total_weight += (parseFloat(item.weight) || 0);
            this.incoming_total_weight += parseFloat(item.sum_coming_weight);

          	this.totalQuantity += (parseFloat(item.quantity) || 0); 
            this.incomingTotalQuantity += (parseFloat(item.sum_coming_qty) || 0); 
          }
          this.totalProductPrice += (parseFloat(item.product_price) || 0);
        });
      }
      this.toDoorDeliverySum = delivery_price * this.total_weight;
      this.incomingToDoorDeliverySum = delivery_price * this.incoming_total_weight;

      if(this.form.shipping_service && this.form.cargo_type != 'parcel'){
        total += this.toDoorDeliverySum;
        this.incomingTotalAmount += this.incomingToDoorDeliverySum;
      }

      return parseFloat(total);
    },

    totalPaymentPercentage() {
      if(this.form.deal_payments && this.form.deal_payments.length > 0){
        this.paymentPercentage = this.form.deal_payments.map(item => item.percentage).reduce((prev, next) => parseFloat(prev) + parseFloat(next));
      }
      if(this.paymentPercentage > 100){
        this.$message({
          type: "warning",
          message: this.$t('message.Payment cannot be more than 100%')
        });        
        this.paymentPercentage = 100
      }
      return Math.round(this.paymentPercentage);
    },

    allItems() {
      if(this.form.products || this.form.packages){
        return this.form.products.concat(this.form.packages);
      }
      return [];
    },

    maxWeight() {
      let max_weight = this.form.shipping_service ? 30 : 30;
      return max_weight;
    },

    totalSMSizeSum() {
      if(this.form.parcel){
        let total = parseFloat(this.form.parcel.width || 0) + parseFloat(this.form.parcel.height || 0) + parseFloat(this.form.parcel.length || 0)
        return _.round(total, 1);
      }
      return 0;
    },
  },
  watch: {
    "form.direction_tariff_id": {
      handler: async function(newVal, oldVal) {
        if(oldVal && newVal != oldVal){
          this.form.shipping_service = false;
        }
        if(this.form.cargo_type != 'parcel'){
          if(newVal){
            let tariff = this.directionTariffs.find(el => el.id === newVal);
            if(tariff){
              if(this.getForm.direction_tariff_id == newVal){
                this.form.delivery_price = JSON.parse(JSON.stringify(this.getForm.delivery_price));
              }else{
                this.form.delivery_price = tariff.price_delivery_to_door;
              }
              this.selectedTariffCurrentPrice = tariff.price_delivery_to_door
              if(!tariff.price_delivery_to_door || tariff.price_delivery_to_door == 0){
                this.form.shipping_service = false;
                this.form.delivery_price = null;
                this.selectedTariffCurrentPrice = null;
              }
            }else if(newVal && !tariff) {
              this.selectedTariffCurrentPrice = JSON.parse(JSON.stringify(this.getForm.delivery_price));
            }else{
              this.form.shipping_service = false;
              this.form.delivery_price = null;
              this.selectedTariffCurrentPrice = null;
            }
          }else{
            this.form.shipping_service = false;
            this.form.delivery_price = null;
            this.selectedTariffCurrentPrice = null;
          }
        }else{
          this.form.delivery_price = null;
          this.selectedTariffCurrentPrice = null;
        }
      },
      immediate: true,
      deep: true,
    },
    "form.deal_type_id": {
        handler: async function(newVal, oldVal) {
          if(!this.loadingData){
            if(newVal == 3){
              this.checkedClientForPayment = {
                client_id: false,
                to_client_id: false,
                partner_client_id: true,
              };
            }else{
              this.checkedClientForPayment = {
                client_id: true,
                to_client_id: false,
                partner_client_id: false,
              };
            }
          }
        },
        immediate: true,
        deep: true,
    },
  },
  methods: {
    ...mapActions({
      save: "deals/update",
      show: "deals/show",
      showInventoryUsers: "users/inventory",
      showClient: "clients/show",
      search: "users/search",
      deleteFile: "deals/deleteFile",
      clientList: 'clients/inventory',
      empty: "deals/empty",
		getServicesByDealType: "services/inventory",
    }),
    getDeliveryPrice(price){
      this.parcel_delivery_price = parseFloat(price);
    },
    roundUpVal(num, dec = 2){
      return _.round((parseFloat(num) || 0), dec)
    },
    allowAdress(){
      return (this.form.cargo_type === 'parcel' || (this.form.deal_type_id && this.form.deal_type_id === 1)) ? true : false
    },
    closeProductListCheck() {
      let empty_prod = false;
      let empty_packs = [];
      if(this.$refs.productsTable){
        empty_prod = this.$refs.productsTable.dealProductsList.filter(prod => prod.product.name === '').length > 0 ? true : false;
        if(!empty_prod){
          empty_prod = this.$refs.productsTable.dealPackages.filter(pack => pack.deal_products.filter(prod => prod.product.name === '').length > 0).length > 0 ? true : false;
        }
        empty_packs = this.$refs.productsTable.dealPackages.map((el, index) => { return {number: (index+1), prods: el.deal_products}})
        empty_packs = empty_packs.filter(pack => (!pack.prods || (pack.prods && pack.prods.length === 0)));
      }
      if(empty_prod){
        return this.$notify({
            title: this.$t('message.warning'),
            type: "warning",
            offset: 130,
            message: this.$t('message.product_name_cant_be_empty')
        });
      }else if(empty_packs.length > 0) {
        let numbers = empty_packs.map(pack => pack.number).toString();
        return this.$notify({
          title: this.$t('message.warning'),
          type: "warning",
          offset: 130,
          message: this.$t('message.package_empty_message', {numbers: numbers})
        });
      }else{
        this.drawerProductList = false;
      }
    },
    weightInPounds(row){
      if(row.weight){
        return _.round(parseFloat(row.weight) * parseFloat(this.$kg_to_pound), 3) ;
      }
      return '';
    },
    openProductList(){
      this.drawerProductList = true;
    },
    updateFormPackages(deal_packs){
      this.$set(this.form, 'packages', deal_packs);
    },
    updateFormDate() {
      this.$set(this.form, 'date', []);
      if(this.form.direction_tariff_id){
        let tariff = this.directionTariffs.find(el => el.id === this.form.direction_tariff_id);
        if(tariff && tariff.average_days){
          let number_of_days = JSON.parse(JSON.stringify(tariff.average_days));
          let begin_date = this.formatDate(new Date());
          let end_date =this.formatDate(new Date(new Date().setDate(new Date().getDate() + number_of_days)));
          this.$set(this.form, 'date', [begin_date, end_date]);
        }
      }
    },
    updateCheckedPayer(newVal){
      if(newVal === 3){
        this.checkedClientForPayment = {
          client_id: false,
          to_client_id: false,
          partner_client_id: true,
        };
      }
    },
    productRowTotal(product) {
      let rowTotal = product.currency_rate * product.price * product.weight;
      return rowTotal;
    },
    productRowIncomingTotal(product) {
      let rowTotal = product.currency_rate * product.price * product.sum_coming_weight;
      return rowTotal;
    },
    updatePayerId(client_key, payer_id = null){
        if(client_key){
          this.checkedClientForPayment = {
            client_id: false,
            to_client_id: false,
            partner_client_id: false,
          };
          this.checkedClientForPayment[client_key] = true;
          this.form.payer_client_id = this.form[client_key];
        }
        if(payer_id){
          this.checkedClientForPayment = {
            client_id: false,
            to_client_id: false,
            partner_client_id: false,
          };
          if(this.form.payer_client_id === this.form.client_id){
            this.$set(this.checkedClientForPayment, 'client_id', true);
          }
          if(this.form.payer_client_id === this.form.to_client_id){
            this.$set(this.checkedClientForPayment, 'to_client_id', true);
          }
          if(this.form.payer_client_id === this.form.partner_client_id){
            this.$set(this.checkedClientForPayment, 'partner_client_id', true);
          }
        }
    },

    updateClientCridentials(client){
      if(!this.loadingData && client){
        this.form.email = client.email;
        this.form.phone_country_code = client.phone_country_code;
        this.form.phone = client.phone;
        this.form.country = client.country;
        this.form.custom_id = client.custom_id;
        if(this.checkedClientForPayment.client_id){
          this.form.payer_client_id = client.id;
        }
        if(this.form.deal_type_id === 1 && client.id !== this.getForm.client_id){
          this.form.client_deal_address = client.default_address
          this.form.client_deal_address_id = client.default_address_id
        }
      }
    },

    updateRecieverCridentials(client){
      if(!this.loadingData && client){
        this.form.to_email = client.email;
        this.form.to_client_phone_country_code = client.phone_country_code;
        this.form.to_client_phone = client.phone;
        this.form.to_country = client.country;
        this.form.to_custom_id = client.custom_id;
        if(this.checkedClientForPayment.to_client_id){
          this.form.payer_client_id = client.id;
        }
        if(this.form.deal_type_id !== 1 && client.id !== this.getForm.to_client_id){
          this.form.to_client_deal_address = client.default_address
          this.form.to_client_deal_address_id = client.default_address_id
        }
      }
    },

    updatePartnerCridentials(client){
      if(!this.loadingData && client){
        this.form.partner_email = client.email;
        this.form.partner_phone_country_code = client.phone_country_code;
        this.form.partner_phone = client.phone;
        this.form.partner_country = client.country;
        this.form.partner_custom_id = client.custom_id;
        if(this.checkedClientForPayment.partner_client_id){
          this.form.payer_client_id = client.id;
        }
      }
    },
    changeFilials() {
      if(this.form.from_filial_id && this.form.to_filial_id){
        let from = JSON.parse(JSON.stringify(this.form.from_filial_id));
        let to = JSON.parse(JSON.stringify(this.form.to_filial_id));
         this.$set(this.form, 'from_filial_id', to);
         this.$set(this.form, 'to_filial_id', from);
      }
    },
    getClientPhoneStatus({is_valid, countryCode}){
        this.form.phone_country_code = countryCode;
    },
    getRecieverPhoneStatus({is_valid, countryCode}){
        this.form.to_client_phone_country_code = countryCode;
    },
    getPartnerPhoneStatus({is_valid, countryCode}){
        this.form.partner_phone_country_code = countryCode;
    },
    afterOpen() {
      this.fetchData();
		  this.afterLeaveWorked = false;
    },
    reloadTariff(){
      if(this.$refs['tariffReff']){
        this.$refs['tariffReff'].firstUpdate = true;
      }
    },
    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        this.show(this.selectedItem.id)
          .then(async res => {
            await this.setData();     
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      } 
    },
    async setData(){
      await this.updatePayerId('' ,this.form.payer_client_id);
      await this.reloadTariff()
      this.form = JSON.parse(JSON.stringify(this.getForm));
      this.firstOpen = true;
      this.fileList = this.form.files;
      this.selectedUsersList = JSON.parse(JSON.stringify(this.form.spectators));
      await this.setUsers();
      if(this.form.deal_payments && this.form.deal_payments.length === 0){
        await this.addPayment()
      }
      if (this.form.cargo_type) {
        await this.getServicesByDealType({deal_type: this.form.cargo_type});
      }

      return 'success';
    },

    addPayment(){
      if(this.form.deal_payments && this.form.deal_payments.filter(item => item.percentage === 0).length > 0){
        this.$message({
          type: "warning",
          message: this.$t('message.One of the payments is 0, please enter the percentage for the existing payment first')
        }); 
      }else{
        let payment = {
          id: null,
          percentage: 0,
          begin_date: '',
          end_date: '',
          comment: '',
          payment_condition_id: null
        }
        this.form.deal_payments.push(payment);
      }
    },
    removePayment(index){
      this.$confirm(
        this.$t('message.do_you_really_want_to_do_this'),
        this.$t('message.warning'), {
          confirmButtonText: this.$t('message.yes'),
          cancelButtonText: this.$t('message.no'),
          type: "warning"
        }
      )
      .then(() => {
          this.form.deal_payments.splice(index, 1);
      })
      .catch(() => {
        this.$message({
          type: "warning",
          message: this.$t('message.operation_canceled')
        });
      });
    },
    calculatePaymentPercentage(payment) {
      let currency_symbol = ''
      if(this.$refs['currencySelect']){
        currency_symbol = this.$refs['currencySelect'].currency_symbol;
      }
      return  this.formatNumber((this.form.money_amount * payment.percentage / 100), 1) + ' ' + currency_symbol;
    },

    updateDealAddressID(deal_address){
      if(deal_address){
        if(deal_address.client_id == this.form.client_id){
          this.$set(this.form, 'client_deal_address_id', deal_address.id);
          this.$set(this.form, 'client_deal_address', deal_address.text);
        }
        if(deal_address.client_id == this.form.to_client_id){
          this.$set(this.form, 'to_client_deal_address_id', deal_address.id);
          this.$set(this.form, 'to_client_deal_address', deal_address.text);
        }
      }
    },

    submit(close = true) {
      if(!this.form.user_id){
        this.form.user_id = this.authUser.id;
      }
      let message = '';
      let success = true;
      let err_weights = this.form.products.filter(el => parseFloat(el.weight) === 0);
      if(this.form.cargo_type === 'parcel' && this.form.parcel && parseFloat(this.form.parcel.weight) > this.maxWeight) {
        success = false;
        message = this.$t('message.parcel_weight_limit', {weight: this.maxWeight});
      }
      if(this.form.cargo_type === 'parcel' && this.form.parcel && parseFloat(this.totalSMSizeSum) > this.maxSize) {
        success = false;
        message = this.$t('message.parcel_size_limit', {size: '300', measure: this.$t('message.sm')});
      }
      if(this.form.cargo_type === 'parcel' && this.form.parcel && this.form.parcel.parcel_products.filter(el => !el.name).length > 0) {
        success = false;
        message = this.$t('message.enter_product_name');
      }   
      if(this.form.cargo_type !== 'parcel' && err_weights.length > 0) {
        success = false;
        message = this.$t('message.weight_cant_be_0');
      }
      if(!success) {
        return this.$notify({
            title: this.$t('message.warning'),
            type: "warning",
            offset: 130,
            message: message
        });
      } else {
        let formData = new FormData();
        for (const key in this.form) {
          if (this.form[key]) {
            if(key !== 'deal_payments' && key !== 'products' && key !== 'packages' && key !== 'parcel'){
              formData.append(key, this.form[key]);
            }else{
              formData.append(key, JSON.stringify(this.form[key]));
            }
          } else {
            formData.append(key, "");
          }
        }

        if (_.size(this.updateFile) > 0) {
          for (const key in this.updateFile) {
            if (this.updateFile.hasOwnProperty(key)) {
              const element = this.updateFile[key];
              formData.append(`files[${key}]`, element);
            }
          }
        }

		  	let formServices = JSON.parse(JSON.stringify(this.formServices));
			  let partition = _.partition(formServices, {'new_service': true});

        this.buildFormData(formData, partition[0], 'new_services');
        this.buildFormData(formData, partition[1], 'old_services');

        formData.append('_method', 'PUT');
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.loadingButton = true;
            this.save(formData)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.parent().listChanged();
                this.parent().fetchData(this.form.id);
                if (close) this.close();
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$alert(err);
              });
          }
        });
      }
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    findResponsibleUsers() {
      const { usersDropdown } = this.$refs;
      let value = event.target.value;
      if (value) {
        this.searchUsers(value, this);
      }

      if (value) {
        usersDropdown.classList.add("active");
      } else {
        usersDropdown.classList.remove("active");
      }
    },
    searchUsers: _.debounce((search, self) => {
      if (_.isFunction(self.search)) {
        self
          .search(search)
          .then((res) => {
            self.usersList = JSON.parse(JSON.stringify(self.getUserSearch.filter(item => item.id !== self.form.user_id)));
          })
          .catch((err) => {});
      }
    }, 1000),
    getSelectedUsers() {
      const { usersDropdown } = this.$refs;
      usersDropdown.classList.toggle("active");
      if (usersDropdown.classList.contains("active")) {
        this.showInventoryUsers().then(() => {
          this.usersList = JSON.parse(JSON.stringify(this.getInventoryUsers.filter(item => item.id !== this.form.user_id)));
        });
      }
    },
    selectUsers(user) {
      let $el = event.target.closest(".users-dropdown-items");
      if (!this.selectedUsersList.some((person) => person.id === user.id)) {
        this.selectedUsersList.push(user);
        $el.classList.add("active");
      } else {
        this.selectedUsersList = this.selectedUsersList.filter(
          (item) => item.id !== user.id
        );
        $el.classList.remove("active");
      }
    },
    setUsers() {
      this.form.spectators = this.selectedUsersList.map(item => item.id);
      const { usersDropdown, responsibleUsersInput } = this.$refs;

      this.selectedUsersContainer = true;
      usersDropdown.classList.remove("active");
      responsibleUsersInput.value = "";
    },
    removeFromSelectedUsers(user) {
      this.selectUsers(user);
      this.form.spectators = this.selectedUsersList.map(item => item.id);
    },
    afterLeave() {
      if(this.dealEdit){
        this.parent().fetchData(this.form.id);
      }
      this.usersList = [];
      this.selectedUsersList = [];
      if(this.$refs.productsTable){
        this.dealProductsList = [];
        this.$refs.productsTable.dealProductsList = [];
        this.$refs.productsTable.emptyProducts();
        this.$refs.productsTable.dealPackages = [];
      }
		  this.afterLeaveWorked = true;
    },
    
    handleExceed(files, fileList) {
      this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
    },
    handleRemove(file, fileList){
      if(file.id){
        this.deleteFile({deal_id: this.selectedItem.id, file: file.name})
      }
      this.updateFile.forEach((element, index) => {
        if(element.name === file.name ){
          this.updateFile.splice(index, 1);
        }
      });
    },
    createImageList(file, fileList) {
      if (file.size > 5000000) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_size_limit_warning', {number: 5}),
        });
      }
      if(this.updateFile.some(item => item.name === file.name && item.size === file.size)) {
        this.updateFile.forEach((element, index) => {
          if(element.name === file.name && element.size === file.size){
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_already_exists'),
            });
          } 
        });
      }else{
        this.updateFile.push(file.raw);
      }
    },

    // table footer all total summs  
    getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = this.$t('message.total');
            return;
          }
          if (column.label === this.$t('message.quantity_y')) {
            sums[index] = formatNumber(this.totalQuantity, 1) + ' / ' + formatNumber(this.incomingTotalQuantity, 1);
            return;
          }
          if (column.label === this.$t('message.product_price')) {
            sums[index] = formatMoney(this.totalProductPrice, 2); 
            return;
          }
          if (column.label === this.$t('message.weight')) {
            sums[index] =formatNumber(this.total_weight, 1) + ' / ' + formatNumber(this.incoming_total_weight, 1)  + ' kg';
            return;
          }
          if (column.label === this.$t('message.comment') && this.form.shipping_service && this.form.cargo_type != 'parcel') {
            sums[index] = this.$t('message.delivery') + ': ' + formatMoney(this.toDoorDeliverySum, 2) + ' / ' +formatMoney(this.incomingToDoorDeliverySum, 2);
            return;
          }
          if (column.label === this.$t('message.total')) {
            sums[index] = formatMoney(this.totalAmount, 2) + ' / ' + formatMoney(this.incomingTotalAmount, 2);
            return;
          }
        });

        return sums;
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed()
      }
    },
    drawerOpened(ref) {        
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened()
        }
      }
    },
    
  },
};
</script>


<style>
.el-form--label-top .el-form-item__label {
    margin: 0px !important;
    padding: 0px !important;
}

 .mm__font__14 {
  font-size: 14px;
 }

 .mm__tab__color {
      background: #eef2f4 !important;
  }
  .mm_custom_input_padding .el-input-group__append {
    padding: 0px !important;
    width: 35px !important;
    text-align: center;
  }
</style>
